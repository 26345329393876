import React, { useState } from 'react';
import { useEffect } from 'react';
import './styles.css';
import firebase from "firebase/app";
import { CircularProgress, Divider, InputAdornment, TextField } from '@material-ui/core';
import ConsultantItem from './components/ConsultantItem';
import { Fragment } from 'react';
import SearchIcon from '@material-ui/icons/Search'
import { useGlobalStyles } from '../App/globalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { userState } from '../../api/userSlice';
import { fetchTheme, themeState } from '../../api/themeSlice';
import { defaultTheme } from '../../config/config';
import { useHistory } from 'react-router-dom';

const ConsultantsList = (props) => {
  const { teamID } = props
  const isConsultant = useSelector(userState).user?.consultant
  const integration = useSelector(userState).integration
  const isIntegrationTeam = useSelector(userState).isIntegrationTeam
  const themeID = useSelector(themeState).theme?.id ?? defaultTheme.id
  const realThemeID = useSelector(themeState).realThemeID
  const dispatch = useDispatch()
  const history = useHistory()

  const [teamName, setTeamName] = useState(null);
  const [consultantsList, setConsultantsList] = useState(null);
  const [searchedConsultants, setSearchedConsultants] = useState([]);

  useEffect(() => {
    if (teamID || isIntegrationTeam) {
      firebase.firestore().collection('/teams').where('code', '==', teamID ?? integration).get().then((teams) => {
        const teamData = teams.docs[0].data()
        if (teamData) {
          firebase.firestore().doc(`users/${teamData.masterID}`).get().then(res => {
            if (teamID) {
              const themeData = res.data()
              const newTheme = isIntegrationTeam && themeData.integrationThemeID ? themeData.integrationThemeID : themeData.themeID
              dispatch(fetchTheme({
                newThemeID: newTheme,
                history
              }))
            }
  
            setTeamName(teamData.masterName)
            firebase.functions().httpsCallable('getTeamWorkersStats')({
              code: teamID ?? integration
            }).then(async (res) => {
                const stats = JSON.parse(res.data).stats
        
                const consultantListWithProfilePic = await Promise.all(stats.map(async user => {
                  try {
                    const url = await firebase.app().storage().ref(`images/${user.userID}.jpg`).getDownloadURL()
                    return {
                      ...user,
                      profilePic: url
                    }
                  } catch {
                    return user
                  }
                }))
        
                const sortedConsultantByTimeSpend = consultantListWithProfilePic.sort((a, b) => {
                  return b.timeSpentInCall - a.timeSpentInCall
                })
                const sortedConsultant = sortedConsultantByTimeSpend.sort((a, b) => {
                  return (b.online && !b.isBusy) - (a.online && !a.isBusy)
                })
                setConsultantsList(sortedConsultant)
                setSearchedConsultants(sortedConsultant)
              })
          })
        } else {
          setConsultantsList([])
        }
      })
    } else if (integration) {
      firebase.firestore().collection('users').where('code', '==', integration).onSnapshot(res => {
        const consultant = res.docs.map(consultant => consultant.data())
        setConsultantsList(consultant)
        setSearchedConsultants(consultant)
      })
    } else {
      firebase.app().firestore()
        .collection('users')
        .where('consultant', '==', true)
        .where('isPublic', '==', true)
        .where('themeID', '==', realThemeID ?? themeID)
        .orderBy('online', 'desc')
        .orderBy('timeSpentInCall', 'desc').onSnapshot(async res => {
          let users = res.docs.map(consultant => consultant.data())
          const goodThemeID = realThemeID ?? themeID
          if (goodThemeID !== 'lokiTheme' && goodThemeID !== 'yaloopTheme') {
            users = await firebase.functions().httpsCallable('checkTeamMember')({
              themeID: goodThemeID,
              users: JSON.stringify(users)
            })
          }
  
          const realUsers = goodThemeID === 'lokiTheme' || goodThemeID === 'yaloopTheme' ? users : users.data
          const consultantListWithProfilePic = await Promise.all(realUsers.map(async user => {
            try {
              const url = await firebase.app().storage().ref(`images/${user.userID}.jpg`).getDownloadURL()
              return {
                ...user,
                profilePic: url
              }
            } catch {
              return user
            }
          }))
  
          const sortedConsultant = consultantListWithProfilePic.sort((a, b) => {
            return (b.online && !b.isBusy) - (a.online && !a.isBusy)
          })
          setConsultantsList(sortedConsultant)
          setSearchedConsultants(sortedConsultant)
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamID, realThemeID, themeID])

  const handleSearch = (e) => {
    const search = e.target.value.toLowerCase()
    const newSearchedConsultants = consultantsList.filter(consultant => {
      const allName = `${consultant.name} ${consultant.surname}`
      return allName.toLowerCase().includes(search) || consultant.consultancyType.toLowerCase().includes(search)
    })
    setSearchedConsultants(newSearchedConsultants)
  }

  useGlobalStyles()

  return (
    <div className='consultants_list_container'>
      {consultantsList ? 
        <>
          <div>
              <TextField
                className='search'
                variant="outlined"
                size='small'
                onChange={handleSearch}
                placeholder="Type a name or expertise"
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                }}
              />
          </div>
          {isConsultant &&
            <p className='cannot_access_consultant'>
              You cannot access these services as you are logged in with a consultant account.
            </p>
          }
          {searchedConsultants?.map((consultant, index) => (
            <Fragment key={index}>
              <ConsultantItem consultant={consultant} teamID={teamID} teamName={teamName} />
              {index !== searchedConsultants.length - 1 && <Divider orientation='horizontal' variant='fullWidth' />}
            </Fragment>
          ))}
        </>
      :
        <div className='loading'>
          <CircularProgress size={50} />
        </div>
      }
    </div>
  )
};

export default ConsultantsList;
