import React, { useState } from 'react'
import firebase from 'firebase/app'
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button, CircularProgress } from '@material-ui/core'

export default function DeleteAccountDialog(props) {
    const { handleClose, open } = props
    const [loading, setLoading] = useState(false)

    const handleDeleteAccount = () => {
        setLoading(true)
        firebase.functions().httpsCallable("gdprRemoval")().then(() => {
            firebase.app().auth().signOut().finally(() => {
                handleClose()
                setLoading(false)
            })
        }).finally(() => {
            handleClose()
            setLoading(false)
        })
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Delete Account</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you wish to do this? This action is permanent!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="white">
                    Cancel
                </Button>
                <Button onClick={handleDeleteAccount} style={{ color: 'red', width: '70px' }}>
                    {loading ? <CircularProgress color='red' size={20} /> : 'Delete'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
