import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Badge, CircularProgress, Divider, List, ListItem } from '@material-ui/core'
import firebase from 'firebase'
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as NotificationsIcon } from '../../images/notifications.svg';
import BookingCard from '../Home/components/bookingCard/BookingCard';
import ConfirmAlert from '../components/ConfirmAlert';
import useCookieTheme from '../App/useCookieTheme';
import moment from 'moment';
import ClearIcon from '@material-ui/icons/Clear'
import { useSelector } from 'react-redux';
import { userState } from '../../api/userSlice';

const Notifications = (props) => {
    const fromSideBar = props.fromSideBar
    const userID = useSelector(userState).user?.userID
    const isConsultant = useSelector(userState).user?.consultant

    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const [dataSource, setDataSource] = useState([])
    const [alertInfo, setAlertInfo] = useState({ open: false })
    const [loading, setLoading] = useState(false)

    const appName = useCookieTheme().name
    const wrapperRef = useRef(null)
    const iconRef = useRef(null)

    const handleClick = useCallback((event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !iconRef.current.contains(event.target)) {
            if (notificationsOpen) {
                setNotificationsOpen(!notificationsOpen)
            }
        }
    }, [notificationsOpen]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };

        // eslint-disable-next-line 
    }, [wrapperRef, notificationsOpen]);

    useEffect(() => {
        if (!userID) {
            return
        }

        if (isConsultant) {
            firebase.app().firestore().collection('/bookings').where('startDate', '>', new Date())
                .where('consultantID', '==', userID)
                .where('status', "in", ["pending", "processing"])
                .onSnapshot(snapshot => {
                    setDataSource(snapshot.docs.map(each => each.data()))
                    if (snapshot.docs.length > 0) {
                        document.title = `(${snapshot.docs.length}) ${appName}`
                    } else {
                        document.title = appName
                    }
                })
        } else {
            firebase.app().firestore().collection('/sessions').where('missedCallNotified', '==', true)
                .where('clientID', '==', userID)
                .where('status', "==", "rejected")
                .orderBy('dateCreated', 'desc')
                .onSnapshot(snapshot => {
                    setDataSource(snapshot.docs)
                    if (snapshot.docs.length > 0) {
                        document.title = `(${snapshot.docs.length}) ${appName}`
                    } else {
                        document.title = appName
                    }
                })
        }
    }, [appName, userID, isConsultant])

    const Row = (booking, index) => {
        return (
            <Fragment key={index}>
                <ListItem>
                    <BookingCard
                        index={index}
                        variant="notifications"
                        data={booking}
                        isClient={false}
                        activeCall={null}
                        fromSideBar={fromSideBar}
                        setAlertInfo={setAlertInfo}
                    />
                </ListItem>
                {index !== dataSource.length - 1 &&
                    <Divider variant='middle' />
                }
            </Fragment>
        )
    };

    const RowClient = (sessionDoc, index) => {
        const session = sessionDoc.data()

        const removeNotif = (id) => {
            setLoading(true)
            firebase.functions().httpsCallable('removeSessionMissedCallNotified')({
                sessionID: id
            }).finally(() => {
                setLoading(false)
            })
        }

        return (
            <Fragment key={index}>
                <div className='notif_client'>
                    <p>{session.consultantFullName} informs you that they are now available to be called after you called them at {moment(session.dateCreated.toDate()).format('MMMM Do YYYY hh:mm A')} ({Intl.DateTimeFormat().resolvedOptions().timeZone}).</p>
                    <ClearIcon className='booking_declined_button' onClick={() => removeNotif(sessionDoc.id)} />
                </div>
                {index !== dataSource.length - 1 &&
                    <Divider variant='middle' />
                }
            </Fragment>
        )
    };

    return (
        <div>
            <IconButton className={`${isConsultant ? 'notifications_button' : ''}`} ref={iconRef} onClick={() => setNotificationsOpen(!notificationsOpen)} aria-label="delete">
                <Badge badgeContent={dataSource.length} color="error">
                    <NotificationsIcon className="notifications_icon" />
                </Badge>
            </IconButton>
            {notificationsOpen && (
                <div className="notifications_content" ref={wrapperRef} >
                    {dataSource.length ? (
                        <List className='notifications_list'>
                            {loading ?
                                <div className='loading'>
                                    <CircularProgress size={30} />
                                </div>
                            :
                                <>
                                    {dataSource.map(isConsultant ? Row : RowClient)}
                                </>
                            }
                        </List>
                    ) : (
                        <div style={{ margin: '20px', textAlign: 'center' }}>
                            No requests
                        </div>
                    )}
                </div>
            )}
            {alertInfo.open && <ConfirmAlert data={alertInfo} />}
        </div>
    )
};

export default Notifications;