import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'firebase/firestore'
import { Chat } from '@material-ui/icons'
import { IconButton, Popover } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import firebase from 'firebase/app'
import ActiveChat from './ActiveChat';

const BookingChatIcon = (props) => {
  const { sessionData, isConsultant, sessionID, fromActiveCall, otherHandle } = props
  const ref = sessionData.bookingID ? firebase.app().firestore().doc(`/bookings/${sessionData.bookingID}`) : firebase.app().firestore().doc(`/sessions/${sessionID}`)

  const [showChatBadge, setShowChatBadge] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [chatData, setChatData] = useState(sessionData);

  const chatOpened = Boolean(anchorEl)
  const chatID = chatOpened ? 'simple-chat' : undefined;
  const updateBadge = useRef(false);

  useEffect(() => {
    const asyncGetChatData = async () => {
      const bookingData = (await ref.get()).data()
      setChatData(bookingData)
    }

    if (sessionData.bookingID) {
      asyncGetChatData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChat = async (event) => {
    setAnchorEl(event.currentTarget);
    setShowChatBadge(false)
    updateBadge.current = true
    await seenChat()
  };

  const handleClose = async () => {
    updateBadge.current = false
    setAnchorEl(null)
    await seenChat()
  };

  const seenChat = async () => {
    await ref.update(isConsultant ? {
      consultantHasNewMessage: false
    } : {
      clientHasNewMessage: false
    })
  }

  useEffect(() => {
    ref.onSnapshot((res) => {
      const data = res.data()
      setShowChatBadge(isConsultant ? data?.consultantHasNewMessage : data?.clientHasNewMessage )
    })
  }, [ref, isConsultant])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeChat = useCallback(() => {
    return (
      <ActiveChat
        userID={firebase.app().auth().currentUser?.uid}
        isConsultant={isConsultant}
        sessionData={chatData}
        sessionID={sessionID}
        fromActiveCall={fromActiveCall}
        otherHandle={otherHandle}
      />
    )
  })()

  return (
    <div className='chat'>
      <IconButton onClick={handleChat}>
        <Badge badgeContent={showChatBadge ? '' : 0} color="error" variant='dot'>
          <Chat className='chat_Button' />
        </Badge>
      </IconButton>
      <Popover
        open={chatOpened}
        id={chatID}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {activeChat}
      </Popover>
    </div>
  )
};

export default BookingChatIcon;
