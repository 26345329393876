import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { CircularProgress, FormControl, FormControlLabel, makeStyles, Radio, RadioGroup, Divider } from '@material-ui/core';
import { useGlobalStyles } from '../../../App/globalStyles';
import AddBankAccount from './AddBankAccount';
import { electronicFormatIBAN, isValidBIC, isValidIBAN } from 'ibantools';
import { accountNumber, routingNumber } from 'us-bank-account-validator';
import firebase from 'firebase';
import { SYMBOLS, minWithdrawal, localeToCurrencyName } from '../../../../utils/utils';
import AddPaypal from './AddPaypal';
import moment from 'moment'
import EditIcon from '@material-ui/icons/Edit'
import { countries } from '../../../../utils/countries';
import { useDispatch, useSelector } from 'react-redux';
import { getBalance, setErrorDetails, userState } from '../../../../api/userSlice';

const WithdrawDialog = (props) => {
  const { open, handleClose, fromTeam, withdrawType, withdrawTeamBalance, isSet, teamBalance } = props;
  const dispatch = useDispatch()
  const dbUser = useSelector(userState).user
  const userID = useSelector(userState).user?.userID
  const balance = teamBalance ?? useSelector(userState).balance
  const isUS = dbUser?.locale === 'us'

  const [user, setUser] = useState(null);
  const [type, setType] = useState(withdrawType ?? null);
  const [preview, setPreview] = useState(fromTeam && isSet);
  const [addBankAccount, setAddBankAccount] = useState(!isSet && withdrawType === 'bank');
  const [addPaypal, setAddPaypal] = useState(!isSet && withdrawType === 'paypal');
  const [isLoading, setIsLoading] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [details, setDetails] = useState(null);
  const [proceedDate, setProceedDate] = useState(null);

  const [bankAccountData, setBankAccountdata] = useState({})
  const [paypalEmail, setPaypalEmail] = useState(null)

  useEffect(() => {
    firebase.firestore().doc(`users/${userID}`).onSnapshot((userSnap) => {
      setUser(userSnap.data())
    })
  }, [userID])
  
  useEffect(() => {
    const disabled = 
      (addBankAccount && (!bankAccountData?.firstName || 
        !bankAccountData?.lastName || 
        !bankAccountData?.accountNumberIBAN || 
        !bankAccountData?.SWIFT || 
        !bankAccountData?.country || 
        (isUS && !bankAccountData?.bankName) || 
        (isUS && !bankAccountData?.routingNumber))) ||
      (addPaypal && !paypalEmail) ||
      (preview && details && balance <= minWithdrawal) ||
      false
    setDisabledSave(disabled)
  }, [addBankAccount, addPaypal, balance, bankAccountData, details, isUS, paypalEmail, preview, type])
  
  useEffect(() => {
    const accountDetails = async () => {
      const detailsAccountData = (await firebase.app().firestore().doc(`${type}_accounts/${userID}`).get()).data()
      setDetails(detailsAccountData)
    }
    if (type) {
      accountDetails()
    }

    if (preview) {
      const startDate = moment().add(4, 'days')
      const endDate = moment().add(5, 'days')
      if (startDate.month() === endDate.month()) {
        setProceedDate(`${startDate.date().toString()}-${endDate.date().toString()} ${startDate.add(1, 'month').subtract(1, "month").startOf("month").format('MMMM')}`)
      } else {
        setProceedDate(`${startDate.date().toString()} ${startDate.add(1, 'month').subtract(1, "month").startOf("month").format('MMMM')}-${endDate.date().toString()} ${endDate.add(1, 'month').subtract(1, "month").startOf("month").format('MMMM')}`)
      }
    }
  }, [preview, type, userID])

  const handleNext = () => {
    if (addBankAccount) {
      handleSaveBankAccount()
    } else if (addPaypal) {
      handleSavePaypal()
    } else if (preview) {
      if (fromTeam) {
        withdrawTeamBalance(`${type}_account`)
        handleClose()
      } else {
        withdrawBalance()
      }
    } else {
      if (type === 'bank') {
        if (user?.bank_account && details) {
          setPreview(true)
        } else {
          setAddBankAccount(true)
        }
      } else if (type === 'paypal') {
        if (user?.paypal_account && details) {
          setPreview(true)
        } else {
          setAddPaypal(true)
        }
      }
    }
  };

  const withdrawBalance = () => {
    setIsLoading(true)
    firebase.firestore().runTransaction(async (transaction) => {
      const resWithDrawal = await firebase.app().firestore().collection('withdrawals').where('user', '==', userID).get()
      const resWithDrawalPending = resWithDrawal.docs.find(doc => !doc.data().teamManagerID && doc.data().status === 'pending')
      if (resWithDrawalPending) {
        dispatch(setErrorDetails({
          message: 'You already have a pending withdrawal! Please wait until it\'s processed.'
        }))
        return Promise.resolve()
      }
      const withdrawalData = {
        user: userID,
        amount: balance,
        date: new Date(),
        status: 'pending',
        withdrawalAccount: `${type}_account`,
        isTeamWithdrawalPart: false
      }
      const withdrawalRef = firebase.firestore().collection("/withdrawals").doc()
      transaction.set(withdrawalRef, withdrawalData)
      const userRef = firebase.firestore().doc(`/users/${userID}`)
      transaction.update(userRef, { lastWithdrawal: new Date() })
      return Promise.resolve()
    }).catch(e => {
      dispatch(setErrorDetails({
        message: e.message
      }))
    }).then(() => {
      handleClose()
    }).finally(() => {
      setIsLoading(false)
      dispatch(getBalance(true))
    })
  }

  const handleSaveBankAccount = async () => {
    setIsLoading(true)
    if (isUS) {
      const checkAccountNumber = accountNumber(bankAccountData.accountNumberIBAN).isValid
      const checkRoutingNumber = routingNumber(bankAccountData.routingNumber).isValid
      const checkSWIFT = isValidBIC(bankAccountData.SWIFT)
      if (!checkAccountNumber || !checkRoutingNumber || !checkSWIFT) {
        dispatch(setErrorDetails({
          message: 'Invalid Bank Account!'
        }))
        setIsLoading(false)
        return
      }
    } else {
      const iban = electronicFormatIBAN(bankAccountData.accountNumberIBAN)
      if (!isValidIBAN(iban) || !isValidBIC(bankAccountData.SWIFT)) {
        dispatch(setErrorDetails({
          message: 'Invalid Bank Account!'
        }))
        setIsLoading(false)
        return
      }
    }
    
    const setData = {
      name: bankAccountData.firstName,
      surname: bankAccountData.lastName,
      country: bankAccountData.country,
      currency: isUS ? SYMBOLS.USD : bankAccountData.currency,
      swift: bankAccountData.SWIFT
    }
    if (isUS) {
      setData.accountNumber = bankAccountData.accountNumberIBAN;
      setData.achRoutingNumber = bankAccountData.routingNumber;
      setData.bankName = bankAccountData.bankName;
    } else {
      setData.iban = bankAccountData.accountNumberIBAN;
    }

    const refName = user[`${type}_account`]
    const newRefName = refName ? refName.path : `/bank_accounts/${userID}`
    firebase.app().firestore().doc(newRefName).set(setData).then(() => {
      const setUserData = {
        bank_account: firebase.app().firestore().doc(newRefName)
      }
      firebase.app().firestore().doc(`/users/${userID}`).update(setUserData).then(() => {
        setPreview(true)
        setAddBankAccount(false)
      })
    }).catch(e => {
      dispatch(setErrorDetails({
        message: e.message
      }))
    }).finally(() => {
      setIsLoading(false)
    });
  }

  const handleSavePaypal = async () => {
    setIsLoading(true)
    
    const refName = user[`${type}_account`]
    const newRefName = refName ? refName.path : `/paypal_accounts/${userID}`
    firebase.app().firestore().doc(newRefName).set({ email: paypalEmail }).then(async () => {
      const setData = {
        paypal_account: firebase.app().firestore().doc(newRefName)
      }
      firebase.app().firestore().doc(`/users/${userID}`).update(setData).then(() => {
        setPreview(true)
        setAddPaypal(false)
      })
    }).catch(e => {
      dispatch(setErrorDetails({
        message: e.message
      }))
    }).finally(() => {
      setIsLoading(false)
    });
  }

  const handleEdit = async () => {
    const refName = user[`${type}_account`]
    const accountData = await firebase.app().firestore().doc(refName.path).get()
    const data = accountData.data()
    if (type === 'bank') {
      const bankAccountData ={
        firstName: data.name,
        lastName: data.surname,
        accountNumberIBAN: isUS ? data.accountNumber : data.iban,
        SWIFT: data.swift,
        routingNumber: isUS ? data.achRoutingNumber : null,
        country: isUS ? data.country : countries.find((country) => country.label === data.country),
        bankName: isUS ? data.bankName : null,
        currency: data.currency
      }
      setBankAccountdata(bankAccountData)
      setAddBankAccount(true)
    } else {
      setPaypalEmail(data.email)
      setAddPaypal(true)
    }
    setPreview(false)
  }

  const useStyles = makeStyles({
    circularProgress: {
      margin: 'auto',
      display: 'block'
    }
  });
  const styleClasses = useStyles();
  useGlobalStyles()

  return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogContent className='add_bank_dialog withdraw_dialog'>
          <DialogContentText>
            {addBankAccount ? 'Add Bank Account' : addPaypal ? 'Add Paypal' : preview ? 'Withdraw' : 'Please select which withdrawal method do you prefer'}
          </DialogContentText>
          {addBankAccount && <AddBankAccount isUS={isUS} bankAccountData={bankAccountData} setBankAccountdata={setBankAccountdata} />}
          {addPaypal && <AddPaypal email={paypalEmail} setEmail={setPaypalEmail} />}
          {preview && details &&
            <>
              <div>
                To {type} account: {type === 'paypal' ? details.email : isUS ? details.accountNumber : details.iban}
                <Button className='button_edit' variant="outlined" color="primary" onClick={handleEdit}>
                  <EditIcon />
                </Button>
              </div>
              <Divider style={{ marginTop: 20 }} />
              {balance <= minWithdrawal ?
                <p>Your balance must be at least {minWithdrawal / 100} {localeToCurrencyName(dbUser?.locale)}, so you can't withdraw your funds at this time</p>
              :   
                <p>Your transfer will arrive on {proceedDate}. Would you like to proceed?</p>
              }
            </>
          }
          {!fromTeam && !addBankAccount && !addPaypal && !preview &&
            <FormControl>
              <RadioGroup value={type} onChange={(e) => setType(e.target.value)}>
                <FormControlLabel value="bank" control={<Radio />} label="Bank Account" />
                <FormControlLabel value="paypal" control={<Radio />} label="Paypal" />
              </RadioGroup>
            </FormControl>
          }
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button className='withdraw_dialog_button' onClick={handleNext} disabled={disabledSave} color="primary">
            {isLoading ?
              <CircularProgress size={20} className={styleClasses.circularProgress}/> : 
              addBankAccount || addPaypal ? 'Save' :
              preview ? 'Withdraw' : 'Next'
            }
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default WithdrawDialog