import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import PhoneIcon from '@material-ui/icons/Phone';
import VideocamIcon from '@material-ui/icons/Videocam';
import ChatIcon from '@material-ui/icons/Chat'
import Typography from '@material-ui/core/Typography'
import './styles.css';
import { displayAsCurrency } from '../../../../utils/utils'
import moment from 'moment'
import { config } from '../../../../config/config';

const HistoryRow = (props) => {
    const clientName = props.data.clientFullName ?? props.data.clientHandle
    const locale = props.data.locale

    const sessionDuration = (data) => {
        if (data.bookingID) {
            return `${data.length} minutes`  
        }

        const firstPing = data.firstPingDate
        const lastPing = data.lastPingDate

        if (firstPing && lastPing) {
            if (data.bookingID) {
                return data.length + " minutes"
            }
            const firstSeconds = firstPing["_seconds"]
            const lastSeconds = lastPing["_seconds"]
            const minutesDuration = Math.max(Math.ceil((lastSeconds - firstSeconds) / 60), 1)
            return minutesDuration + " minutes"
        } else {
            return "0 minutes"
        }
    }

    const sessionInterval = (data) => {
        const firstPing = data.firstPingDate
        const dateCreated = data.dateCreated
        const dateStart = data.startDate

        if (firstPing || dateStart) {
            const isBooking = Boolean(data.bookingID)
            const startSeconds = dateStart?._seconds
            const firstPingSeconds = firstPing?._seconds
            const seconds = isBooking ? startSeconds : firstPingSeconds
            const firstSeconds = seconds

            const firstDate = new Date(0)
            firstDate.setUTCSeconds(firstSeconds)

            return `${moment(firstDate).format("MMM Do hh:mm A")}`
        } else {
            const utcSeconds = dateCreated["_seconds"]
            const date = new Date(0);
            date.setUTCSeconds(utcSeconds);

            return `${moment(date).format("MMM Do hh:mm A")}`
        }
    }

    const sessionTypeIcon = (data) => {
        const videoEnabled = data.videoEnabled !== undefined ? data.videoEnabled : data.callType === 'video'
        const chatCall = data.isChatCall !== undefined ? data.isChatCall : data.callType === 'chat'
        return videoEnabled ? 
                <VideocamIcon style={{ color: "#5b5b5b" }}></VideocamIcon> : 
                    chatCall ? <ChatIcon style={{ color: "#5b5b5b" }}></ChatIcon> :
                        <PhoneIcon style={{ color: "#5b5b5b" }}></PhoneIcon>
    }

    const calculatePrice = (data) => {
        const salesTax = data.salesTax
        let realPrice
        if (data.bookingID) {
            realPrice = data.estimatedPrice
        } else {
            const price = data.price
            const successfullyCharged = data.successfullyCharged
            const minutes = successfullyCharged / (price + (salesTax ?? 0))
            realPrice = price * minutes
        }
        const customConsultantPercentage = data.customConsultantPercentage
        const teamMemberPercentage = data.teamMemberPercentage
        const discountPaidByConsultant = data.discountPaidByConsultant
        return realPrice * (customConsultantPercentage ?? 1) * (teamMemberPercentage ?? 1) - (discountPaidByConsultant ?? 0)
    }

    const themeMainColor = useTheme().palette.primary.main
    const useStyles = makeStyles({
        priceLabel: {
            paddingLeft: 5,
            color: themeMainColor
        },
        durationLabel: {
            paddingLeft: 5,
            color: "#5b5b5b"
        },
        intervalLabel: {
            color: "#5b5b5b"
        }
    });
    const styleClasses = useStyles();

    return (
        <div id="wrapper">
            <div id="left">
                <div>
                    <div>{clientName}</div>
                    <div>
                        <Typography className={styleClasses.intervalLabel}>
                            {sessionInterval(props.data)}
                        </Typography>
                    </div>
                </div>
            </div>
            <div id="right">
                <div id="child">
                    {props.data.bookingID ? `${config.type === 'astrologer' ? 'R' : 'B'}` : 'I'}
                </div>
                <div id="spacer-child" ></div>
                <div id="child">
                    <div id="wrapper">
                        <div id="child"> 
                            <SvgIcon id="svg-icon">
                                {sessionTypeIcon(props.data)}
                            </SvgIcon>
                        </div>
                        <div id="child">
                            <Typography className={styleClasses.durationLabel}>
                                {sessionDuration(props.data)}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div id="spacer-child" ></div>
                <div id="child">
                    <Typography className={styleClasses.priceLabel}>
                        {props.data.isFree ? 'FREE' : `+${displayAsCurrency(calculatePrice(props.data), locale)}`}
                    </Typography>
                </div>
            </div>
        </div>
    )
}

export default HistoryRow
