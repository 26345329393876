import React, {  } from 'react'
import 'firebase/functions'
import { useBookingContext } from '../BookingContext';
import { formattedIntegerLocaleSeparator, localeToCurrency } from '../../../../utils/utils';
import { Button } from '@material-ui/core';
import { AUTH_ROUTES_CLIENT } from '../../../../utils/routes';
import { config } from '../../../../config/config';
import useCookieTheme from '../../../App/useCookieTheme';
import useBookingHook from '../../useBookingHook';

const UserPaymentInformation = () => {
  const {
    bookingData,
    paymentInfoData,
    couponData,
    discountCouponData,
    isDetailsForm,
    toPayment
  } = useBookingContext()
  const { setBooking } = useBookingHook()
  const { changePage } = useCookieTheme()

  return (
    <div className='item'>
      <div className='margin_item'>
        <p className='title'>PAYMENT INFORMATION</p>

        <div className='border_left user_information'>
          {paymentInfoData?.stripeDefaultPMHint ? 
            <>
              {paymentInfoData.stripeDefaultPMHint}
              <Button
                className='dismiss_buttton'
                onClick={() => {
                  toPayment.current = true
                  setBooking({
                    bookingData,
                    couponData,
                    discountCouponData,
                    isDetailsForm,
                  })
                  changePage({
                    pathname: AUTH_ROUTES_CLIENT.PAYMENT,
                    search: 'isEdit=true'
                  })
                }}
                color="primary"
                variant="outlined"
              >
                <i>edit</i>
              </Button>
            </>
          : 
            <>
              Please add your payment information here <i>(required)</i>
              <Button
                className='dismiss_buttton'
                onClick={() => {
                  toPayment.current = true
                  setBooking({
                    bookingData,
                    couponData,
                    discountCouponData,
                    isDetailsForm,
                  })
                  changePage({
                    pathname: AUTH_ROUTES_CLIENT.PAYMENT
                  })
                }}
                color="primary"
                variant="outlined"
              >
                <i>add</i>
              </Button>
            </>
          }
          {bookingData.data.estimatedPrice > 0 &&
            <>
              <br /><br />
              <b>Your card will be charged {localeToCurrency(bookingData.data.locale)}{formattedIntegerLocaleSeparator(config.type === 'astrologer' ? Math.round(bookingData.data.estimatedPrice * 1.04) : bookingData.data.estimatedPrice, bookingData.data.locale)} TOTAL.</b><br />
              {config.type === 'astrologer' && <i>includes 4% Sales Tax</i>}
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default UserPaymentInformation;
