import React, {  } from 'react'
import useCookieTheme from '../../App/useCookieTheme';
import Availability from '../components/Availability';

const BookingsScheduleTab = () => {
  const { availabilityHeading } = useCookieTheme()

  return (
    <div id='profile_availability' className='content_item'>
        <div className='content_label heading'>
            {availabilityHeading ?? 'Bookings Schedule'}
        </div>
        <Availability />
    </div>
  )
}

export default BookingsScheduleTab;
