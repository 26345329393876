import moment from 'moment'

export const toIntMonth = (mMoment) => {
  const month = mMoment.month()
  const year = mMoment.year()
  return year * 100 + month + 1
}

export const minsSinceMidnight = (mmt) => {
  const mmtMidnight = mmt.clone().startOf('day');
  return mmt.diff(mmtMidnight, 'minutes');
}


export const validTime = (curData, mTime, length, buffer) => {
  if (!curData) {
    return false
  }
  const curMoment = moment(mTime)

  const isoD = curMoment.toISOString()
  const maxLength = curData[isoD]
  if (maxLength === undefined) {
    return false
  }
  return length <= maxLength
}