import React from 'react'
import ContinueButton from '../ConsultantBooking/components/ContinueButton';
import ContactInformation from '../ConsultantBooking/components/ContactInformation';
import PaymentInformation from '../ConsultantBooking/components/PaymentInformation';
import CreateAccountOptional from '../ConsultantBooking/components/CreateAccountOptional';
import Header from '../ConsultantBooking/components/Header';
import ConsultantDetails from '../ConsultantBooking/components/ConsultantDetails';
import { useBookingContext } from '../ConsultantBooking/BookingContext';
import { useEffect } from 'react';
import firebase from 'firebase/app'
import { AUTH_ROUTES_CONSULTANT, COMMON_ROUTES } from '../../../utils/routes';
import { CircularProgress } from '@material-ui/core';
import { useState } from 'react';
import { isValidEmail } from '../../../utils/utils';
import ConfirmAlert from '../../components/ConfirmAlert';
import useCookieTheme from '../../App/useCookieTheme';
import { defaultTheme } from '../../../config/config';
import { useContext } from 'react';
import { FirebaseContext } from '../../Firebase';
import { beforeTestMessage } from '../../../utils/constants';
import { useGlobalStyles } from '../../App/globalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorDetails, setIsSignUpInBookingProcess, userState } from '../../../api/userSlice';
import { themeState } from '../../../api/themeSlice';

const GuestInstantCall = (props) => {
  const { consultant } = props.match.params
  const startCall = props.location.startCall
  const isChatCall = JSON.parse((new URL(document.location)).searchParams.get("isChatCall") ?? null)
  const videoEnabled = JSON.parse((new URL(document.location)).searchParams.get("videoEnabled") ?? null)
  const { changePage } = useCookieTheme()
  const dispatch = useDispatch()
  const integration = useSelector(userState).integration
  const testIntegration = useSelector(userState).testIntegration
  const themeID = useSelector(themeState).theme?.id
  const realThemeID = useSelector(themeState).realThemeID
  const firebaseSignIn = useContext(FirebaseContext)

  const { consultantData, setConsultantData, contactInfoData, paymentInfoData, openForm, createAccountData } = useBookingContext()
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [alertInfo, setAlertInfo] = useState({ open: false })
  const [emailUsed, setEmailUsed] = useState(false)

  useEffect(() => {
    let disable = !contactInfoData || !contactInfoData.firstName || !contactInfoData.lastName || !contactInfoData.email || 
                    !isValidEmail(contactInfoData.email) || !paymentInfoData
    if (openForm) {
      disable = disable || !createAccountData || !createAccountData.password || !createAccountData.confirmPassword || 
                createAccountData.password !== createAccountData.confirmPassword
    }

    setDisabled(disable)
  }, [contactInfoData, createAccountData, openForm, paymentInfoData])

  useEffect(() => {
    return firebase.app().firestore().doc(`/users/${consultant}`).onSnapshot((snapshot) => {
      const data = snapshot.data()

      if (!data?.consultant) {
        changePage({
          pathname: AUTH_ROUTES_CONSULTANT.HOME
        })
      } else {
        setConsultantData(data)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultant, setConsultantData])

  const signIn = async () => {
    setLoading(true)
      
    dispatch(setIsSignUpInBookingProcess(true))

    const toSend = {
      email: contactInfoData.email,
      password: createAccountData.password,
      consultant: false,
      name: contactInfoData.firstName,
      surname: contactInfoData.lastName,
      locale: 'us',
      consultantCodes: [],
      stripeVersion: "2020-08-27",
    }
    if (integration) {
      toSend.integrationThemeID = themeID
      toSend.themeID = realThemeID
    } else {
      if (themeID) {
        toSend.themeID = themeID
      } else {
        toSend.themeID = defaultTheme.id
      }
    }
    
    const uid = (await firebase.functions().httpsCallable("createUserFlow")(toSend)).data.uid

    if (Boolean(integration)) {
      localStorage.setItem('loggedInWidget', true)
      await firebase.auth().signOut()
    }
    await firebaseSignIn.signIn(contactInfoData.email, createAccountData.password)

    startGuestCall(uid)
  }

  const startGuestCall = (uid) => {
    setLoading(true)
    if (isChatCall) {
      startCall(contactInfoData, uid)
    } else {
      setAlertInfo({
        open: true, 
        title: "",
        description: beforeTestMessage(videoEnabled),
        okTitle: 'Test',
        cancelTitle: 'Skip',
        onConfirm: () => {
          setAlertInfo({ open: false })
          changePage({
            pathname: COMMON_ROUTES.TEST,
            search: `videoEnabled=${videoEnabled}`,
            startCall: () => { startCall(contactInfoData, uid) },
          })
        },
        onCancel: () => {
          setAlertInfo({ open: false })
          startCall(contactInfoData, uid)
        },
        onClose: () => {
          setAlertInfo({ open: false })
          setLoading(false)
        }
      })
    }
  }

  useGlobalStyles()

  if (!startCall) {
    changePage({
      pathname: COMMON_ROUTES.CONSULTANT.replace(':consultant', consultant)
    })
  }

  return (
    <>
      {consultantData ?
        <div className='consultant_booking_content'>
          <Header isInstantCall={true} />
          <ConsultantDetails />

          <ContactInformation
            isInstantCall={true}
            setEmailUsed={setEmailUsed}
          />
          <PaymentInformation />
          <CreateAccountOptional />

          <ContinueButton
            disabled={loading || disabled}
            loading={loading}
            onClick={async () => {
              if (emailUsed) {
                dispatch(setErrorDetails({
                  message: 'This email is already in use. Either login or enter another email as guest!'
                }))
                return
              }
              if (contactInfoData.email !== contactInfoData.confirmEmail) {
                dispatch(setErrorDetails({
                  message: "The emails do not match!"
                }))
                return
              }

              if (testIntegration) {
                dispatch(setErrorDetails({
                  message: 'Cannot have a call in preview widget because you\'re logged as consultant!',
                  severity: 'warning',
                  verticalAnchor: 'bottom'
                }))
              } else {
                const currentUser = firebase.auth().currentUser
                if (openForm && !currentUser) {
                  await signIn()
                } else {
                  startGuestCall()
                }
              }
            }}
            text='START CALL'
          />
        </div>
      :
        <div className='consultant_booking_loading'>
          <CircularProgress size={50} />
        </div>
      }
      
      {alertInfo.open && <ConfirmAlert data={alertInfo} />}
    </>
  )
}

export default GuestInstantCall;
