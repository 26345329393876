import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import firebase from 'firebase'
import { config } from '../../config/config';

const fConfig = () => {
  return config;
}

export const cloudFunctionsURL = () => {
  return fConfig().cloudFunctionsURL
}

class Firebase {
  constructor() {
    app.initializeApp(fConfig());
    this.auth = app.auth()
    this.firestore = app.firestore()
    this.storage = app.storage()
    this.functions = app.functions()
    this.euFunctions = firebase.app().functions("europe-west1")
  }

  setRememberMe = (on) => {
    this.auth.setPersistence(on ? app.auth.Auth.Persistence.LOCAL : app.auth.Auth.Persistence.SESSION)
  }

  signIn = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)
}

export default Firebase;