import React, {  } from 'react'
import 'firebase/functions'
import BookingStepper from './BookingStepper'
import { Button } from '@material-ui/core'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useBookingContext } from '../BookingContext';
import BookingReviewDetails from './BookingReviewDetails';

const ReviewBooking = (props) => {
  const { isGift, rescheduleBookingID } = props
  const { setIsDetailsForm } = useBookingContext()

  return (
    <div className='item'>
      <BookingStepper />
      <div className='margin_item'>
        <p className='heading'>Please review your selection:</p>
        <div className='border_left'>
          <BookingReviewDetails onlyReviewCall={true} isGift={isGift} rescheduleBookingID={rescheduleBookingID} />
        </div>
        <Button
          className='dismiss_buttton edit_buttton'
          onClick={() => setIsDetailsForm(false)}
          variant="outlined"
        >
          <KeyboardArrowLeftIcon />
          EDIT
        </Button>
      </div>
    </div>
  )
}

export default ReviewBooking;
