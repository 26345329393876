import React, { useEffect, useState } from 'react'
import 'firebase/functions'
import firebase from 'firebase/app'
import { useBookingContext } from '../BookingContext'

const ConsultantDetails = () => {
  const { consultantData } = useBookingContext()
  
  const [consultantProfilePic, setConsultantProfilePic] = useState(null)

  useEffect(() => {
    firebase.app().storage().ref(`images/${consultantData.userID}.jpg`).getDownloadURL().then(dlURL => {
      setConsultantProfilePic(dlURL)
    }).catch(_ => {
      console.log('no profile pic')
      setConsultantProfilePic(null)
    })
  }, [consultantData.userID])

  return (
    <div className='item consultant_details'>
      <div>
        <img className="pic profile_pic" alt="" src={consultantProfilePic ?? require("../../../../images/user.png")} />
      </div>
      <div className="section2">
        <div className="label_type_5 heading">
          {consultantData["name"]} {consultantData["surname"]}
        </div>
        <div className="label_type_6">
          {consultantData["consultancyType"]}
        </div>
      </div>
    </div>
  )
}

export default ConsultantDetails;
