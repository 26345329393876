import React, { useState } from 'react'
import firebase from 'firebase/app'
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, DialogContentText, Button } from '@material-ui/core'

export default function ChangePasswordDialog(props) {
    const { handleClose, open } = props

    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")

    const handleUpdatePassword = () => {
        handleClose()

        const currentEmail = firebase.auth().currentUser.email
        firebase.auth().signInWithEmailAndPassword(currentEmail, oldPassword).then(res => {
            firebase.auth().currentUser.updatePassword(newPassword)
        })
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Update password</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please fill in these fields to change your password.
                </DialogContentText>
                <TextField
                    autoFocus
                    type="password"
                    margin="dense"
                    label="Old password"
                    fullWidth
                    onChange={(event) => setOldPassword(event.target.value)}
                />
                <TextField
                    type="password"
                    margin="dense"
                    label="New password"
                    fullWidth
                    onChange={(event) => setNewPassword(event.target.value)}
                />
                <TextField
                    type="password"
                    margin="dense"
                    label="Confirm new password"
                    fullWidth
                    onChange={(event) => setConfirmNewPassword(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="white">
                    Cancel
                </Button>
                <Button disabled={newPassword !== confirmNewPassword || newPassword.length < 3} onClick={handleUpdatePassword} color="primary">
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    )
}
