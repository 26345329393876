import React from 'react';
import { Badge, Button, Divider, useMediaQuery } from '@material-ui/core';
import PerMinuteButtons from '../../ConsultantProfile/components/perMinuteButtons';
import { COMMON_ROUTES } from '../../../utils/routes';
import { config } from '../../../config/config';
import useCookieTheme from '../../App/useCookieTheme';
import { StarRate } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { userState } from '../../../api/userSlice';

const ConsultantItem = (props) => {
  const { consultant, teamID } = props
  const isConsultant = useSelector(userState).user?.consultant
  const { makeAppointmentButtonName, giftButtonName, changePage } = useCookieTheme()

  const makeAppointmentDisabled = isConsultant || !consultant.bookingsEnabled || (
    !consultant.audioBookingAmounts?.filter(amount => amount !== 0).length &&
    !consultant.videoBookingAmounts?.filter(amount => amount !== 0).length &&
    (config.type === 'astrologer' || !consultant.chatBookingAmounts?.filter(amount => amount !== 0).length))
  
  const widthGreaterThan800px = useMediaQuery('(min-width:799px)');

  const MakeAppointmentButton = () => {
    return (
      <Button
        className='make_appointment_button'
        size='small'
        disabled={makeAppointmentDisabled}
        onClick={() => changePage({
          pathname: COMMON_ROUTES.CONSULTANT_BOOKING.replace(':consultant', consultant.userID),
          search: `fromConsultantsList=true${teamID ? `&teamID=${teamID ?? null}` : ''}`
        })}
        color="primary"
        variant="contained"
      >
        {makeAppointmentButtonName ?? 'Make appointment'}
      </Button>
    )
  }

  const GiftAppointmentButton = () => {
    return (
      <Button
        className='make_appointment_button'
        size='small'
        disabled={makeAppointmentDisabled}
        onClick={() => changePage({
          pathname: COMMON_ROUTES.CONSULTANT_BOOKING.replace(':consultant', consultant.userID),
          search: `isGift=true&fromConsultantsList=true${teamID ? `&teamID=${teamID ?? null}` : ''}`,
        })}
        color="primary"
        variant="contained"
      >
        {giftButtonName}
      </Button>
    )
  }

  const ViewProfileButton = () => {
    return (
      <Button
        className='view_profile_button'
        size='small'
        onClick={() => changePage({
          pathname: COMMON_ROUTES.CONSULTANT.replace(':consultant', consultant.userID)
        })}
      >
        View profile
      </Button>
    )
  }

  return (
    <>
      <div className='consultant_item'>
        <div>
          <div className='profile_details' onClick={() => changePage({
            pathname: COMMON_ROUTES.CONSULTANT.replace(':consultant', consultant.userID)
          })}>
            <Badge
              className='badge'
              badgeContent={consultant.online && !consultant.isBusy ? " " : 0}
              color="error"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              variant='dot'
            >
              <img
                src={consultant.profilePic ?? require("../../../images/user.png")}
                alt=""
                className='profile_pic'
              />
            </Badge>
            <div>
              <p
                className='name heading'
                onClick={() => {
                  if (!widthGreaterThan800px) {
                    changePage({
                      pathname: COMMON_ROUTES.CONSULTANT.replace(':consultant', consultant.userID)
                    })
                  }
                }}
              >
                {Boolean(teamID) ? consultant.name : `${consultant.name} ${consultant.surname}`}
              </p>
              <p>{consultant.consultancyType}</p>
              {consultant.generalScore && <p className='general_score'>{Math.round(consultant.generalScore * 10) / 10} <StarRate /></p>}
            </div>
          </div>
          {config.type === 'astrologer' && consultant.online && !consultant.isBusy &&
            <img className='online_now_img' alt="" src={require("../../../images/online_now.jpeg")} />
          }
        </div>
        <div className='consultantProfile consultantProfile_content'>
          <PerMinuteButtons
            data={consultant}
            fromConsultantsList={true}
            teamID={teamID}
          />
          <Divider className='divider' variant='middle' />
          {config.type === 'astrologer' && 
            <div className='heading_book_a_reading'>BOOKED READINGS</div>
          }
          <div className='booked_readings_buttons'>
            <MakeAppointmentButton />
            <GiftAppointmentButton />
          </div>
        </div>
        {widthGreaterThan800px &&
          <ViewProfileButton />
        }
      </div>
    </>
  )
};

export default ConsultantItem;
