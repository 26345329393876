import React, {  } from 'react'
import "../profile.css";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Menu, MenuItem, Slider, useMediaQuery } from '@material-ui/core'
import "react-multi-date-picker/styles/colors/red.css"
import { useState } from 'react';
import { Calendar } from "react-multi-date-picker"
import { useEffect } from 'react';
import { marks, miniMarks, timeConvert } from './sliderRangeUtils';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import firebase from 'firebase/app'
import moment from 'moment'
import { MoreVert } from '@material-ui/icons';
import InfoPopover from '../../components/InfoPopover';
import { setErrorDetails } from '../../../api/userSlice';
import { useDispatch } from 'react-redux';

const SpecificAvailabilityDates = (props) => {
  const { user, excludedDays, specificDates, styleClasses } = props
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [date, setDate] = useState(null)
  const [showSlider, setShowSlider] = useState(false)
  const [sliderRanges, setSliderRanges] = useState([[480, 1200]])
  const [disabledButton, setDisabledButton] = useState([null])
  const [refresh, setRefresh] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [currentSpecificDate, setCurrentSpecificDate] = useState(null)
  const [anchorElInfo, setAnchorElInfo] = React.useState(null);
  const openMenuInfo = Boolean(anchorElInfo);

  const handleInfo = (event) => {
    setAnchorElInfo(event.currentTarget);
  };

  const widthLessThan500px = useMediaQuery('(max-width:499px)');

  let specificDatesHandle = []
  if (specificDates) {
    specificDates.forEach(specificDate => {
      const specificDateHandle = specificDatesHandle.find(specificDateHandle => specificDateHandle.date.getTime() === specificDate.date.getTime())
      if (specificDateHandle) {
        specificDateHandle.values.push({
          offset: specificDate.offset,
          duration: specificDate.duration,
        })
      } else {
        specificDatesHandle.push({
          date: specificDate.date,
          values: [{
            offset: specificDate.offset,
            duration: specificDate.duration,
          }]
        })
      }
    })

    specificDatesHandle = specificDatesHandle.sort((a, b) => a.date.getTime() - b.date.getTime())
    specificDatesHandle.forEach(specificDate => {
      specificDate.values = specificDate.values.sort((a, b) => a.offset - b.offset)
    })
  }

  useEffect(() => {
    const disabled = (!showSlider && !date) || (showSlider && !sliderRanges)
    setDisabledButton(disabled)
  }, [date, showSlider, sliderRanges])

  const addSpedificAvailability = () => {
    setDate(null)
    setShowSlider(false)
    setSliderRanges([[480, 1200]])
    setOpen(true)
  }

  const handleNext = () => {
    const goodDate = () => {
      return moment(date.toDate ? date.toDate() : date).startOf('day').toDate()
    }

    if (showSlider || isEditing) {
      setDisabledButton(true)

      let newSpecificDates = sliderRanges.map(sliderRange => {
        return {
          day: goodDate().getDate(),
          month: goodDate().getMonth(),
          year: goodDate().getFullYear(),
          offset: sliderRange[0],
          duration: sliderRange[1] - sliderRange[0],
        }
      })
      if (isEditing) {
        const specificDatesWithoutNew = specificDates.filter(sd => {
          return sd.date.getTime() !== goodDate().getTime()
        })
        newSpecificDates = newSpecificDates.concat(specificDatesWithoutNew)
      } else {
        newSpecificDates = newSpecificDates.concat(specificDates ?? [])
      }

      finishUpdate(newSpecificDates)
    } else {
      const noGoodDates = (excludedDays ?? []).concat(specificDates ? specificDates.map(date => date.date) : [])
      const d = goodDate()

      let ok = true
      noGoodDates.forEach(noGoodDate => {
        if (noGoodDate.getTime() === d.getTime()) {
          ok = false
          return
        }
      })

      if (ok) {
        setShowSlider(true)
      } else {
        dispatch(setErrorDetails({
          message: 'This date has already been selected either here or in the unavailability calendar'
        }))
      }
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const removeSpecificDate = () => {
    const date = currentSpecificDate.date
    setIsRemoving(true)
    const newSpecificDates = specificDates
      .filter(specificDate => specificDate.date.getTime() !== date.getTime())
    
    finishUpdate(newSpecificDates)
  }

  const editSpecificDate = () => {
    setDate(currentSpecificDate.date)
    setAnchorEl(null)
    const currentSliderRanges = currentSpecificDate.values.map(slider => {
      return [slider.offset % 1440, (slider.offset + slider.duration) % 1440]
    })
    setSliderRanges(currentSliderRanges)
    setIsEditing(true)
  }

  const finishUpdate = (newSpecificDates) => {
    const toSet = {
        specificDates: newSpecificDates
    }

    firebase.app().functions().httpsCallable("updateUserProfile")({
        updateData: JSON.stringify(toSet),
        targetUserID: user.userID,
        isSchedules: true
    }).then(() => {
        console.log("Success updating!")
        setOpen(false)
        setIsEditing(false)
    }).catch((error) => {
        dispatch(setErrorDetails({
          message: error.message
        }))
    }).finally(() => {
        setDisabledButton(false)
        setIsRemoving(false)
        setAnchorEl(null)
    })}

  const handleBack = () => {
    setShowSlider(false)
  }

  return (
    <>
      <div className='header_container heading'>
          <p className='title_info_container'>
            Availability Schedule for Specific Dates
            <InfoPopover
                open={openMenuInfo}
                anchorEl={anchorElInfo}
                setAnchorEl={setAnchorElInfo}
                handleInfo={handleInfo}
                buttonClass='availability_info_button'
            >
                <div className='timezone_info_content'>
                  This feature enables you to specify specific days when you want to be available, overriding both the recurrent scheduling and non-availability settings for those selected days. It comes in handy when you want to work on days that you don't typically have availability.
                </div>
            </InfoPopover>
            </p>
          <Button color="primary" variant="contained" onClick={addSpedificAvailability}>
              Add
          </Button>
      </div>
      <div className='header_container'>
          <p className='red_text'>***takes priority to other scheduling settings</p>
      </div>
      <div className='specific_dates'>
        {specificDatesHandle.length > 0 ? 
          <>
            {specificDatesHandle.map((specificDate, index) => (
              <div key={index}>
                <div className='available_schedule_item'>
                  <div className='available_schedule_label'>
                    {widthLessThan500px ?
                      <p>
                        {moment(specificDate.date).format('MMMM Do')}
                        <br />
                        {moment(specificDate.date).format('YYYY')}
                      </p>
                    :
                      <p>{moment(specificDate.date).format('MMMM Do YYYY')}</p>
                    }
                  </div>
                  <div className='times_and_actions'>
                    <div className='times'>
                      {specificDate.values.map((range, indexRange) => (
                        <p key={indexRange}>
                          <span className={`${styleClasses.time} ${!specificDatesHandle[index] ? styleClasses.timeDisable : ''}`}>
                            {moment().startOf('week').add(range.offset, 'minutes').format('HH:mm')}
                          </span>
                          <span className={`${styleClasses.line} ${!specificDatesHandle[index] ? styleClasses.lineDisable : ''}`}>-</span>
                          <span className={`${styleClasses.time} ${!specificDatesHandle[index] ? styleClasses.timeDisable : ''}`}>
                            {moment().startOf('week').add(range.offset + range.duration, 'minutes').format('HH:mm')}
                          </span>
                        </p>
                      ))}
                    </div>
                    <div className='remove_icon'>
                      {isRemoving ?
                        <CircularProgress size={15} />
                      :
                        <>
                          <MoreVert onClick={(event) => {
                            setCurrentSpecificDate(specificDate)
                            setAnchorEl(event.currentTarget)
                          }} />
                          <Menu
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={() => setAnchorEl(null)}
                          >
                            <MenuItem onClick={editSpecificDate}>
                              Edit
                            </MenuItem>
                            <MenuItem onClick={removeSpecificDate}>
                              Remove
                            </MenuItem>
                          </Menu>
                        </>
                      }
                    </div>
                  </div>
                </div>
                {index !== specificDatesHandle.length -1 && <Divider />}
              </div>
            ))}
          </>
        :
          'No dates'
        }
      </div>

      <Dialog open={open || isEditing} onClose={() => {
        setOpen(false)
        setIsEditing(false)
      }} className='specific_dates_dialog'>
          <DialogTitle className='title'>
            {showSlider || isEditing ? 
              <>
                {isEditing ? 'Edit' : 'Select'} availability schedule<br />{moment(date?.toDate ? date.toDate() : date).format('MMMM Do YYYY')}
              </>
            : 'Select date'}
          </DialogTitle>
          <DialogContent className='specific_dates_dialog_content'>
            {showSlider || isEditing ?
              <>
                {sliderRanges.map((range, indexRange) => (
                    <div key={indexRange} className='sliders_container'>
                        <Slider
                            min={0}
                            max={1440}
                            step={15}
                            style={{ marginHorizontal: 20 }}
                            value={range}
                            onChange={(_, newValue) => {
                                if (newValue[1] > newValue[0]) {
                                  const copyRange = [...sliderRanges]
                                  copyRange[indexRange] = newValue
                                  setSliderRanges(copyRange)
                                }
                            }}
                            valueLabelFormat={(each) => {
                                return timeConvert(each)
                            }}
                            valueLabelDisplay="on"
                            marks={widthLessThan500px ? miniMarks : marks}
                        />
                        {indexRange > 0 &&
                            <RemoveCircleOutline onClick={() => {
                              sliderRanges.splice(indexRange, 1)
                              setSliderRanges(sliderRanges)
                              setRefresh(!refresh)
                            }} />
                        }
                    </div>
                ))}
                <div className='add_slider_button_container'>
                    <AddCircleOutline onClick={() => setSliderRanges([...sliderRanges, [480, 1200]])} />
                </div>
              </>
            :
              <Calendar
                  value={date}
                  multiple={false}
                  className="red"
                  minDate={new Date()}
                  onChange={e => {
                      setDate(e)
                  }}
              />
            }
          </DialogContent>
          <DialogActions className='actions'>
            {showSlider && !isEditing ?
              <Button onClick={handleBack} color="primary">
                  Back
              </Button>
              :
              <div></div>
            }
            <Button disabled={disabledButton} onClick={handleNext} color="primary">
                {showSlider || isEditing ? 'Save' : 'Next'}
            </Button>
          </DialogActions>
      </Dialog>
    </>
  )
}

export default SpecificAvailabilityDates;
