import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { cutOptions } from '../../../MyProfile/components/DiscountCoupons';
import useCookieTheme from '../../../App/useCookieTheme';

const TeamDiscountCouponsRow = (props) => {
    const { data, handleEdit, handleDelete } = props
    const { consultantTitle } = useCookieTheme()

    const themeMainColor = useTheme().palette.primary.main

    return (
        <TableRow>
            <TableCell component="th" scope="row" className='heading'>{data.name}</TableCell>
            <TableCell align="left">{data.percent}%</TableCell>
            <TableCell align="left">{data.expiryDateMonth + 1}/{data.expiryDateDay}/{data.expiryDateYear}</TableCell>
            <TableCell align="left" className='members_discount_coupons'>{data.members?.length ? data.members.map(member => member.name).join(', ') : 'All'}</TableCell>
            <TableCell align="left" className='cut_options_discount_coupons'>{cutOptions(consultantTitle).find(option => option.id === data.cutOption)? cutOptions(consultantTitle).find(option => option.id === data.cutOption).label : '-'}</TableCell>
            <TableCell align="left" className='actions_discount_coupons'>
              <EditIcon style={{ color: themeMainColor, cursor: 'pointer' }} onClick={() => handleEdit(data)} />
              <DeleteIcon style={{ color: 'red', cursor: 'pointer' }} onClick={() => handleDelete(data.id)} />
            </TableCell>
        </TableRow>
    )
}

export default TeamDiscountCouponsRow