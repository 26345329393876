import React, { useEffect, useState } from 'react';
import './styles.css';
import Box from '@material-ui/core/Box'
import MobileStoreButton from 'react-mobile-store-button';
import useCookieTheme from '../App/useCookieTheme';

const Footer = (props) => {
  const { specialCase, isConsultant } = props;
  const [text, setText] = useState(null);
  const { appleURL, googleURL } = useCookieTheme()

  const specialCaseText = 'Please keep the platform open in order to receive calls!';
  const orText = 'OR';
  const res = 'Download the app in order to receive calls<br />and appointments requests directly on your phone!';
  const clientText = 'Find our iOS and Android apps below'
  useEffect(() => {
    const newText = specialCase ? `${specialCaseText + '<br/ >' + orText + '<br/ >' + res}` : res;
    setText(newText);
  }, [specialCase])

  return (
    <Box className="footer">
      <Box>
        <div className="footer_label">
          <span dangerouslySetInnerHTML={{ __html: isConsultant ? text : clientText }} />
        </div>
      </Box>
      <Box className="footer_stores" pt={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: '5px' }}>
        <div className='footer_button_store'>
          <MobileStoreButton
            store="ios"
            url={appleURL}
            linkProps={{ title: 'iOS Store Button', style: { display: 'inline-block', overflow: 'hidden', textDecoration: 'none', height: '100%', width: '100%', padding: '5px', background: 'rgba(0, 0, 0, 0) url("https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/640px-Download_on_the_App_Store_Badge.svg.png") no-repeat scroll 0% 0% / contain' } }}
          />
        </div>
        <div className='footer_button_store'>
          <MobileStoreButton
            store="android"
            url={googleURL}
            linkProps={{ title: 'Android Store Button', style: { display: 'inline-block', overflow: 'hidden', textDecoration: 'none', height: '100%', width: '100%', padding: '5px', background: 'rgba(0, 0, 0, 0) url("https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg") no-repeat scroll 0% 0% / contain' } }}
          />
        </div>
      </Box>
    </Box>
  )
};


export default Footer;
