import React from 'react'
import 'firebase/functions'
import BookingStepper from './BookingStepper';
import { Button, capitalize } from '@material-ui/core';
import { useBookingContext } from '../BookingContext';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import BookingReviewDetails from './BookingReviewDetails';
import ConfirmBookingButton from './ConfirmBookingButton';
import useCookieTheme from '../../../App/useCookieTheme';

const ConfirmBooking = (props) => {
  const { rescheduleBookingID, isGift } = props
  const {
    setIsDetailsForm,
    setIsFinishBooking,
  } = useBookingContext()
  const { consultantTitle, appointmentName } = useCookieTheme()

  return (
    <div className='item'>
      <BookingStepper />
      <div className='margin_item confirm_booking'>
        <div className='astrologer_text heading big_astrologer_text'>
          {isGift ?
            <>
              Please review and confirm your gift request below. A gift will be sent to your giftee at your delivery date set.
            </>
          :
            <>
              Please review and confirm your {appointmentName ?? 'appointment'} request below. Once you hit confirm,&nbsp;
              {appointmentName ? `a ${appointmentName}` : 'an appointment'} request will be sent to your selected&nbsp;
              {capitalize(consultantTitle)}. <b>Your credit card will not be charged and your {appointmentName ?? 'appointment'}&nbsp;
              will not be officially confirmed until your {capitalize(consultantTitle)} accepts the booking
              </b> (bookings are accepted within 24 hours). We will send you an email confirmation once your&nbsp;
              {appointmentName ?? 'appointment'} has been accepted.
            </>
          }
        </div>
        <p className='confirm_booking_title'>YOUR REQUESTED {isGift ? 'GIFT' : `${appointmentName ? appointmentName.toUpperCase() : 'APPOINTMENT'}`}</p>
        <div className='border_left'>
          <BookingReviewDetails isGift={isGift} rescheduleBookingID={rescheduleBookingID} />
        </div>
        <Button
          className='dismiss_buttton edit_buttton'
          onClick={() => {
            setIsDetailsForm(true)
            setIsFinishBooking(false)
          }}
          variant="outlined"
        >
          <KeyboardArrowLeftIcon />
          EDIT
        </Button>

        <ConfirmBookingButton
          rescheduleBookingID={rescheduleBookingID}
          isGift={isGift}
        />
      </div>
    </div>
  )
}

export default ConfirmBooking;
