import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import firebase from 'firebase/app'

const ProfileContext = React.createContext();

function ProfileProvider(props) {
  console.log('RENDER CONTEXT')

  const [userID, setUserID] = useState(null)
  const [user, setUser] = useState(null)
  const [isPublicProfile, setIsPublicProfile] = useState(false)
  const [isGratuityPeriod, setIsGratuityPeriod] = useState(false)
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [severity, setSeverity] = useState('error')
    
  const [anchorEl, setAnchorEl] = useState({
      isPublicProfile: null,
      isGratuityPeriod: null,
      enableRecordings: null,
      needBirthForm: null
  });
  const [refreshPage, setRefreshPage] = useState(false);
  const open = {
      isPublicProfile: Boolean(anchorEl.isPublicProfile),
      isGratuityPeriod: Boolean(anchorEl.isGratuityPeriod),
      enableRecordings: Boolean(anchorEl.enableRecordings),
      needBirthForm: Boolean(anchorEl.needBirthForm)
  }

  const anchorSet = (value, keyName) => {
      setAnchorEl(oldAnchorEl => {
          oldAnchorEl[`${keyName}`] = value
          return oldAnchorEl
      })
      setRefreshPage(!refreshPage)
  };

  const handleInfo = (event, setAnchor) => {
      setAnchor(event.currentTarget);
  };

  useEffect(() => {
    if (userID) {
      firebase.firestore().doc(`/users/${userID}`).onSnapshot(snapshot => {
          const data = snapshot.data()
          if (data) {
              setUser(data)
              setIsPublicProfile(data.isPublic)
              setIsGratuityPeriod(data.gratuityPeriod)
          }
          setLoading(false)
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID])

  return (
      <ProfileContext.Provider value={{ 
        user,
        setErrorMessage,
        setSeverity,
        userID,
        isPublicProfile,
        isGratuityPeriod,
        loading,
        setUserID,
        errorMessage,
        severity,
        open,
        anchorSet,
        handleInfo,
        anchorEl,
        setUser
       }}>
          {props.children}
      </ProfileContext.Provider>
  );
}

function useProfileContext() {
    const profileContext = React.useContext(ProfileContext);
    if (!profileContext) {
        throw new Error('useProfileContext must be used within a ProfileProvider');
    }
    return profileContext;
}

export { ProfileProvider, useProfileContext };