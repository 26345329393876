import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

export default function MyDropzone(props) {
  const { loadFile } = props

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const binaryStr = reader.result
        const isImage = file.type.startsWith('image/')

        loadFile({
          buffer: binaryStr,
          name: file.name,
          isImage: isImage
        })
      }
      reader.readAsArrayBuffer(file)
    })

  }, [loadFile])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <p style={{fontSize: 14, cursor: 'pointer', width: 'fit-content'}}>To upload files, click here!</p>
    </div>
  )
}