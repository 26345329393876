import React, { useState } from 'react'
import 'firebase/functions'
import { useBookingContext } from '../BookingContext';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUserOutlined';
import CardElementStripe from '../../../Payment/CardElementStripe';

const PaymentInformation = () => {
  const { contactInfoData, paymentInfoData, setPaymentInfoData } = useBookingContext()
  const [stripeInfoId, setStripeInfoId] = useState(null);

  return (
    <div className='item form_information'>
      <div className='margin_item'>
        <p className='title'>PAYMENT INFORMATION</p>
        <p className='info_payment'><i>(if you already have an account, please login so you can use your saved card)</i></p>
        
        <CardElementStripe
          currentCardNumber={paymentInfoData?.stripeDefaultPMHint}
          currentCardExpiryDate={paymentInfoData?.stripeDefaultPMExpiryDate}
          fromConsultantBooking={true}
          email={contactInfoData?.email}
          setPaymentInfoData={setPaymentInfoData}
          stripeInfoId={stripeInfoId}
          setStripeInfoId={setStripeInfoId}
        />

        <div className='secure_text'>
          <VerifiedUserIcon />
          Your payment is secure. We use Stripe, a certified PCI Service Provider Level 1.
        </div>
      </div>
    </div>
  )
}

export default PaymentInformation;
