import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import { displayAsCurrency } from '../../../../utils/utils'
import HistoryPopup from '../HistoryPopup/HistoryPopup'
import SetPercentagePopup from '../SetPercentagePopup/SetPercentagePopup'
import { useMediaQuery } from '@material-ui/core';
import { AUTH_ROUTES_CONSULTANT } from '../../../../utils/routes';
import useCookieTheme from '../../../App/useCookieTheme';

const TeamMemberRow = (props) => {
    const consultantID = props.data.consultantID
    const name = props.data.name
    const email = props.data.email
    const amount = props.data.walletBalance
    const teamMemberAmount = props.data.teamMemberWalletBalance
    const locale = props.data.locale
    const timeSpent = props.data.timeSpentInCall
    const status = props.data.status
    const profileLink = props.data.profileLink
    const { changePage } = useCookieTheme()

    const [memberCut, setMemberCut] = useState(props.data.memberCut)
    
    const widthLessThan650px = useMediaQuery('(max-width:649px)');
    const widthLessThan450px = useMediaQuery('(max-width:449px)');

    const removeTapped = () => {
        props.removeTapped(props.data)
    }

    const editConsultant = (consultantID) => {
        changePage({
          pathname: AUTH_ROUTES_CONSULTANT.PROFILE.replace(':id', consultantID)
        })
    }

    const handlePercentUpdate = (percent) => {
        setMemberCut(percent / 100)
    }

    const themeMainColor = useTheme().palette.primary.main
    const useStyles = makeStyles({
        actionsContainer: {
            display: 'flex'
        },
        deleteButton: {
            height: widthLessThan450px ? 17 : widthLessThan650px ? 20 : 25,
            fontSize: widthLessThan450px ? 7 : 11,
            textTransform: 'none',
            color: "#FFFFFF",
            "&:hover": {
                backgroundColor: "#A45A52"
            }
        },
        editButton: {
            height: widthLessThan450px ? 17 : widthLessThan650px ? 20 : 25,
            fontSize: widthLessThan450px ? 7 : 11,
            textTransform: 'none',
            marginRight: 10,
            color: "#FFFFFF",
            "&:hover": {
                backgroundColor: "darkblue"
            }
        },
        totalRevenue: {
          color: themeMainColor
        },
        profileLink: {
            fontSize: 11
        },
        activeStatus: {
            color: themeMainColor,
            fontWeight: 'bold'
        },
        pendingStatus: {
            color: "red",
            fontWeight: 'bold'
        }
    });

    const styleClasses = useStyles();

    return (
        <TableRow key={consultantID}>
            <TableCell component="th" scope="row" className='heading'>{name}</TableCell>
            <TableCell component="th" scope="row">{email}</TableCell>
            <TableCell align="left" className={styleClasses.totalRevenue}>{displayAsCurrency(amount, locale)}</TableCell>
            <TableCell align="left">{memberCut ? `${Math.round(memberCut * 100)}%` : 'N/A'}</TableCell>
            <TableCell align="left">{displayAsCurrency(teamMemberAmount, locale)}</TableCell>
            <TableCell align="left">{timeSpent} minutes</TableCell>
            <TableCell align="left" className={styleClasses.profileLink}>{profileLink}</TableCell>
            <TableCell align="left">{status === 'active' ?
                <span className={styleClasses.activeStatus}>Active</span> :
                <span className={styleClasses.pendingStatus}>Pending</span>}
            </TableCell>
            <TableCell align="left">
                <div className={styleClasses.actionsContainer}>
                    {props.historyEnabled && email &&
                        <HistoryPopup memberData={props.data}></HistoryPopup>
                    }
                    {props.employeeCutEnabled && email &&
                        <SetPercentagePopup data={props.data} percentageUpdated={handlePercentUpdate}></SetPercentagePopup>
                    }
                    {status === 'active' && email &&
                        <Button variant="contained" onClick={() => editConsultant(consultantID)} className={`${styleClasses.editButton} editButton`}>Edit</Button>
                    }
                    {props.removeEnabled &&
                        <Button variant="contained" onClick={removeTapped} className={`${styleClasses.deleteButton} deleteButton`}>Remove</Button>
                    }
                </div>
            </TableCell>
        </TableRow>
    )
}

export default TeamMemberRow