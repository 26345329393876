import React from 'react'
import 'firebase/functions'
import { useBookingContext } from '../BookingContext';
import BookingStepper from './BookingStepper';
import { typeOptions } from '..';
import { formattedIntegerLocaleSeparator, localeToCurrency } from '../../../../utils/utils';
import moment from 'moment';
import momentTimezone from 'moment-timezone'
import { config } from '../../../../config/config';
import { capitalize } from '@material-ui/core';
import useCookieTheme from '../../../App/useCookieTheme';

const YoureAlmostDone = () => {
  const { bookingData, couponData, isTenPercentDiscount } = useBookingContext()
  const { appointmentName, consultantTitle } = useCookieTheme()
  
  const typeOption = typeOptions(false, true).find(elem => elem.id === bookingData.data.callType);

  return (
    <div className='item'>
      <BookingStepper />
      <div className='margin_item'>
        <p className='big_title'>YOU'RE ALMOST DONE!</p>
        <div className='astrologer_text heading'>
          Your {appointmentName ?? 'appointment'} request has been sent to your {consultantTitle} and you should receive a confirmation within 24 hours, at which point your card will be charged and your {appointmentName ?? 'appointment'} will be confirmed.
          {config.type === 'astrologer' && 
            <>
              <br /><br />
              Please look out for emails from ac@astrologyhub.com for updates about your reading request and information to prepare for your reading.
            </>
          }
        </div>
        <div className='border_left almost_done_review'>
          <span className='review_call_type heading'>{typeOption.label} {config.type === 'astrologer' && capitalize(appointmentName)}</span><br />
          {bookingData.data.length} minutes
          {!couponData && <span> - <span className={`${isTenPercentDiscount ? 'cut_text' : ''}`}>{localeToCurrency(bookingData.data.locale)}</span><span className={`${isTenPercentDiscount ? 'cut_text' : ''}`}>{formattedIntegerLocaleSeparator(isTenPercentDiscount ? bookingData.data.oldPrice : bookingData.data.estimatedPrice, bookingData.data.locale)}</span></span>}
          {isTenPercentDiscount && <b><br />Yout Price Today: {localeToCurrency(bookingData.data.locale)}{formattedIntegerLocaleSeparator(bookingData.data.estimatedPrice, bookingData.data.locale)}<br /></b>}
          <br /><br />
          {moment(bookingData.data.startDate).format('MMMM D, YYYY @h:mma')} {momentTimezone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr()}
          {config.type === 'astrologer' && !couponData &&
            <>
              <br /><br />
              {localeToCurrency(bookingData.data.locale)}{formattedIntegerLocaleSeparator(Math.round(bookingData.data.estimatedPrice * 1.04), bookingData.data.locale)} TOTAL<br />
              <i>includes 4% Tax</i>
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default YoureAlmostDone;
