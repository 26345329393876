import React, { useEffect, useState } from 'react'
import "../profile.css";
import { Button, Checkbox, Divider, FormControlLabel, TextField, useMediaQuery } from '@material-ui/core'
import firebase from 'firebase/app'
import { Calendar } from "react-multi-date-picker"
import moment from 'moment'
import AvailabilityDialog from './availabilityDialog'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { capitalizeFirstLetter } from '../../../utils/utils';
import "react-multi-date-picker/styles/colors/red.css"
import SpecificAvailabilityDates from './SpecificAvailabilityDates';
import InfoPopover from '../../components/InfoPopover';
import { useProfileContext } from '../profileContext';
import { setErrorDetails } from '../../../api/userSlice';
import { useDispatch } from 'react-redux';

const Availability = () => {
    const { user, setSeverity } = useProfileContext()
    const dispatch = useDispatch()
    const daysOfTheWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
    
    const [daysCheckedStates, setDaysCheckedStates] = useState(daysOfTheWeek.map(() => false))
    const [sliderRanges, setSliderRanges] = useState(daysOfTheWeek.map(() => []))
    const [isRenderSliders, setIsRenderSliders] = useState(true)
    const [missingDateValues, setMissingDateValues] = useState([])
    const [isCalendarChanged, setIsCalendarChanged] = useState(false)
    const [availabilityDialog, setAvailabilityDialog] = useState(false)
    const [excludedDays, setExcludedDays] = useState(null)
    const [specificDates, setSpecificDates] = useState(null)
    const [selectedTimezone, setSelectedTimezone] = useState({
        value: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
    const [enableBufferScheduledAppointment, setEnableBufferScheduledAppointment] = useState(Boolean(user.bufferScheduleAppointment))
    const [bufferValue, setBufferValue] = useState(user.bufferScheduleAppointment > 0 ? user.bufferScheduleAppointment : 24)
    const [enableBufferBetweenAppointments, setEnableBufferBetweenAppointments] = useState(Boolean(user.bufferBetweenAppointments))
    const [secondBufferValue, setSecondBufferValue] = useState(user.bufferBetweenAppointments > 0 ? user.bufferBetweenAppointments : 15)
    const [anchorEl, setAnchorEl] = useState({
        availabilitySchedule: null,
        bufferScheduledAppointment: null,
        bufferBetweenAppointments: null,
        unavailableDates: null
    });
    const [refreshPage, setRefreshPage] = useState(false);
    const open = {
        availabilitySchedule: Boolean(anchorEl.availabilitySchedule),
        bufferScheduledAppointment: Boolean(anchorEl.bufferScheduledAppointment),
        bufferBetweenAppointments: Boolean(anchorEl.bufferBetweenAppointments),
        unavailableDates: Boolean(anchorEl.unavailableDates)
    }

    const widthLessThan770px = useMediaQuery('(max-width:769px)');

    const handleInfo = (event, setAnchor) => {
        setAnchor(event.currentTarget);
    };

    const setIsPublicProfileValue = (value, keyName) => {
        setAnchorEl(oldAnchorEl => {
            oldAnchorEl[`${keyName}`] = value
            return oldAnchorEl
        })
        setRefreshPage(!refreshPage)
    };
    
    const setSchedules = (userID) => {
        firebase.firestore().doc(`/schedules_multiple/${userID}`).onSnapshot(snapshot  => {
            setIsRenderSliders(true)
            const data = snapshot.data()

            const checkedCopy = daysOfTheWeek.map(() => false)
            const sliderRangesCopy = daysOfTheWeek.map(() => [])

            if (!data) {
                daysOfTheWeek.forEach((_, index) => {
                    sliderRangesCopy[index].push([480, 1200])
                })
                setSliderRanges(sliderRangesCopy)
                setIsRenderSliders(false)
                return
            }

            const intervals = data["intervals"]
            daysOfTheWeek.forEach((_, index) => {
                const dataForDay = intervals?.filter(interval => interval.offset >= index * 1440 && interval.offset < (index + 1) * 1440)
                if (!dataForDay?.length) {
                  sliderRangesCopy[index].push([480, 1200])
                  return
                }
                dataForDay.forEach((data) => {
                  sliderRangesCopy[index].push([data.offset - 1440 * index, data.offset + data.duration - 1440 * index])
                  checkedCopy[index] = true
                })
            })

            setDaysCheckedStates(checkedCopy)
            setSliderRanges(sliderRangesCopy)

            const excludedDaysRes = data["excludedDays"]
            if (excludedDaysRes) {
              const missingDates = excludedDaysRes.map(each => {
                return moment().set('year', each.year).set('month', each.month).set('date', each.day).startOf('day').toDate()
              })
              
              setMissingDateValues(missingDates)
              setExcludedDays(missingDates)
            } else {
              setExcludedDays(null)
            }

            const specificDatesRes = data["specificDates"]
            if (specificDatesRes) {
              const spedicifDatesReal = specificDatesRes.map(each => {
                return {
                  ...each,
                  date: moment().set('year', each.year).set('month', each.month).set('date', each.day).startOf('day').toDate()
                }
              }).filter(each => each.date.getTime() >= moment().startOf('day').toDate().getTime())
              setSpecificDates(spedicifDatesReal)
            }

            setIsRenderSliders(false)
            setSelectedTimezone(oldTimeZone => data.timezone ?? oldTimeZone)
        })
    }

    useEffect(() => {
        setSchedules(user.userID)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const handleSaveCalendar = () => {
      const toSet = {
          excludedDays: missingDateValues.map(each => {
            const eachDate = each.toDate()
            return {
              day: eachDate.getDate(),
              month: eachDate.getMonth(),
              year: eachDate.getFullYear(),
            }
          }),
      }
      firebase.app().functions().httpsCallable("updateUserProfile")({
          updateData: JSON.stringify(toSet),
          targetUserID: user.userID,
          isSchedules: true
      }).then(() => {
          console.log("Success updating!")
      }).catch((error) => {
          dispatch(setErrorDetails({
            message: error.message
          }))
          setSeverity('error')
          setSchedules(user.userID)
      })
      setIsCalendarChanged(false)
    }

    const setBufferScheduleAppointment = (e) => {
      setEnableBufferScheduledAppointment(e.target.checked)
      const value = e.target.checked ? 24 : 0
      setBufferScheduleAppointmentValue(value)
      firebase.app().functions().httpsCallable("updateUserProfile")({
        updateData: { bufferScheduleAppointment: value },
        targetUserID: user.userID,
      })
    }

    const setBufferScheduleAppointmentValue = (value) => {
      setBufferValue(Number(value))
      firebase.app().functions().httpsCallable("updateUserProfile")({
        updateData: { bufferScheduleAppointment: Number(value) },
        targetUserID: user.userID,
      })
    }

    const setBufferBeweenAppointments = (e) => {
      setEnableBufferBetweenAppointments(e.target.checked)
      const value = e.target.checked ? 15 : 0
      setBufferBetweenAppointmentsValue(value)
      firebase.app().functions().httpsCallable("updateUserProfile")({
        updateData: { bufferBetweenAppointments: value },
        targetUserID: user.userID,
      })
    }

    const setBufferBetweenAppointmentsValue = (value) => {
      setSecondBufferValue(Number(value))
      firebase.app().functions().httpsCallable("updateUserProfile")({
        updateData: { bufferBetweenAppointments: Number(value) },
        targetUserID: user.userID,
      })
    }

    const DisplayTimes = props => {
      const { index } = props

      return (
        <div className='times'>
          {sliderRanges[index].map((range, indexRange) => (
            <p key={indexRange}>
              <span className={`${styleClasses.time} ${!daysCheckedStates[index] ? styleClasses.timeDisable : ''}`}>
                {moment().startOf('day').add(range[0], 'minutes').format('HH:mm')}
              </span>
              <span className={`${styleClasses.line} ${!daysCheckedStates[index] ? styleClasses.lineDisable : ''}`}>-</span>
              <span className={`${styleClasses.time} ${!daysCheckedStates[index] ? styleClasses.timeDisable : ''}`}>
                {moment().startOf('day').add(range[1], 'minutes').format('HH:mm')}
              </span>
            </p>
          ))}
        </div>
      )
    }

    const themeMainColor = useTheme().palette.primary.main
  
    const useStyles = makeStyles({
        time: {
            backgroundColor: themeMainColor,
            color: 'white',
            padding: '3px 7px',
            margin: '0 5px',
            fontSize: '12px'
        },
        timeDisable: {
          backgroundColor: 'lightGray',
          color: 'gray'
        },
        line: {
          color: themeMainColor
        },
        lineDisable: {
          color: 'gray'
        },
        square: {
          minWidth: 15,
          minHeight: 15,
          backgroundColor: themeMainColor,
          borderRadius: 5,
        }
    })
    const styleClasses = useStyles();

    return (
      <div className='availability_container'>
        <div className='right_content_child'>
          <div className='header_container heading'>
            <p className='title_info_container'>
              Availability Schedule
              <InfoPopover
                  open={open.availabilitySchedule}
                  anchorEl={anchorEl.availabilitySchedule}
                  setAnchorEl={(value) => setIsPublicProfileValue(value, 'availabilitySchedule')}
                  handleInfo={(e) => handleInfo(e, (value) => setIsPublicProfileValue(value, 'availabilitySchedule'))}
                  buttonClass='availability_info_button'
              >
                  <div className='timezone_info_content'>
                    A recurring schedule is set for each day of the week from Monday to Sunday.
                  </div>
              </InfoPopover>
            </p>
            <Button color="primary" variant="contained" onClick={() => setAvailabilityDialog(true)}>
                Edit
            </Button>
          </div>
          <div className='timezone_display'>
            Timezone: <span className='heading'>{selectedTimezone.value ?? selectedTimezone}</span>
          </div>
          <div className='availability_schedule_container'>
            {daysOfTheWeek.map((day, index) => (
              <div key={index}>
                <div className='available_schedule_item'>
                  <div className='available_schedule_label'>
                    <div className={`${styleClasses.square} ${!daysCheckedStates[index] ? 'square_disable' : ''}`} />
                    <p>{capitalizeFirstLetter(day)}</p>
                  </div>
                  {!isRenderSliders && <DisplayTimes index={index} />}
                </div>
                {index !== daysOfTheWeek.length -1 && <Divider />}
              </div>
            ))}
          </div>
          <Divider variant='fullWidth' className='buffers_divider' />
          <div className='buffer_container'>
            <div className='title_info_container'>
              <FormControlLabel
                  className='buffer_checkbox'
                  control={
                      <Checkbox
                          checked={enableBufferScheduledAppointment}
                          onChange={setBufferScheduleAppointment}
                          color="primary"
                      />
                  }
                  label='Buffer before first available slot'
              />
              <InfoPopover
                  open={open.bufferScheduledAppointment}
                  anchorEl={anchorEl.bufferScheduledAppointment}
                  setAnchorEl={(value) => setIsPublicProfileValue(value, 'bufferScheduledAppointment')}
                  handleInfo={(e) => handleInfo(e, (value) => setIsPublicProfileValue(value, 'bufferScheduledAppointment'))}
                  buttonClass='availability_info_button'
              >
                  <div className='timezone_info_content'>
                    This feature prevents clients from making bookings on very short notice. By setting a value for it, you can determine the minimum amount of time you want the client to see the first available slot from the moment they attempt to book.
                  </div>
              </InfoPopover>
            </div>
            {enableBufferScheduledAppointment &&
              <div className='hours_buffer'>
                <TextField
                  style={{backgroundColor: 'white', borderRadius: 10}}
                  variant='outlined'
                  value={bufferValue}
                  onChange={(e) => setBufferScheduleAppointmentValue(e.target.value)}
                  type='number'
                  InputProps={{
                      inputProps: { 
                          min: 0 
                      }
                  }}
                />
                hours
              </div>
            }
          </div>
          <div className='buffer_container'>
            <div className='title_info_container'>
              <FormControlLabel
                  className='buffer_checkbox'
                  control={
                      <Checkbox
                          checked={enableBufferBetweenAppointments}
                          onChange={setBufferBeweenAppointments}
                          color="primary"
                      />
                  }
                  label='Buffer for in-between appointments'
              />
              <InfoPopover
                  open={open.bufferBetweenAppointments}
                  anchorEl={anchorEl.bufferBetweenAppointments}
                  setAnchorEl={(value) => setIsPublicProfileValue(value, 'bufferBetweenAppointments')}
                  handleInfo={(e) => handleInfo(e, (value) => setIsPublicProfileValue(value, 'bufferBetweenAppointments'))}
                  buttonClass='availability_info_button'
              >
                  <div className='timezone_info_content'>
                    This value represents the minimum amount of time you want to have between two consecutive appointments.
                  </div>
              </InfoPopover>
            </div>
            {enableBufferBetweenAppointments &&
              <div className='hours_buffer'>
                <TextField
                  style={{backgroundColor: 'white', borderRadius: 10}}
                  variant='outlined'
                  value={secondBufferValue}
                  onChange={(e) => setBufferBetweenAppointmentsValue(e.target.value)}
                  type='number'
                  InputProps={{
                      inputProps: { 
                          min: 0 
                      }
                  }}
                />
                minutes
              </div>
            }
          </div>
        </div>
        <Divider orientation={widthLessThan770px ? 'horizontal' : 'vertical'} variant='fullWidth' style={{ width: widthLessThan770px ? '100%' : '1px' }} />
        <div className='right_content_child'>
          <div className='header_container heading'>
              <p className='title_info_container'>
                Unavailable Dates
                <InfoPopover
                    open={open.unavailableDates}
                    anchorEl={anchorEl.unavailableDates}
                    setAnchorEl={(value) => setIsPublicProfileValue(value, 'unavailableDates')}
                    handleInfo={(e) => handleInfo(e, (value) => setIsPublicProfileValue(value, 'unavailableDates'))}
                    buttonClass='availability_info_button'
                >
                    <div className='timezone_info_content'>
                      This feature allows you to select specific days that you don't want to be available. It overrides the recurring scheduling for those selected days, which is useful for managing vacations or sick days.
                    </div>
                </InfoPopover>
              </p>
              <Button disabled={!isCalendarChanged} color="primary" variant="contained" onClick={handleSaveCalendar}>
                  Save
              </Button>
          </div>
          <div className='unavailable_dates_calendar'>
              <Calendar
                  value={missingDateValues}
                  className="red"
                  minDate={new Date()}
                  onChange={e => {
                      setIsCalendarChanged(true)
                      setMissingDateValues(e)
                  }}
              />
          </div>
          <Divider className='horizontal_divider_availability' />
          <SpecificAvailabilityDates
            user={user}
            excludedDays={excludedDays}
            specificDates={specificDates}
            styleClasses={styleClasses}
          />
        </div>
        
        {availabilityDialog &&
          <AvailabilityDialog
            user={user}
            handleClose={() => setAvailabilityDialog(false)}
            open={availabilityDialog}
            daysOfTheWeek={daysOfTheWeek}
            daysCheckedStates={daysCheckedStates}
            sliderRanges={sliderRanges}
            setDaysCheckedStates={setDaysCheckedStates}
            setSliderRanges={setSliderRanges}
            selectedTimezone={selectedTimezone}
            setSelectedTimezone={setSelectedTimezone}
          />}
      </div>
    )
}

export default Availability;
