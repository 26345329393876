import React from 'react'
import 'firebase/functions'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import SignInDialog from './SignInDialog'
import { useState } from 'react'

const SaveAdditionalInformation = (props) => {
  const [saveInfos, setSaveInfos] = useState(false)

  return (
    <div className='item save_additional_info_item'>
      <div className='margin_item'>
        <FormControlLabel
            className='save_additional_info'
            control={
                <Checkbox
                    checked={saveInfos}
                    onChange={() => setSaveInfos(oldSaveInfos => !oldSaveInfos)}
                    color="primary"
                />
            }
            label='SAVE MY INFORMATION FOR A FASTER CHECKOUT, READING HISTORY AND ACCESS TO READING RECORDINGS'
        />
      </div>

      {saveInfos &&
        <SignInDialog
          openDialog={saveInfos}
          setOpenDialog={setSaveInfos}
        />
      }
    </div>
  )
}

export default SaveAdditionalInformation;
