import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const TeamDiscountRow = (props) => {
    const { data } = props

    return (
      <TableRow key={data.id}>
          <TableCell align='right' className='heading' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', borderRight: '1px solid lightgray' }}>{data.name}</TableCell>
          <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal' }}>{data.countThisWeek}</TableCell>
          <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: 'lightgray' }}>{data.countDiscountThisWeek}</TableCell>
          <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: '#f2f2f2' }}>
            {(data.countThisWeek === 0 || data.countDiscountThisWeek === 0 ? 0 : (data.countDiscountThisWeek * 100 / data.countThisWeek)).toFixed(2)}%
          </TableCell>
          <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal' }}>{data.countNextWeek}</TableCell>
          <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: 'lightgray' }}>{data.countDiscountNextWeek}</TableCell>
          <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: '#f2f2f2' }}>
            {(data.countNextWeek === 0 || data.countDiscountNextWeek === 0 ? 0 : (data.countDiscountNextWeek * 100 / data.countNextWeek)).toFixed(2)}%
          </TableCell>
          <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal' }}>{data.countThisMonth}</TableCell>
          <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: 'lightgray' }}>{data.countDiscountThisMonth}</TableCell>
          <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: '#f2f2f2' }}>
            {(data.countThisMonth === 0 || data.countDiscountThisMonth === 0 ? 0 : (data.countDiscountThisMonth * 100 / data.countThisMonth)).toFixed(2)}%
          </TableCell>
          <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal' }}>{data.countNextMonth}</TableCell>
          <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: 'lightgray' }}>{data.countDiscountNextMonth}</TableCell>
          <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: '#f2f2f2' }}>
            {(data.countNextMonth === 0 || data.countDiscountNextMonth === 0 ? 0 : (data.countDiscountNextMonth * 100 / data.countNextMonth)).toFixed(2)}%
          </TableCell>
          <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal' }}>{data.countYear}</TableCell>
          <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: 'lightgray' }}>{data.countDiscountYear}</TableCell>
          <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: '#f2f2f2' }}>
            {(data.countYear === 0 || data.countDiscountYear === 0 ? 0 : (data.countDiscountYear * 100 / data.countYear)).toFixed(2)}%
          </TableCell>
      </TableRow>
    )
}

export default TeamDiscountRow