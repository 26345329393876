import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import 'firebase/firestore'
import { MessageList, Input, Button } from 'react-chat-elements'
import 'react-chat-elements/dist/main.css';
import MyDropzone from './MyDropzone'
import { Divider, LinearProgress } from '@material-ui/core'
import firebase from 'firebase';
import moment from 'moment'
import { config } from '../../../config/config';
import { dataCalls } from '../../Home/components/bookingCard/BookingCard';

const ActiveChat = (props) => {
  const { sessionData, userID, isConsultant, sessionID, fromActiveCall, otherHandle } = props
  const { clientID, consultantID, clientEmail } = sessionData

  const [addedTogether, setAddedTogether] = useState(null)
  const [realUserID, setRealUserID] = useState(null)
  const [otherTyping, setOtherTyping] = useState(false)
  const isSetTyping = useRef(false)
  const ref = sessionData.bookingID ? firebase.app().firestore().doc(`/bookings/${sessionData.bookingID}`) : firebase.app().firestore().doc(`/sessions/${sessionID}`)

  let are3Days = true
  if (sessionData.bookingID) {
    const a = moment(sessionData.startDate.toDate()).add(sessionData.length, 'minutes').add(3, 'days').toDate().getTime()
    const b = new Date().getTime()
    are3Days = a > b
  }

  const [dataSource, setDataSource] = useState([])
  const [uploadingFile, setUploadingFile] = useState(false)

  const inputRef = createRef();
  const curTextRef = useRef("")

  useEffect(() => {
    const asyncGetChat = async () => {
      let clientSide = clientID ?? clientEmail
      if (clientID) {
        const client = (await firebase.app().firestore().doc(`/users/${clientID}`).get()).data()
        if (client?.wasGuest) {
          clientSide = clientEmail
        }
      }
      const addedTogetherValue = `${consultantID}-${clientSide}${sessionData.bookingID ? `-${sessionData.bookingID}` : ''}`
      setAddedTogether(addedTogetherValue)
      
      const realUserIDValue = isConsultant ? userID : clientSide
      setRealUserID(realUserIDValue)
      
      const date = moment().subtract(1, 'months').toDate()
      firebase.app().firestore().doc(`/chats/${addedTogetherValue}`).collection('messages').orderBy('date', 'asc').where('date', '>=', date).onSnapshot(res => {
        const docs = res.docs.map(each => each.data())
  
        const newDataSource = docs.map(each => {
          if (each.type === "TEXT") {
            return {
              position: each.senderId === realUserIDValue ? "right" : "left",
              type: 'text',
              text: each.message,
              date: each.date.toDate()
            }
          } else if (each.type === "IMAGE") {
            return {
              position: each.senderId === realUserIDValue ? "right" : "left",
              type: 'photo',
              data: { uri: each.fileData.url, width: 100, height: 100, status: { download: false } },
              width: 150,
              height: 150,
              date: each.date.toDate()
            }
          } else if (each.type === "DOCUMENT") {
            console.log("Got url:")
            console.log(each.fileData.url)
            return {
              position: each.senderId === realUserIDValue ? "right" : "left",
              type: 'file',
              data: { uri: each.fileData.url, status: { click: false, download: false } },
              date: each.date.toDate()
            }
          } else {
            return {
              position: each.senderId === realUserIDValue ? "right" : "left",
              type: 'text',
              text: 'Unknown message type',
              date: each.date.toDate()
            }
          }
        })
  
        setDataSource(newDataSource)
      })
    }

    asyncGetChat()
  }, [clientEmail, clientID, consultantID, isConsultant, sessionData.bookingID, userID])

  useEffect(() => {
    firebase.app().firestore().doc(`/sessions/${sessionID}`).onSnapshot((res) => {
      const otherTypingValue = isConsultant ? res.data()?.clientTyping : res.data()?.consultantTyping
      setOtherTyping(otherTypingValue)
    })
  }, [isConsultant, sessionID])

  const setTyping = async (value) => {
    isSetTyping.current = value
    await firebase.app().firestore().doc(`/sessions/${sessionID}`).update(isConsultant ? {
      consultantTyping: value
    } : {
      clientTyping: value
    })
  }

  const onFocus = async () =>{
    if (!isSetTyping.current) {
      await setTyping(true)
    }
  }

  const onBlur = async () =>{
    if (isSetTyping.current) {
      await setTyping(false)
    }
  }

  const checkTyping = async (value) => {
    if (value.length && !isSetTyping.current) {
      await setTyping(true)
    } else if (value.length === 0 && isSetTyping.current) {
      await setTyping(false)
    }
  }

  const handleSend = async () => {
    const curText = curTextRef.current
    inputRef.current.clear()

    if (curText.length > 0) {
      const msgCollection = firebase.app().firestore().doc(`/chats/${addedTogether}`).collection('messages')
      const newDocID = msgCollection.doc()
      const newMessage = {
        date: new Date(),
        deleted: false,
        id: newDocID.id,
        message: curText,
        senderId: realUserID,
        receiverId: realUserID === consultantID ? clientID ?? clientEmail : consultantID,
        type: "TEXT",
        sendEmail: Boolean(sessionData.bookingID) && !fromActiveCall
      }
      await newDocID.set(newMessage)

      await ref.update(isConsultant ? {
        clientHasNewMessage: true
      } : {
        consultantHasNewMessage: true
      })
    }

    onBlur()
  }

  const loadFile = useCallback(async (data) => {
    const { buffer, name, isImage } = data

    setUploadingFile(true)

    const msgCollection = firebase.app().firestore().doc(`/chats/${addedTogether}`).collection('messages')
    const newDocID = msgCollection.doc()
    const refPath = `/chat_files/${addedTogether}/${newDocID}${name}`
    const storageFolder = await firebase.app().storage().ref(refPath).put(buffer)
    const downloadURL = await storageFolder.ref.getDownloadURL()
    const newMessage = {
      date: new Date(),
      deleted: false,
      id: newDocID.id,
      senderId: realUserID,
      receiverId: realUserID === consultantID ? clientID ?? clientEmail : consultantID,
      type: isImage ? "IMAGE" : "DOCUMENT",
      fileData: {
        id: newDocID.id,
        name: name,
        referencePath: refPath,
        url: downloadURL
      },
      sendEmail: Boolean(sessionData.bookingID) && !fromActiveCall
    }

    newDocID.set(newMessage)
    setUploadingFile(false)
  }, [addedTogether, realUserID, consultantID, clientID, clientEmail, sessionData.bookingID, fromActiveCall])

  const startMoment = moment(sessionData.startDate?.toDate())
  const endMoment = moment(startMoment).set('seconds', 0).add(sessionData.length, 'minutes')
  const prettyStartDate = startMoment.format('MMMM Do YYYY')
  const prettyStartTime = startMoment.format('hh:mm')
  const endStart = endMoment.format('hh:mm')
  const ampm = startMoment.format('A')

  return (
    <div className={`chat_container ${sessionData.isChatCall ? 'chat_container_width_full chat_container_height_full' : ''}`}>
      {(sessionData.bookingID || !sessionData.isChatCall) &&
        <>
          <div className='chat_title'>
            CHAT<br />
            {sessionData.bookingID &&
              <div>
                {dataCalls[sessionData.callType].name}<br />
                {prettyStartDate}, {prettyStartTime} - {endStart} {ampm} ({Intl.DateTimeFormat().resolvedOptions().timeZone})
              </div>
            }
          </div>
        </>
      }
      <Divider />
      <MessageList
        className={`my-message-list ${(sessionData.bookingID || !sessionData.isChatCall) ? 'my-message-list-small-chat' : ''} ${config.type === 'astrologer' && sessionData.bookingID && !isConsultant ? 'my-message-list-small-chat-client-astrologer' : ''}`}
        lockable={true}
        toBottomHeight={'100%'}
        onDownload={(e) => {
          console.log("Downloadin")
          const uri = e.data.uri
          window.open(uri, "_blank")
        }}
        dataSource={dataSource} />
      {are3Days &&
          <>
            <Input
              placeholder="Type here..."
              multiline={true}
              onChange={(newValue) => {
                curTextRef.current = newValue.target.value
                checkTyping(newValue.target.value)
              }}
              onFocus={(event) => {
                if (event.target.value.length) {
                  onFocus()
                }
              }}
              onBlur={onBlur}
              onKeyPress={event => {
                if(event.key === 'Enter'){
                  event.preventDefault()
                  handleSend()
                }
              }}
              ref={inputRef}
              rightButtons={
                <Button
                  color='white'
                  onClick={() => {
                    handleSend()
                  }}
                  backgroundColor='black'
                  className='send_chat_button'
                  text='Send' />
              }
            />
            <p className='other_typing'>{otherTyping && `${otherHandle} is typing...`}</p>
            {uploadingFile && <LinearProgress style={{ marginHorizontal: 10 }} />}
            <MyDropzone loadFile={loadFile} />
          </>
      }
      {config.type === 'astrologer' && sessionData.bookingID && !isConsultant && 
        <p>
          Astrologers will get back within 24 hours but if you need more urgent response, send an email to <a href="mailto:support@astrologyhub.com" rel="noopener noreferrer" target="_blank">support@astrologyhub.com</a>.
        </p>
      }
    </div>
  )
};

export default ActiveChat;
