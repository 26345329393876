import { useMemo } from 'react';
import { defaultTheme } from '../../config/config';
import { createMuiTheme } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import { getURLs } from '../../utils/theme';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userState } from '../../api/userSlice';
import { themeState } from '../../api/themeSlice';

export default function useCookieTheme() {
  const history = useHistory()
  const integration = useSelector(userState).integration
  const testIntegration = useSelector(userState).testIntegration
  const theme = useSelector(themeState).theme

  const changePage = (data) => {
    const searchParams = new URLSearchParams()
    if (theme && (theme?.id !== defaultTheme.id)) {
      searchParams.set('theme', theme.id.split('Theme')[0])
    }
    if (Boolean(integration)) {
      searchParams.set('integration', integration)
    }
    if (testIntegration) {
      searchParams.set('testIntegration', true)
    }
    history.push({
      ...data,
      search: data.search ? `${data.search}&${searchParams.toString()}` : searchParams.toString()
    })
  }

  const themeColor = theme?.color
  const color = themeColor ?? defaultTheme.mainColor
  const backgroundColor = theme?.backgroundColor ?? defaultTheme.backgroundColor
  const themeMui = useMemo(() => {
    return createMuiTheme({
      palette: {
        primary: {
          main: color,
          contrastText: '#FFFFFF'
        },
        secondary: {
          main: blue[50],
        },
        background: {
          default: backgroundColor
        }
      },
    })
  }, [color, backgroundColor]);

  const name = theme?.name ?? defaultTheme.appName
  const logo = theme?.logo ?? defaultTheme.logo
  const consultantTitle = theme?.consultantTitle ?? defaultTheme.consultantTitle
  const employeeTitle = theme?.employeeTitle ?? defaultTheme.employeeTitle
  const docsLocation = theme?.docsLocation ?? defaultTheme.docsLocation
  const menuDashboard = theme?.menu?.dashboard ?? defaultTheme.menu?.dashboard
  const menuHistory = theme?.menu?.history ?? defaultTheme.menu?.history
  const menuProfile = theme?.menu?.profile ?? defaultTheme.menu?.profile
  const menuConsultantsList = theme?.menu?.consultantsList ?? defaultTheme.menu?.consultantsList
  const iosAppStore = theme?.iosAppStore ?? defaultTheme.iosAppStore
  const androidPackage = theme?.androidPackage ?? defaultTheme.androidPackage
  const autoNotifyTeamMaster = theme?.autoNotifyTeamMaster ?? defaultTheme.autoNotifyTeamMaster
  const universalLink = theme?.universalLink ?? defaultTheme.universalLink
  const appointmentName = theme?.appointmentName ?? defaultTheme.appointmentName
  const makeAppointmentButtonName = theme?.makeAppointmentButtonName ?? defaultTheme.makeAppointmentButtonName
  const giftButtonName = theme?.giftButtonName ?? defaultTheme.giftButtonName
  const bookingLogo = theme?.bookingLogo ?? defaultTheme.bookingLogo
  const availabilityHeading = theme?.availabilityHeading ?? defaultTheme.availabilityHeading
  const bookingEnableName = theme?.bookingEnableName ?? defaultTheme.bookingEnableName

  const urls = getURLs(iosAppStore, androidPackage)
  let appleURL = urls.appleURL
  let googleURL = urls.googleURL

  return {
    changePage,
    themeMui,
    name,
    logo,
    consultantTitle,
    employeeTitle,
    docsLocation,
    menuDashboard,
    menuHistory,
    menuProfile,
    menuConsultantsList,
    iosAppStore,
    androidPackage,
    autoNotifyTeamMaster,
    appleURL,
    googleURL,
    universalLink,
    appointmentName,
    makeAppointmentButtonName,
    giftButtonName,
    bookingLogo,
    availabilityHeading,
    bookingEnableName
  };
}
