import { Dialog, DialogContent } from '@material-ui/core'
import React from 'react'
import 'firebase/functions'
import SignInComponent from '../../../SignIn/components/SignInComponent';
import { useBookingContext } from '../BookingContext';

const SignInDialog = (props) => {
  const { openDialog, setOpenDialog } = props
  const { contactInfoData, setIsCongrats, setIsAllSet, setIsCreatedAccount, additionalInfoData } = useBookingContext()

  const onSubmit = async () => {
    setOpenDialog(false)
    if (additionalInfoData?.questionsText) {
      setIsAllSet(true)
      setIsCongrats(false)
    }
    setIsCreatedAccount(true)
  }

  return (
    <Dialog maxWidth='lg' open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogContent className='signin_dialog_content'>
        <SignInComponent
          fromConsultantBooking={true}
          onSubmit={onSubmit}
          contactInfoData={contactInfoData}
        />
      </DialogContent>
    </Dialog>
  )
}

export default SignInDialog;
