import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import useCookieTheme from './useCookieTheme'
import { useGlobalStyles } from './globalStyles'
import { useDispatch } from 'react-redux'
import { setErrorDetails } from '../../api/userSlice'

const ReviewDialog = (props) => {
  const { sessionID, setOpen }= props
  const { logo } = useCookieTheme()
  const dispatch = useDispatch()

  const [sessionData, setSessionData] = useState(null)
  const [stars, setStars] = useState(null)
  const [reviewMessage, setReviewMessage] = useState(null)
  const [sending, setSending] = useState(false)

  useEffect(() => {
    const getSessionDataAsync = async () => {
      const sessionDataRes = (await firebase.firestore().doc(`sessions/${sessionID}`).get()).data()
      setSessionData(sessionDataRes)
    }

    getSessionDataAsync()
  }, [sessionID])

  const sendReview = () => {
    setSending(true)
    firebase.app().functions("europe-west1").httpsCallable("submitReview")({
      sessionId: sessionID,
      stars: stars,
      review: reviewMessage
    }).then(() => {
      setOpen(false)
    }).catch(e => {
      dispatch(setErrorDetails({
        message: e.message
      }))
    }).finally(() => {
      setSending(false)
    })
  }

  useGlobalStyles()

  return (
    <Dialog
      open={Boolean(sessionData)}
    >
      <DialogContent>
        <div className='review_dialog_content'>
          <img alt="" src={logo} />
          <p>How would you rate {sessionData?.consultantFullName} for this session?</p>
          <Rating
            value={stars}
            onChange={(_, newValue) => {
              setStars(newValue);
            }}
          />
          {stars &&
            <TextField
              multiline
              variant='outlined'
              label="Share your experience"
              value={reviewMessage}
              onChange={(e) => setReviewMessage(e.target.value)}
              rows={3}
            />
          }
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!stars}
          onClick={sendReview}
        >
          {sending ? <CircularProgress size={10} /> : 'SEND'}
        </Button>
        <Button
          color="primary"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReviewDialog;
