import React, { useState } from 'react';
import { Backdrop } from '@material-ui/core';
import "./payment.css";
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import firebase from 'firebase/app'
import 'firebase/functions'
import Button from '@material-ui/core/Button'
import { usePaymentStyles } from '../App/globalStyles';
import { AUTH_ROUTES_CONSULTANT, COMMON_ROUTES } from '../../utils/routes';
import { useRef } from 'react';
import useCookieTheme from '../App/useCookieTheme';
import { useDispatch, useSelector } from 'react-redux';
import { userState, setErrorDetails, setUserState } from '../../api/userSlice';
import useBookingHook from '../ConsultantProfile/useBookingHook';

const PaymentForm = (props) => {
  const {
    fromConsultantsList,
    fromConsultantBooking,
    fromCallHistory,
    email,
    setPaymentInfoData,
    setEdit,
    edit,
    setCardLoaded,
    setStripeInfoId
  } = props
  const stripe = useStripe()
  const elements = useElements()
  const { getBooking, setBooking } = useBookingHook()
  const { changePage } = useCookieTheme()
  const dispatch = useDispatch()
  const userID = useSelector(userState).user?.userID
  const isSignUpInBookingProcess = useSelector(userState).isSignUpInBookingProcess
  const bookingFlow = getBooking()
  
  const [loading, setLoading] = useState(false)
  const stripeDefaultPMHint = useRef(null)

  const cardAdded = () => {
    if (fromConsultantsList) {
      changePage({
        pathname: COMMON_ROUTES.LIST
      })
    } else if (fromCallHistory) {
      changePage({
        pathname: AUTH_ROUTES_CONSULTANT.HISTORY
      })
    } else if (bookingFlow && bookingFlow !== "null") {
      if (bookingFlow.perMinuteConsultant) {
        changePage({
          pathname: COMMON_ROUTES.CONSULTANT.replace(':consultant', bookingFlow.perMinuteConsultant)
        })
      } else {
        setBooking({
          ...bookingFlow,
          paymentInfoData: {
            stripeDefaultPMHint: stripeDefaultPMHint.current
          }
        })
        changePage({
          pathname: COMMON_ROUTES.CONSULTANT_BOOKING.replace(':consultant', bookingFlow.bookingData?.data?.consultantID)
        })
      }
    }
  }

  const handleSubmit = async () => {
    setCardLoaded(false)
    setLoading(true)
    dispatch(setErrorDetails({
      message: null
    }))

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (!paymentMethod || error) {
      dispatch(setErrorDetails({
        message: error.message
      }))
      setLoading(false)
      cardElement.clear()
      return
    }

    const brandToString = (brand) => {
      if (brand === "amex") return "Amex"
      if (brand === "diners") return "Diners Club"
      if (brand === "jcb") return "JCB"
      if (brand === "mastercard") return "Mastercard"
      if (brand === "unionpay") return "UnionPay"
      if (brand === "visa") return "Visa"
      return "Card"
    }
    const prettyBrand = brandToString(paymentMethod.card.brand)

    const prettyHint = `${prettyBrand} **** ${paymentMethod.card.last4}`

    const objToSend = {
      stripeDefaultPM: paymentMethod.id,
      stripeDefaultPMHint: prettyHint,
      stripeDefaultPMExpiryDate: `${paymentMethod.card.exp_month < 10 ? `0${paymentMethod.card.exp_month}` : paymentMethod.card.exp_month}/${paymentMethod.card.exp_year.toString().substring(2, 4)}`
    }
    stripeDefaultPMHint.current = prettyHint

    let stripeInfoIdValue;
    if (fromConsultantBooking) {
      if (!userID && !isSignUpInBookingProcess) {
        const stripeInfoRes = await firebase.app().functions().httpsCallable("addStripeInfoForGuest")({
          ...objToSend,
          email: email,
          stripeVersion: "2020-08-27"
        })
        setStripeInfoId(stripeInfoRes.data.id)
        stripeInfoIdValue = stripeInfoRes.data.id
      }
      setPaymentInfoData(objToSend)
    }

    const result = await firebase.app().functions().httpsCallable("setupPaymentIntent")({ stripeInfoId: stripeInfoIdValue })
    const clientSecret = result.data.client_secret

    const confirmCad = await stripe.confirmCardSetup(clientSecret, { payment_method: paymentMethod.id })

    if (!confirmCad.setupIntent || confirmCad.error) {
      console.log("Incoming:")
      console.log(confirmCad.setupIntent)
      console.log(confirmCad.error)
      dispatch(setErrorDetails({
        message: confirmCad.error?.message
      }))
      setLoading(false)
      return
    }

    if (!fromConsultantBooking || (fromConsultantBooking && userID)) {
      await firebase.app().functions().httpsCallable("updatePaymentMethod")(objToSend)
      dispatch(setUserState({
        stripeDefaultPMHint: prettyHint,
        stripeDefaultPMExpiryDate: `${paymentMethod.card.exp_month < 10 ? `0${paymentMethod.card.exp_month}` : paymentMethod.card.exp_month}/${paymentMethod.card.exp_year.toString().substring(2, 4)}`
      }))
    }
    setEdit(false)
    setLoading(false)
    setCardLoaded(true)

    if (!fromConsultantBooking) {
      cardAdded()
    }
  }

  usePaymentStyles()

  const cardElem = () => {
    return (
      <CardElement
        options={{
          hidePostalCode: true
        }}
        onChange={(event) => {
          if (event.complete && fromConsultantBooking) {
            handleSubmit()
          }
        }}
        id="card-element"
      />
    )
  }

  const CancelButton = () => {
    return (
      <Button
        onClick={() => {
          if (bookingFlow && bookingFlow !== "null") {
            setBooking(bookingFlow)
            changePage({
              pathname: COMMON_ROUTES.CONSULTANT_BOOKING.replace(':consultant', bookingFlow.bookingData?.data?.consultantID)
            })
          } else {
            setEdit(false)
          }
        }} color="primary" variant="contained" className='cancel_button'>
        Cancel
      </Button>
    )
  }
  
  return (
    <form>
      {cardElem()}

      <Backdrop
        open={fromConsultantBooking && loading}
        onClick={() => {}}
        style={{ zIndex: 1000, color: "#FFF" }}
      >
        <div className='loading_container_card'>
          <CircularProgress size={24} /> Checking payment information...
        </div>
      </Backdrop>

      {fromConsultantBooking && edit && !loading &&
        <div className='clear_edit'>
          <CancelButton />
        </div>
      }

      <Box className="payment_card_elem_actions" pt={4}>
        <div style={{ position: 'relative' }}>
          {!fromConsultantBooking &&
            <Button
              style={{ transform: 'translateX(-50%)', left: '50%', width: 150 }}
              onClick={handleSubmit} color="primary" variant="contained">
              {loading ? <CircularProgress size={24} color='white' /> : edit ? 'Save' : 'Add card'}
            </Button>
          }
        </div>
      </Box>
    </form>)
}

export default PaymentForm;
