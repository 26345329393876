import React, { useState } from 'react'
import firebase from 'firebase/app'
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button, CircularProgress } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { setUserState } from '../../api/userSlice'

export default function DeleteCardDialog(props) {
    const { handleClose, open, stripeInfoId, setStripeInfoId, setPaymentInfoData } = props
    const dispatch = useDispatch()
    
    const [loading, setLoading] = useState(false);

    const handleDeleteCard = async () => {
        setLoading(true)
        await firebase.app().functions().httpsCallable("deletePaymentMethod")({ stripeInfoId })
        dispatch(setUserState({
            stripeDefaultPMHint: null,
            stripeDefaultPMExpiryDate: null
        }))
        if (setStripeInfoId) {
            setStripeInfoId(null)
        }
        if (setPaymentInfoData) {
            setPaymentInfoData(null)
        }
        handleClose()
    }

    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Delete Card</DialogTitle>
          <DialogContent>
            <DialogContentText>
                Are you sure you wish to do this? This action is permanent!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose} color="white">
                  Cancel
              </Button>
              <Button onClick={handleDeleteCard} style={{ color: 'red', minWidth: 75 }}>
                  {loading ? <CircularProgress size={20} style={{ color: 'red' }} /> : 'Delete'}
              </Button>
          </DialogActions>
      </Dialog>
    )
}