import React, {  } from 'react';
import TableCell from '@material-ui/core/TableCell';
import { TableRow, useMediaQuery } from '@material-ui/core';
import TeamTable from './TeamTable';
import TeamAvailableRow from './TeamAvailabilityRow';
import moment from 'moment';
import { config } from '../../../../config/config';

const TeamAvailabilityTable = (props) => {
  const { dataSource } = props

  const widthLessThan650px = useMediaQuery('(max-width:649px)');
  const widthLessThan450px = useMediaQuery('(max-width:449px)');

  const thisWeek = `${moment().startOf('week').format('MMM DD')} - ${moment().endOf('week').format('MMM DD')}`
  const nextWeek = `${moment().add(1, 'week').startOf('week').format('MMM DD')} - ${moment().add(1, 'week').endOf('week').format('MMM DD')}`
  const thisMonth = moment().startOf('month').format('MMMM YYYY')
  const nextMonth = moment().add(1, 'month').startOf('month').format('MMMM YYYY')
  const year = moment().format('YYYY')

  const tableCells = 
      <>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="center" colSpan={2} style={{ backgroundColor: 'gray', borderRight: '1px solid lightgray' }}>TODAY</TableCell>
          <TableCell align="center" colSpan={2} style={{ backgroundColor: 'gray', borderRight: '1px solid lightgray' }}>TOMORROW</TableCell>
          <TableCell align="center" colSpan={2} style={{ backgroundColor: 'gray', borderRight: '1px solid lightgray' }}>THIS WEEK<br />{thisWeek}</TableCell>
          <TableCell align="center" colSpan={2} style={{ backgroundColor: 'gray', borderRight: '1px solid lightgray' }}>NEXT WEEK<br />{nextWeek}</TableCell>
          <TableCell align="center" colSpan={2} style={{ backgroundColor: 'gray', borderRight: '1px solid lightgray' }}>THIS MONTH<br />{thisMonth}</TableCell>
          <TableCell align="center" colSpan={2} style={{ backgroundColor: 'gray', borderRight: '1px solid lightgray' }}>NEXT MONTH<br />{nextMonth}</TableCell>
          <TableCell align="center" colSpan={2} style={{ backgroundColor: 'gray' }}>THIS YEAR<br />{year}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right" style={{ textTransform: 'none', minWidth: widthLessThan450px ? 90 : widthLessThan650px ? 110 : 150, borderRight: '1px solid lightgray' }}>{config.type === 'astrologer' && 'Astrologer '}Name</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: widthLessThan450px ? 55 : widthLessThan650px ? 69 : 92 }}>Hours Available</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: widthLessThan450px ? 52 : widthLessThan650px ? 65 : 90, backgroundColor: 'lightgray' }}>Hours Booked</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: widthLessThan450px ? 55 : widthLessThan650px ? 69 : 92 }}>Hours Available</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: widthLessThan450px ? 52 : widthLessThan650px ? 65 : 90, backgroundColor: 'lightgray' }}>Hours Booked</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: widthLessThan450px ? 55 : widthLessThan650px ? 69 : 92 }}>Hours Available</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: widthLessThan450px ? 52 : widthLessThan650px ? 65 : 90, backgroundColor: 'lightgray' }}>Hours Booked</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: widthLessThan450px ? 55 : widthLessThan650px ? 69 : 92 }}>Hours Available</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: widthLessThan450px ? 52 : widthLessThan650px ? 65 : 90, backgroundColor: 'lightgray' }}>Hours Booked</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: widthLessThan450px ? 55 : widthLessThan650px ? 69 : 92 }}>Hours Available</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: widthLessThan450px ? 52 : widthLessThan650px ? 65 : 90, backgroundColor: 'lightgray' }}>Hours Booked</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: widthLessThan450px ? 55 : widthLessThan650px ? 69 : 92 }}>Hours Available</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: widthLessThan450px ? 52 : widthLessThan650px ? 65 : 90, backgroundColor: 'lightgray' }}>Hours Booked</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: widthLessThan450px ? 55 : widthLessThan650px ? 69 : 92 }}>Hours Available</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: widthLessThan450px ? 52 : widthLessThan650px ? 65 : 90, backgroundColor: 'lightgray' }}>Hours Booked</TableCell>
        </TableRow>
      </>

  const tableRows = 
      <>
        {dataSource?.map((row, index) => (
          <TeamAvailableRow key={index} data={row} isLastOne={index === dataSource.length - 1} />
        ))}
      </>

  return (
    <TeamTable tableCells={tableCells} tableRows={tableRows} />
  )
};

export default TeamAvailabilityTable;
