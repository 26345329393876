import React, { useState } from 'react';
import './styles.css';
import { Grid } from '@material-ui/core';
import Footer from './footer'
import 'firebase/auth';
import { config } from '../../config/config';
import useCookieTheme from '../App/useCookieTheme';
import AstrologerBanner from '../../images/astrologer_banner.png';
import LoginComponent from './components/LoginComponent';
import SignInComponent from './components/SignInComponent';
import { useGlobalStyles } from '../App/globalStyles';
import { useSelector } from 'react-redux';
import { userState } from '../../api/userSlice';
import { COMMON_ROUTES } from '../../utils/routes';

const SignIn = (props) => {
  const { forConsultant, bannerOn } = props
  const fromEmail = JSON.parse((new URL(document.location)).searchParams.get("fromEmail") ?? null)
  const joinTeam = JSON.parse((new URL(document.location)).searchParams.get("joinTeam") ?? null)
  const { logo, changePage } = useCookieTheme()
  const isLogout = useSelector(userState).isLogout
  const integration = useSelector(userState).integration
  const isIntegrationTeam = useSelector(userState).isIntegrationTeam

  const [showLogin, setShowLogin] = useState((config.type === 'astrologer' && !forConsultant) || isLogout || fromEmail)

  if (isLogout && integration) {
    if (isIntegrationTeam) {
      changePage({
        pathname: COMMON_ROUTES.TEAM_CONSULTANTS.replace(':teamID', integration)
      })
    } else {
      changePage({
        pathname: COMMON_ROUTES.CONSULTANT_DYNAMIC_PAGE.replace(':consultant_code', integration)
      })
    }
  }

  const appLogo = logo

  useGlobalStyles()

  return (
    <>
      <Grid container className='signin'>
        {config.type === 'astrologer' &&
            <img alt="" className="signin_form_banner" src={AstrologerBanner} />
        }
        <Grid item xs={12} className={`signin_form_page ${bannerOn ? 'signin_form_page_banner_on' : ''}`}>
          {config.type !== 'astrologer' &&
            <div className='signin_logo_container'>
              <img 
                alt=""
                className='signin_form_logo'
                src={appLogo}
                onClick={() => {
                  if (config.type === 'yaloop') {
                    window.open('https://www.yaloop.com/', '_blank')
                  }
                }}
              />
            </div>
          }

          <div className='signin_form_container'>
            {showLogin ?
                <LoginComponent
                  setShowLogin={setShowLogin}
                />
              :
                <SignInComponent
                  setShowLogin={setShowLogin}
                  joinTeam={joinTeam}
                  forConsultant={forConsultant}
                />
            }
          </div>
          {config.type !== 'astrologer' &&
            <div className='signin_footer_container'>
              <Footer isConsultant={true} />
            </div>
          }
        </Grid>
      </Grid>
    </>
  )
};

export default SignIn;
