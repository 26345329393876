export const COMMON_ROUTES = {
  CONSULTANT_DYNAMIC_PAGE: '/:consultant_name/:consultant_code',
  CONSULTANT: '/consultant/:consultant',
  CONSULTANT_BOOKING: '/consultant/:consultant/booking',
  ACTIVE_CALL: '/session',
  TEST: '/test',
  GUEST_INSTANT_CALL: '/consultant/:consultant/instant-call',
  TEAM_INVITE: '/team-invite/:invite_id',
  DOWNLOAD_RECORDING: '/download-recording/:id',
  LIST: '/list',
  TEAM_CONSULTANTS: '/team/:teamName/:teamID',
  TEST_WIDGET: '/test-widget'
}

export const NO_AUTH_ROUTES = {
  SIGN_UP: '/',
  SIGN_UP_COSNULTANT: '/sign-up-consultant',
  RESET_PASSWORD: '/account/reset-password',
  TERMS_AND_CONDITIONS: '/termsandconditions',
  PRIVACY: '/privacy',
  GUEST_CALL: '/call/:bookingID',
}

export const AUTH_ROUTES_CONSULTANT = {
  HOME: '/',
  TEAM: '/team',
  PROFILE: '/profile/:id',
  HISTORY: '/history',
  WEBSITE_INTEGRATION: '/website'
}

export const AUTH_ROUTES_CLIENT = {
  HOME: '/',
  ACCOUNT_DETAILS: '/account-details',
  HISTORY: '/history',
  PAYMENT: '/payment'
}

export const checkIfPageExists = (authUserRef, isConsultant, pageName) => {
  const pages = authUserRef.current ? 
                  isConsultant ? 
                    Object.keys({...AUTH_ROUTES_CONSULTANT, ...COMMON_ROUTES}) : 
                    Object.keys({...AUTH_ROUTES_CLIENT, ...COMMON_ROUTES}) : 
                  Object.keys({...NO_AUTH_ROUTES, ...COMMON_ROUTES})

  let pageExists = false
  pages.forEach((name) => {
    let page = authUserRef.current ? 
                isConsultant ? AUTH_ROUTES_CONSULTANT[name] || COMMON_ROUTES[name] : AUTH_ROUTES_CLIENT[name] || COMMON_ROUTES[name] :
              NO_AUTH_ROUTES[name] || COMMON_ROUTES[name]
    let realPageName = pageName
    if (page.includes(':') && realPageName.split('/').length > 2) {
      let pageSplited = page.split('/')
      pageSplited = pageSplited.map((pageS, index) => {
        if (pageS.includes(':')) {
          const realPageNameSplited = realPageName.split('/')
          realPageNameSplited[index] = ':id_of_object'
          realPageName = realPageNameSplited.join('/')
          return ':id_of_object'
        }
        return pageS
      })
      page = pageSplited.join('/')
    }
    if (realPageName === page) {
      pageExists = true
    }
  })

  return pageExists
}
