import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { formattedIntegerLocaleSeparator, localeToCurrency } from '../../utils/utils'
import { useSelector } from 'react-redux'
import { userState } from '../../api/userSlice'

const ConsultantBalance = () => {
  const dbUser = useSelector(userState).user
  const balance = useSelector(userState).balance

  return (
    <div className="consultantBalance heading">
      {balance !== null ? 
        <div>{localeToCurrency(dbUser?.locale)} {formattedIntegerLocaleSeparator(balance, dbUser?.locale)}</div>
      :
        <CircularProgress size={16} style={{ top: 0, left: -20, position: "relative" }} />
      }
    </div>
  )
}

export default ConsultantBalance;