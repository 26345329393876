import React, {  } from 'react';
import TableCell from '@material-ui/core/TableCell';
import { TableRow, useMediaQuery } from '@material-ui/core';
import TeamTable from './TeamTable';
import TeamDiscountRow from './TeamDiscountRow';
import moment from 'moment';
import { config } from '../../../../config/config';

const TeamDiscountTable = (props) => {
  const { dataSource } = props

  const widthLessThan650px = useMediaQuery('(max-width:649px)');
  const widthLessThan450px = useMediaQuery('(max-width:449px)');

  const thisWeek = `${moment().startOf('week').format('MMM DD')} - ${moment().endOf('week').format('MMM DD')}`
  const lastWeek = `${moment().add(-1, 'week').startOf('week').format('MMM DD')} - ${moment().add(-1, 'week').endOf('week').format('MMM DD')}`
  const thisMonth = moment().startOf('month').format('MMMM YYYY')
  const lastMonth = moment().add(-1, 'month').startOf('month').format('MMMM YYYY')
  const year = moment().format('YYYY')

  const tableCells = 
      <>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="center" colSpan={3} style={{ backgroundColor: 'gray', borderRight: '1px solid lightgray' }}>THIS WEEK<br />{thisWeek}</TableCell>
          <TableCell align="center" colSpan={3} style={{ backgroundColor: 'gray', borderRight: '1px solid lightgray' }}>LAST WEEK<br />{lastWeek}</TableCell>
          <TableCell align="center" colSpan={3} style={{ backgroundColor: 'gray', borderRight: '1px solid lightgray' }}>THIS MONTH<br />{thisMonth}</TableCell>
          <TableCell align="center" colSpan={3} style={{ backgroundColor: 'gray', borderRight: '1px solid lightgray' }}>LAST MONTH<br />{lastMonth}</TableCell>
          <TableCell align="center" colSpan={3} style={{ backgroundColor: 'gray' }}>THIS YEAR<br />{year}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right" style={{ textTransform: 'none', minWidth: widthLessThan450px ? 90 : widthLessThan650px ? 110 : 150, borderRight: '1px solid lightgray' }}>{config.type === 'astrologer' && 'Astrologer '}Name</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: 70 }}>Booked Sessions</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', backgroundColor: 'lightgray', minWidth: 70 }}>Booked with 10% Discount</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', backgroundColor: '#f2f2f2', minWidth: 70 }}>%<br/>Booked with 10% Discount</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: 70 }}>Booked Sessions</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', backgroundColor: 'lightgray', minWidth: 70 }}>Booked with 10% Discount</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', backgroundColor: '#f2f2f2', minWidth: 70 }}>%<br/>Booked with 10% Discount</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: 70 }}>Booked Sessions</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', backgroundColor: 'lightgray', minWidth: 70 }}>Booked with 10% Discount</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', backgroundColor: '#f2f2f2', minWidth: 70 }}>%<br/>Booked with 10% Discount</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: 70 }}>Booked Sessions</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', backgroundColor: 'lightgray', minWidth: 70 }}>Booked with 10% Discount</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', backgroundColor: '#f2f2f2', minWidth: 70 }}>%<br/>Booked with 10% Discount</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', minWidth: 70 }}>Booked Sessions</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', backgroundColor: 'lightgray', minWidth: 70 }}>Booked with 10% Discount</TableCell>
          <TableCell align="center" style={{ textTransform: 'none', backgroundColor: '#f2f2f2', minWidth: 70 }}>%<br/>Booked with 10% Discount</TableCell>
        </TableRow>
      </>

  const tableRows = 
      <>
        {dataSource?.map((row, index) => (
          <TeamDiscountRow key={index} data={row} />
        ))}
      </>

  return (
    <TeamTable tableCells={tableCells} tableRows={tableRows} />
  )
};

export default TeamDiscountTable;
