import { Dialog, DialogContent } from '@material-ui/core'
import React from 'react'
import 'firebase/functions'
import LoginComponent from '../../../SignIn/components/LoginComponent';
import { useBookingContext } from '../BookingContext';
import firebase from 'firebase/app'
import { COMMON_ROUTES } from '../../../../utils/routes';
import useCookieTheme from '../../../App/useCookieTheme';
import useBookingHook from '../../useBookingHook';

const LoginDialog = (props) => {
  const { openDialog, setOpenDialog, isInstantCall } = props
  const { changePage } = useCookieTheme()

  const {
    consultantData,
    bookingID,
    giftID,
    bookingData,
    additionalInfoData,
    couponData,
    discountCouponData,
    giftData,
    isDetailsForm,
    isFinishBooking,
    isCongrats,
    isAllSet
  } = useBookingContext()
  const { setBooking } = useBookingHook()

  const saveBookingData = async () => {
    if (isInstantCall) {
      changePage({
        pathname: COMMON_ROUTES.CONSULTANT.replace(':consultant', consultantData.userID)
      })
    } else {
      if (bookingID) {
        firebase.functions().httpsCallable('updateClientIdBooking')({
          bookingID: bookingID,
        })
      }
      if (giftID) {
        firebase.functions().httpsCallable('updateClientIdGift')({
          giftID: giftID,
        })
      }

      setBooking({
        bookingID,
        giftID,
        bookingData,
        additionalInfoData,
        couponData,
        giftData,
        discountCouponData,
        isDetailsForm,
        isFinishBooking,
        isCongrats,
        isAllSet
      })
    }
  }

  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogContent className='login_dialog_content'>
        <LoginComponent
          fromConsultantBooking={true}
          setOpenDialog={setOpenDialog}
          saveBookingData={saveBookingData}
        />
      </DialogContent>
    </Dialog>
  )
}

export default LoginDialog;
