import firebase from 'firebase/app'
import JSZip from 'jszip';
import { config } from '../config/config';
import { setErrorDetails } from '../api/userSlice';

export const downloadRecording = async (each, startMoment, dispatch, setIsDownloading) => {
  if (setIsDownloading) {
    setIsDownloading(true)
  }

  const filesRes = await firebase.functions().httpsCallable('getRecording')({
    clientID: each.clientID,
    id: each.id
  })
  let urlCreator = window.webkitURL || window.URL;
  if (filesRes.data.files.length === 1) {
    const recordingFile = await firebase.app().storage().ref(filesRes.data.files[0].name).getDownloadURL()
    const name = `${startMoment.format('MMMM Do YYYY HH:mm')} ${each.consultantFullName ?? each.consultantHandle}${config.type === 'astrologer' ? ' - Astrology Hub\'s Astrologer Connect.mp4' : '.mp4'}`
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
      try {
        let videoUrl = urlCreator.createObjectURL(xhr.response);
        let tag = document.createElement('a');
        tag.href = videoUrl;
        tag.target = '_blank';
        tag.download = name;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
        
        if (!setIsDownloading) {
          dispatch(setErrorDetails({
            message: `Successfully downloaded your recording!`,
            severity: 'success'
          }))
        }
      } catch (err) {
        console.log('Cannot download your recording because it\'s not available', err)
        dispatch(setErrorDetails({
          message: 'Failed to download your recording because it\'s unavailable! ' + err
        }))
      } finally {
        if (setIsDownloading) {
          setIsDownloading(false)
        }
      }
    };
    xhr.onerror = (err) => {
      console.log('Cannot download your recording because it\'s not available', err)
      dispatch(setErrorDetails({
        message: 'Cannot download your recording because it\'s not available! ' + err
      }))
      if (setIsDownloading) {
        setIsDownloading(false)
      }
    };
    xhr.open('GET', recordingFile, true);
    xhr.send();
  } else if (filesRes.data.files.length > 1) {
    const filesArray = filesRes.data.files
    let allFilesZiped = filesArray.map(_ => { return false })
    const zip = new JSZip()
    await Promise.all(filesArray.map(async (file, index) => {
      const recordingFile = await firebase.app().storage().ref(file.name).getDownloadURL()
      let xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function () {
        try {
          zip.file(`recording_${index + 1}.mp4`, xhr.response)
        } catch (err) {
          console.log('Cannot download your recording because it\'s not available', err)
          dispatch(setErrorDetails({
            message: 'Failed to download your recording because it\'s unavailable! ' + err
          }))
        } finally {
          allFilesZiped[index] = true
        }
      };
      xhr.onerror = (err) => {
        console.log('Cannot download your recording because it\'s not available', err)
        dispatch(setErrorDetails({
          message: 'Cannot download your recording because it\'s not available! ' + err
        }))
      };
      xhr.open('GET', recordingFile, true);
      xhr.send();
    }))

    const zipName = `${startMoment.format('MMMM Do YYYY HH:mm')} ${each.consultantFullName ?? each.consultantHandle}${config.type === 'astrologer' ? ' - Astrology Hub\'s Astrologer Connect.zip' : '.zip'}`
    const intervalZip = setInterval(() => {
      const noFinishRecording = allFilesZiped.filter(ziped => ziped === false)
      if (!noFinishRecording.length) {
        clearInterval(intervalZip)
        try {
          zip.generateAsync( {type:"blob"} ).then(function(content) {
            let url = urlCreator.createObjectURL(content);
            let tag = document.createElement('a');
            tag.href = url;
            tag.target = '_blank';
            tag.download = zipName;
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
        
            if (!setIsDownloading) {
              dispatch(setErrorDetails({
                message: `Successfully downloaded your recording!`,
                severity: 'success'
              }))
            }
          });
        } catch (err) {
          console.log('Cannot download your recording because it\'s not available', err)
          dispatch(setErrorDetails({
            message: 'Failed to download your recording because it\'s unavailable! ' + err
          }))
        } finally {
          if (setIsDownloading) {
            setIsDownloading(false)
          }
        }
      }
    }, 5000)
  } else {
    console.log('Recording is not available yet, pls try again later')
    dispatch(setErrorDetails({
      message: 'Recording is not available yet, pls try again later!'
    }))
    if (setIsDownloading) {
      setIsDownloading(false)
    }
  }  
}
