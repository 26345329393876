import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { IntakeInformationContent } from '../../../ActiveCall/components/IntakeInformationContent';

const IntakeInformationDialog = (props) => {
  const { bookingID, open, setOpen } = props

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <IntakeInformationContent bookingID={bookingID} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default IntakeInformationDialog
