import React, {  } from 'react';
import { CircularProgress } from '@material-ui/core';
import TeamAvailabilityTable from '../Table/TeamAvailabilityTable';

const BookedHoursTab = (props) => {
  const { reportData } = props

  return (
    <>
      {reportData ?
        <>
          <div className='team_availability_title heading'>Booked Hours</div>
          <div className='availability_table'>
            <TeamAvailabilityTable dataSource={reportData} />
          </div>
        </>
      :
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
          <CircularProgress size={50} />
        </div>
      }
    </>
  )
};

export default BookedHoursTab;
