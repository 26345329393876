import React from 'react'
import 'firebase/functions'
import { Grid } from '@material-ui/core';
import { useBookingContext } from '../BookingContext';
import { ValidatorForm } from 'react-material-ui-form-validator';
import BirthInfosForm from './BirthInfosForm';
import useCookieTheme from '../../../App/useCookieTheme';

const BirthInformation = () => {
  const { birthInfosData, setBirthInfosData } = useBookingContext()
  const { consultantTitle, appointmentName } = useCookieTheme()

  return (
    <div className='item form_information'>
      <div className='margin_item'>
        <p>BIRTH INFORMATION</p>
        <p className='astrologer_text heading'>
          Your {consultantTitle} will need accurate birth information to pull your astrology chart for your {appointmentName ?? 'appointment'}. 
          &nbsp;<b>If you don't know your birth time</b>, please put 12:00pm or the closest time you are aware of and make a note 
          for your {consultantTitle} in the <i>"Anything else we should know?"</i> &nbsp;section at the end of the booking process. 
        </p>
      
        <ValidatorForm
          className={"form_items"}
          onError={errors => console.log(errors)}
          onSubmit={() => { return }}
        >
          <Grid className='details_client_form' container spacing={2}>
            <BirthInfosForm birthInfosState={birthInfosData} setBirthInfoState={setBirthInfosData} />
          </Grid>
        </ValidatorForm>
      </div>
    </div>
  )
}

export default BirthInformation;
