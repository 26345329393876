import React, { useEffect } from 'react'
import 'firebase/functions'
import { Button, Grid, useMediaQuery } from '@material-ui/core';
import { useState } from 'react';
import LoginDialog from './LoginDialog';
import { useBookingContext } from '../BookingContext';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import firebase from 'firebase/app'
import { setErrorDetails, userState } from '../../../../api/userSlice';
import { useDispatch, useSelector } from 'react-redux';

const ContactInformation = (props) => {
  const { isInstantCall, setEmailUsed } = props
  const dispatch = useDispatch()
  const testIntegration = useSelector(userState).testIntegration
  const { contactInfoData, setContactInfoData, discountCouponData, setPaymentInfoData } = useBookingContext()

  const [openDialog, setOpenDialog] = useState(false)

  const widthLessThan600px = useMediaQuery('(max-width:599px)');

  useEffect(() => {
    ValidatorForm.addValidationRule('minChars', (value) => {
        if (value.toString().length < 2) {
            return false;
        }
        return true;
    });
    ValidatorForm.addValidationRule('isEmailMatch', () => {
      if (contactInfoData?.confirmEmail !== contactInfoData?.email) {
        return false;
      }
      return true;
    });

    return () => {
      ValidatorForm.removeValidationRule('minChars')
      ValidatorForm.removeValidationRule('isEmailMatch')
    }
  }, [contactInfoData])

  const checks = async () => {
    setPaymentInfoData(null)
    if (contactInfoData?.email) {
      const existsRes = await firebase.app().functions().httpsCallable("checkIfUserExists")({ email: contactInfoData?.email })
      if (existsRes.data?.exists) {
        dispatch(setErrorDetails({
          message: 'This email is already in use. Either login or enter another email as guest!'
        }))
      }
      setEmailUsed(existsRes.data?.exists)
    }
    if (discountCouponData) {
      const res = await firebase.functions().httpsCallable("checkDiscountCouponForGuest")({
        code: discountCouponData.code,
        clientEmail: contactInfoData?.email
      })
      if (res.data?.error) {
        dispatch(setErrorDetails({
          message: res.data?.error
        }))
        setContactInfoData(olDContactInfoData => ({
          ...olDContactInfoData,
          email: ""
        }))
      }
    }
  }

  const onClickLogin = () => {
    if (testIntegration) {
      dispatch(setErrorDetails({
        message: 'Cannot login in preview widget because you\'re already logged as consultant!',
        severity: 'warning'
      }))
    } else {
      setOpenDialog(true)
    }
  }

  return (
    <div className='item form_information'>
      <div className='margin_item'>
        <div className='header_info'>
          <p>CONTACT INFORMATION</p>
          <Button
            className='dismiss_buttton have_an_account_button'
            onClick={onClickLogin}
            color='primary'
            variant="outlined"
          >
            HAVE AN ACCOUNT? LOGIN HERE!
          </Button>
        </div>
      
        <ValidatorForm
          className={"form_items"}
          onError={errors => console.log(errors)}
          onSubmit={() => { return }}
        >
          <Grid container spacing={2}>
            <Grid item xs={widthLessThan600px ? 12 : 6}>
              <TextValidator
                validators={['required', 'minChars']}
                variant="outlined"
                size={'small'}
                errorMessages={['This field is required', 'Min 2 characters']}
                onChange={(e) => {
                  const { value } = e.target
                  setContactInfoData(olDContactInfoData => ({
                    ...olDContactInfoData,
                    firstName: value
                  }))
                }}
                value={contactInfoData?.firstName}
                label="FIRST NAME" fullWidth required
              />
            </Grid>
            <Grid item xs={widthLessThan600px ? 12 : 6}>
              <TextValidator
                validators={['required', 'minChars']}
                variant="outlined"
                size={'small'}
                errorMessages={['This field is required', 'Min 2 characters']}
                onChange={(e) => {
                  const { value } = e.target
                  setContactInfoData(olDContactInfoData => ({
                    ...olDContactInfoData,
                    lastName: value
                  }))
                }}
                value={contactInfoData?.lastName} 
                label="LAST NAME" fullWidth required
              />
            </Grid>
            <Grid item xs={widthLessThan600px ? 12 : 6}>
              <TextValidator
                validators={['required', 'isEmail']}
                variant="outlined"
                size={'small'}
                errorMessages={['This field is required', '']}
                onChange={(e) => {
                  const { value } = e.target
                  setContactInfoData(olDContactInfoData => ({
                    ...olDContactInfoData,
                    email: value
                  }))
                }}
                onBlur={checks}
                value={contactInfoData?.email}
                label="EMAIL"
                type="email" fullWidth required
              />
            </Grid>
            <Grid item xs={widthLessThan600px ? 12 : 6}>
              <TextValidator
                validators={['required', 'isEmailMatch']}
                variant="outlined"
                size={'small'}
                errorMessages={['This field is required', '']}
                onChange={(e) => {
                  const { value } = e.target
                  setContactInfoData(olDContactInfoData => ({
                    ...olDContactInfoData,
                    confirmEmail: value
                  }))
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                value={contactInfoData?.confirmEmail}
                label="CONFIRM EMAIL"
                type="email" fullWidth required
              />
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>

      {openDialog && 
        <LoginDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          isInstantCall={isInstantCall}
        />
      }
    </div>
  )
}

export default ContactInformation;
