import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, Checkbox, CircularProgress, DialogActions, DialogTitle, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import DatePicker from 'react-multi-date-picker';
import { useEffect } from 'react';
import { config } from '../../../config/config';
import firebase from 'firebase/app'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTheme } from '@material-ui/core/styles';
import Confirmation from '../../components/Confirmation';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import { capitalizeFirstLetter } from '../../../utils/utils';
import useCookieTheme from '../../App/useCookieTheme';
import TeamDiscountCouponsTable from '../../Team/components/Table/TeamDiscountCouponsTable';
import { setErrorDetails } from '../../../api/userSlice';
import { useDispatch } from 'react-redux';

export const cutOptions = (consultantTitle) => [
  {
    id: 'consultant',
    label: `${consultantTitle ? capitalizeFirstLetter(consultantTitle) : 'Consultant'}s Cut`
  },
  {
    id: 'team',
    label: `Team Cut`
  },
  {
    id: 'both',
    label: `Both Equal`
  }
]

const DiscountCoupons = (props) => {
  const { consultantID, masterID, memberList } = props
  const { consultantTitle } = useCookieTheme()
  const dispatch = useDispatch()

  const [discountCoupons, setDiscountCoupons] = useState([])
  const [open, setOpen] = useState(false)
  const [selectedID, setSelectedID] = useState(null)
  const [name, setName] = useState(null)
  const [percent, setPercent] = useState(null)
  const [expiryDate, setExpiryDate] = useState(null)
  const [disableButton, setDisableButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [allMembers, setAllMembers] = useState(true);
  const [selectedCutOption, setSelectedCutOption] = useState(null);

  useEffect(() => {
    const getDiscountCoupons = async () => {
      if (consultantID) {
        firebase.app().firestore().collection(`/discount_coupons`)
          .where('consultantID', '==', consultantID).onSnapshot(resDiscountCoupons => {
            setDiscountCouponsFiltered(resDiscountCoupons)
          })
      } else if (masterID) {
        firebase.app().firestore().collection(`/discount_coupons`)
          .where('masterID', '==', masterID).onSnapshot(resDiscountCoupons => {
            setDiscountCouponsFiltered(resDiscountCoupons)
          })
      }

      const setDiscountCouponsFiltered = (resDiscountCoupons) => {
        let discountCouponsDB = resDiscountCoupons.docs.map(res => {
          return {
            ...res.data(),
            id: res.id
          }
        })
        discountCouponsDB = discountCouponsDB?.filter(dcDB => {
          const dcDBDate = new Date(dcDB.expiryDateYear, dcDB.expiryDateMonth, dcDB.expiryDateDay)
          const now = moment().startOf('day').toDate()
          return dcDBDate.getTime() >= now.getTime()
        }).sort((a, b) => {
          const dcDBDateA = new Date(a.expiryDateYear, a.expiryDateMonth, a.expiryDateDay)
          const dcDBDateB = new Date(b.expiryDateYear, b.expiryDateMonth, b.expiryDateDay)
          return dcDBDateA - dcDBDateB
        })
        setDiscountCoupons(discountCouponsDB)
      }
    }

    getDiscountCoupons()
  }, [consultantID, masterID])

  useEffect(() => {
    const disable = !name || name?.length < 5 || !percent || percent < 1 || percent > 100 || !expiryDate
    setDisableButton(disable || (memberList && ((!allMembers && !selectedMembers.length) || !selectedCutOption)))
  }, [allMembers, expiryDate, name, percent, selectedMembers, selectedCutOption, memberList])

  const handleClose = () => {
    setLoading(false)
    setOpen(false)
    setName(null)
    setPercent(null)
    setExpiryDate(null)
    setSelectedID(null)
    setSelectedMembers([])
    setAllMembers(true)
    setSelectedCutOption(null)
  }

  const handleCreate = async () => {
    setLoading(true)

    if (selectedID) {
      await firebase.firestore().doc('/discount_coupons/' + selectedID).update({
        percent,
        expiryDateDay: expiryDate.getDate(),
        expiryDateMonth: expiryDate.getMonth(),
        expiryDateYear: expiryDate.getFullYear(),
        members: selectedMembers,
        cutOption: selectedCutOption?.id ?? null
      })
      dispatch(setErrorDetails({
        message: 'Successfully update discount coupon!',
        severity: 'success'
      }))
      handleClose()
    } else {
      const res = await firebase.functions().httpsCallable("createDiscountCoupon")({
        consultantID,
        masterID,
        name,
        percent,
        expiryDateDay: expiryDate.getDate(),
        expiryDateMonth: expiryDate.getMonth(),
        expiryDateYear: expiryDate.getFullYear(),
        members: selectedMembers,
        cutOption: selectedCutOption?.id
      })
      if (res.data.exists) {
        dispatch(setErrorDetails({
          message: 'A discount coupon with this name already exists!'
        }))
        setLoading(false)
      } else {
        dispatch(setErrorDetails({
          message: 'Successfully create discount coupon!',
          severity: 'success'
        }))
        handleClose()
      }
    }
  }

  const handleEdit = (discountCoupon) => {
    setName(discountCoupon.name)
    setPercent(discountCoupon.percent)
    setExpiryDate(new Date(discountCoupon.expiryDateYear, discountCoupon.expiryDateMonth, discountCoupon.expiryDateDay))
    setSelectedID(discountCoupon.id)
    setSelectedMembers(discountCoupon.members)
    setAllMembers(!discountCoupon.members?.length)
    setSelectedCutOption(cutOptions(consultantTitle).find(option => option.id === discountCoupon.cutOption))
    setOpen(true)
  }

  const handleDelete = (discountCouponID) => {
    setSelectedID(discountCouponID)
    setShowDeleteConfirmation(true)
  }

  const deleteDiscountCoupon = async () => {
    setLoading(true)
    await firebase.functions().httpsCallable("deleteDiscountCoupon")({
      id: selectedID
    })
    setShowDeleteConfirmation(false)
    handleClose()
  }
  
  const themeMainColor = useTheme().palette.primary.main

  return (
    <div className='discount_coupon_container'>
      {!memberList && <p className='team_availability_title content_label heading'>Discount Coupons</p>}
      <Button onClick={() => setOpen(true)} color="primary" variant="outlined">
        CREATE DISCOUNT COUPON
      </Button>

      {discountCoupons.length ?
        <>
          {memberList ? 
            <TeamDiscountCouponsTable dataSource={discountCoupons} handleEdit={handleEdit} handleDelete={handleDelete} />
          :
            <>
              {discountCoupons.map(discountCoupon => {
                return (
                  <div key={discountCoupon.id} className='discount_coupon_item'>
                    <p className='name'>{discountCoupon.name}</p>
                    <p className='percent'>{discountCoupon.percent}%</p>
                    <p className='expiry_date'>{discountCoupon.expiryDateMonth + 1}/{discountCoupon.expiryDateDay}/{discountCoupon.expiryDateYear}</p>
                    <p className='actions'>
                      <EditIcon style={{ color: themeMainColor, cursor: 'pointer' }} onClick={() => handleEdit(discountCoupon)} />
                      <DeleteIcon style={{ color: 'red', cursor: 'pointer' }} onClick={() => handleDelete(discountCoupon.id)} />
                    </p>
                  </div>
                )  
              })}
            </>
          }
        </>
      :
        <p>No coupons.</p>
      }

      {open &&
        <Dialog
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Create discount coupon</DialogTitle>
          <DialogContent className='discount_coupon_container_dialog_content'>
            <DialogContentText>
              <ValidatorForm
                onError={errors => console.log(errors)}
                onSubmit={() => { return }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      size='small'
                      onChange={(e) => setName(e.target.value.replace(/\s/g, ''))}
                      value={name}
                      label="Name (min 5 characters)"
                      fullWidth
                      required
                      disabled={Boolean(selectedID)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      size='small'
                      onChange={(e) => { 
                        let value = e.target.value
                        if (value < 0) {
                            value = 0
                        }
                        const maxValue = config.type === 'loki' ? 50 : 100
                        if (value > maxValue) {
                            value = maxValue
                        }
                        setPercent(parseInt(value)) 
                    }}
                      value={percent}
                      type="number"
                      label="Percent"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid className='delivery_date' item xs={12}>
                    <DatePicker
                      value={expiryDate}
                      onChange={(date) => {
                        setExpiryDate(date.toDate())
                      }}
                      minDate={new Date()}
                      placeholder='Expiry date *'
                    />
                  </Grid>
                  {memberList &&
                    <>
                      <Grid item xs={12}>
                        <Autocomplete
                          options={memberList}
                          value={selectedMembers}
                          getOptionLabel={(option) => option.name}
                          autoHighlight
                          onChange={(_, e) => {
                            setSelectedMembers(e)
                          }}
                          multiple
                          disabled={allMembers}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size='small'
                              label="Choose members*"
                              variant='outlined'
                            />
                          )}
                        />
                      </Grid>
                      <Grid className='all_members_checkbox' item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={allMembers}
                                    onChange={(e) => {
                                      setAllMembers(e.target.checked)
                                      setSelectedMembers([])
                                    }}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label='All members'
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          options={cutOptions(consultantTitle)}
                          value={selectedCutOption}
                          getOptionLabel={(option) => option.label}
                          autoHighlight
                          onChange={(_, e) => {
                            setSelectedCutOption(e)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size='small'
                              label="Choose cut option*"
                              variant='outlined'
                            />
                          )}
                        />
                      </Grid>
                    </>
                  }
                </Grid>
              </ValidatorForm>
            </DialogContentText>
          </DialogContent>
          <DialogActions className='discount_coupon_container_dialog_actions'>
              <Button onClick={handleClose} color="white">
                  Dismiss
              </Button>
              <Button className='create_button' disabled={disableButton} onClick={handleCreate} color="primary">
                  {loading ? <CircularProgress size={20} /> : selectedID ? 'Update' : 'Create'}
              </Button>
          </DialogActions>
        </Dialog>
      }

      {showDeleteConfirmation &&
        <Confirmation
          content="Are you sure you want to delete this discount coupon?"
          onClose={setShowDeleteConfirmation}
          onAction={deleteDiscountCoupon}
          loading={loading}
        />
      }
    </div>
  )
}

export default DiscountCoupons
