import { Button, CircularProgress, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useState } from 'react'
import './styles.css'
import firebase from 'firebase/app'
import useCookieTheme from '../App/useCookieTheme'
import { AUTH_ROUTES_CONSULTANT } from '../../utils/routes'

const AccountResetPasswordPage = () => {
    const theCode = (new URL(document.location)).searchParams.get("code");
    const themeName = (new URL(document.location)).searchParams.get("theme");
    const { logo, changePage } = useCookieTheme()

    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setNewConfirmPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState(theCode ? null : "Invalid code supplied, please try again later!")
    const [isLoading, setLoading] = useState(false)

    const invalidInput = newPassword !== confirmNewPassword || newPassword.length < 6

    const handleSave = async () => {
        if (invalidInput) {
            return
        }
        setLoading(true)

        try {
            const res = await firebase.functions().httpsCallable("confirmForgotPassword")({
                code: theCode,
                newPassword: newPassword
            })
            if (res.data.status === "expired") {
                setErrorMessage("That password reset link expired, please try sending a new one!")
                return
            } else if (res.data.status === "ok") {
                setTimeout(() => {
                    document.location.href = `/?message=PASSWORD_RESET&theme=${themeName}`;
                }, 100);

            } else {
                setErrorMessage("An unknown error occured, please try again later!")
            }
        } catch {
            setErrorMessage("An unknown error occured, please try again later!")
        }
    }

    return (
        <div class="unverifiedEmail">
            <img alt="" className="signin_form_logo" src={logo} />
            {errorMessage && <Alert style={{ marginTop: 20, marginBottom: 20 }} severity="error">{errorMessage}</Alert>}

            {!errorMessage && <TextField
                disabled={isLoading}
                type="password"
                margin="dense"
                label="New Password"
                onChange={(event) => setNewPassword(event.target.value)}
            />}
            {!errorMessage && <TextField
                disabled={isLoading}
                type="password"
                margin="dense"
                label="Confirm new Password"
                onChange={(event) => setNewConfirmPassword(event.target.value)}
            />}

            <div className='characters_limit'>
              {newPassword.length < 6 && <p>*The password must have at least 6 characters</p>}
              {invalidInput && <p>*Confirm password must be the same as new password</p>}
            </div>

            {!errorMessage && <Button style={{ marginTop: 10, width: 250, height: 50 }} disabled={isLoading || invalidInput} color="primary" variant="contained" onClick={handleSave}>
                {!isLoading && "Update password"}
                {isLoading && <CircularProgress />}
            </Button>}

            {(errorMessage) && <Button style={{ marginTop: 10 }} color="primary" onClick={() => {
                changePage({
                    pathname: AUTH_ROUTES_CONSULTANT.HOME
                })
            }}>Go Home</Button>}
        </div>
    )
}

export default AccountResetPasswordPage;
