import React, {  } from 'react';
import 'firebase/firestore'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab'

const CallTicker = (props) => {
  const {
    counter,
    isConsultant,
    isChatCall,
    otherHandle,
    endsIn5Mins,
    onClose,
  } = props

  return (
    <>
    {counter > 0 ?
      <Typography>
        {moment(counter * 1000).format('mm:ss')}
      </Typography>
      :
      <>
        {isConsultant ?
          <div className='calling'>Connecting...</div> :
          <div className='calling'>{isChatCall ? `Initiating text chat with ${otherHandle}` : 'Calling...'}</div>
        }
      </>
    }

    {endsIn5Mins &&
      <Snackbar
        open={endsIn5Mins}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className='timeEndsBooking'
      >
        <Alert onClose={onClose} severity={`error`}>
          The official booking time ends in less than 5 minutes!
        </Alert>
      </Snackbar>
    }
    </>
  )
};

export default CallTicker;
