import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import React from 'react';

const AddMember = (props) => {
  const { formMode, newInviteTapped, email, setEmail, sendInviteTapped, cancelTapped, isAddingMember } = props

  return (
    <div className='add_container'>
      {isAddingMember ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={50} />
        </div>
      :
        <>
        {!formMode &&
          <Button className='newMemberButton' color="primary" variant="contained" onClick={newInviteTapped}>
            Add member
          </Button>
        }

        {formMode &&
          <div className='inviteContainer'>
            <Typography variant="body1" display="inline" gutterBottom className='fillMessage'>
              Please fill the new member email in the next field:
            </Typography>
            <TextField
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Mail Address"
              variant="outlined"
              type='text'
              fullWidth
            />
            <div className='inviteContainer_buttons'>
              <Button variant="contained"  color="primary" onClick={sendInviteTapped}>Send Invite</Button>
              <Button variant="contained"  color="primary" onClick={cancelTapped}>Cancel</Button>
            </div>
          </div>
        }
        </>
      }
    </div>
  )
}
  
export default AddMember;
