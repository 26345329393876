import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { userState } from '../../../api/userSlice';
import firebase from 'firebase/app'
import useBookingHook from '../useBookingHook';

const BookingContext = React.createContext();

function BookingProvider(props) {
  const dbUser = useSelector(userState).user
  const { getBooking } = useBookingHook()
  const bookingFlow = getBooking()
  const isBookingFlow = bookingFlow && bookingFlow !== "null"

  const [bookingID, setBookingID] = useState(isBookingFlow ? bookingFlow.bookingID : null)
  const [giftID, setGiftID] = useState(isBookingFlow ? bookingFlow.giftID : null)
  const [consultantData, setConsultantData] = useState(null)
  const [bookingData, setBookingData] = useState(isBookingFlow ? bookingFlow.bookingData : null)
  const authUser = firebase.auth().currentUser
  const [contactInfoData, setContactInfoData] = useState(dbUser ? {
    lastName: dbUser.surname,
    firstName: dbUser.name,
    email: authUser?.email
  } : null)
  const [paymentInfoData, setPaymentInfoData] = useState(dbUser ? {
    stripeDefaultPMHint: dbUser.stripeDefaultPMHint
  } : isBookingFlow ? bookingFlow.paymentInfoData : null)
  const seconds = dbUser?.birthInfos?.birthDate?.seconds
  const [birthInfosData, setBirthInfosData] = useState(dbUser ? {
    birthDate: seconds ? new Date(seconds * 1000) : dbUser?.birthInfos?.birthDate ? new Date(dbUser?.birthInfos?.birthDate) : null,
    city: dbUser.birthInfos?.city,
    state: dbUser.birthInfos?.state,
    country: dbUser.birthInfos?.country,
  } : null)
  const [createAccountData, setCreateAccountData] = useState(null)
  const [openForm, setOpenForm] = useState(false)
  const [additionalInfoData, setAdditionalInfoData] = useState(isBookingFlow ? bookingFlow.additionalInfoData : null)
  const [submittedAdditionalInfoData, setSubmittedAdditionalInfoData] = useState(false)
  const [couponData, setCouponData] = useState(isBookingFlow ? bookingFlow.couponData : null)
  const [discountCouponData, setDiscountCouponData] = useState(isBookingFlow ? bookingFlow.discountCouponData : null)
  const [giftData, setGiftData] = useState(isBookingFlow ? bookingFlow.giftData : null)
  const [isDetailsForm, setIsDetailsForm] = useState(isBookingFlow ? bookingFlow.isDetailsForm || bookingFlow.isFinishBooking : false)
  const [isFinishBooking, setIsFinishBooking] = useState(false)
  const [isCongrats, setIsCongrats] = useState(false)
  const [isAlmostDone, setIsAlmostDone] = useState(isBookingFlow ? bookingFlow.isCongrats : false)
  const [isAllSet, setIsAllSet] = useState(isBookingFlow ? bookingFlow.isAllSet : false)
  const [isCreatedAccount, setIsCreatedAccount] = useState(false)
  const [isTenPercentDiscount, setIsTenPercentDiscount] = useState(false)
  const toPayment = useRef(false)

  useEffect(() => {
    if (dbUser) {
      setContactInfoData({
        lastName: dbUser.surname,
        firstName: dbUser.name,
        email: authUser?.email
      })
    }
  }, [authUser, dbUser])

  useEffect(() => {
    if (dbUser) {
      setPaymentInfoData({
        stripeDefaultPMHint: dbUser.stripeDefaultPMHint
      })
    }
  }, [dbUser])

  useEffect(() => {
    if (dbUser) {
      const seconds = dbUser.birthInfos?.birthDate?.seconds
      setBirthInfosData({
        birthDate: seconds ? new Date(seconds * 1000) : dbUser.birthInfos?.birthDate ? new Date(dbUser.birthInfos?.birthDate) : null,
        city: dbUser.birthInfos?.city,
        state: dbUser.birthInfos?.state,
        country: dbUser.birthInfos?.country,
      })
    }
  }, [dbUser])

    return (
        <BookingContext.Provider value={{
          bookingID, setBookingID,
          giftID, setGiftID,
          consultantData, setConsultantData,
          bookingData, setBookingData,
          contactInfoData, setContactInfoData,
          paymentInfoData, setPaymentInfoData,
          birthInfosData, setBirthInfosData,
          createAccountData, setCreateAccountData,
          openForm, setOpenForm,
          additionalInfoData, setAdditionalInfoData,
          submittedAdditionalInfoData, setSubmittedAdditionalInfoData,
          couponData, setCouponData,
          discountCouponData, setDiscountCouponData,
          giftData, setGiftData,
          isDetailsForm, setIsDetailsForm,
          isFinishBooking, setIsFinishBooking,
          isCongrats, setIsCongrats,
          isAlmostDone, setIsAlmostDone,
          isAllSet, setIsAllSet,
          isCreatedAccount, setIsCreatedAccount,
          isTenPercentDiscount, setIsTenPercentDiscount,
          toPayment
         }}>
            {props.children}
        </BookingContext.Provider>
    );
}

function useBookingContext() {
    const bookingContext = React.useContext(BookingContext);
    if (!bookingContext) {
        throw new Error('useBookingContext must be used within a BookingProvider');
    }
    return bookingContext;
}

export { BookingProvider, useBookingContext };