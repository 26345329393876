import { useEffect } from "react";
import { useState } from "react";
import AgoraRTC from 'agora-rtc-sdk-ng';
import { useRef } from "react";
import { config } from "../../config/config";
import { isChrome, isSafari } from "react-device-detect";

export default function useTestAgoraHook(setOpens, videoEnabled) {
  const [activeStep, setActiveStep] = useState(-1)
  const [finishTest, setFinishTest] = useState(false)
  const [volume, setVolume] = useState(0)
  const profileStatus = useRef('pending')
  const initialTestSuites = [
    {
      id: 0,
      notError: true,
      message: "",
      open: false
    },
    {
      id: 1,
      notError: true,
      message: "",
      open: false
    },
    {
      id: 2,
      notError: true,
      message: "",
      open: false
    },
  ]
  if (videoEnabled) {
    initialTestSuites.push({
      id: 3,
      notError: true,
      message: "",
      open: false
    })
  }
  const [testSuites, setTestSuites] = useState(initialTestSuites)

  const agoraSendClientRef = useRef(AgoraRTC.createClient({ mode: "rtc", codec: "vp8" }))
  const microphone = useRef(null)
  const videoTrackRef = useRef(null)

  useEffect(() => {
    const handleSteps = async () => {
      switch(activeStep) {
        case 0: checkBrowserCompatibility()
                break;
        case 1: await checkMicrophone()
                break;
        case 2: break;
        case 3: await startLocalCamera();
                break;
        default: return
      }
    }

    handleSteps()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep])

  const checkBrowserCompatibility = () => {
    setTimeout(() => {
      const checkSystem = AgoraRTC.checkSystemRequirements()
      setTestSuites(oldTestSuites => {
        oldTestSuites.find(testSuite => testSuite.id === 0).notError = checkSystem
        oldTestSuites.find(testSuite => testSuite.id === 0).message = `Browser is${!checkSystem ? ' not' : ''} supported.${!checkSystem ? '<br /><p>The platform is compatible with Chrome and Safari browsers only.</p>' : ''}`
        return oldTestSuites
      })
      setActiveStep(1)
      if (!checkSystem) {
        setOpens(oldOpens => {
          const newOpens = [...oldOpens]
          newOpens[0] = !newOpens[0]
          return newOpens
        })
      }
    }, 3000)
  }

  const checkMicrophone = async () => {
    try {
      microphone.current = await AgoraRTC.createMicrophoneAudioTrack({ bypassWebAudio: true })
      let totalVolume = 0
      const microphoneCheckTimer = setInterval(() => {
        const inputVolume = Math.floor(
          microphone.current?.getVolumeLevel() * 100
        );
        setVolume(inputVolume)
        totalVolume += inputVolume;
      }, 100);
      setTimeout(() => {
        clearInterval(microphoneCheckTimer);
        if (microphone.current) {
          microphone.current.close();
          microphone.current.stop();
        }
        if (totalVolume < 60) {
          setTestSuites(oldTestSuites => {
            oldTestSuites.find(testSuite => testSuite.id === 1).notError = false
            oldTestSuites.find(testSuite => testSuite.id === 1).message = 'Can barely hear you.'
            return oldTestSuites
          })
          setOpens(oldOpens => {
            const newOpens = [...oldOpens]
            newOpens[1] = !newOpens[1]
            return newOpens
          })
        } else {
          setTestSuites(oldTestSuites => {
            oldTestSuites.find(testSuite => testSuite.id === 1).message = 'Microphone works well.'
            return oldTestSuites
          })
        }
        setActiveStep(2)
      }, 7000)
    } catch (e) {
      if (e.code === 'PERMISSION_DENIED') {
        setTestSuites(oldTestSuites => {
          oldTestSuites.find(testSuite => testSuite.id === 1).notError = false
          oldTestSuites.find(testSuite => testSuite.id === 1).message = `<p>Permission denied.<br />
${isSafari ? 'Go to Preferences > Websites > Microphone > When visiting other websites: select \'Allow\' from the drop-down.' :
isChrome ? 'Go to Preferences > Privacy & Security > Site Settings > then select Microphone and allow the website to have access to it.' : ''}</p>`
          return oldTestSuites
        })
        setOpens(oldOpens => {
          const newOpens = [...oldOpens]
          newOpens[1] = !newOpens[1]
          return newOpens
        })
        setTimeout(() => {
          setActiveStep(2)
        }, 1000)
      }
    }
  }

  const checkSpeaker = (value) => {
    if (value) {
      setTestSuites(oldTestSuites => {
        oldTestSuites.find(testSuite => testSuite.id === 2).message = 'Speaker works well.'
        return oldTestSuites
      })
    } else {
      setTestSuites(oldTestSuites => {
        oldTestSuites.find(testSuite => testSuite.id === 2).notError = false
        oldTestSuites.find(testSuite => testSuite.id === 2).message = 'Something is wrong with the speaker.<br /><p>Please make sure the volume on your device is turned on.</p>'
        return oldTestSuites
      })
      setOpens(oldOpens => {
        const newOpens = [...oldOpens]
        newOpens[2] = !newOpens[2]
        return newOpens
      })
    }
    if (videoEnabled) {
      setActiveStep(3)
    } else {
      setFinishTest(true)
    }
  }

  const startTest = (setOpens) => {
    setTestSuites(initialTestSuites)
    setFinishTest(false)
    setVolume(0)
    profileStatus.current = 'pending'
    setActiveStep(0)
    setOpens([false, false, false, false, false])
  }

  const stopAll = () => {
    if (microphone.current) {
      microphone.current.stop()
      microphone.current.close()
      microphone.current = null
    }
    if (videoTrackRef.current) {
      videoTrackRef.current.stop()
      videoTrackRef.current.close()
      videoTrackRef.current = null
    }
    if (agoraSendClientRef.current) {
      agoraSendClientRef.current.leave()
    }
  }

  const startLocalCamera = async () => {
    const ts = new Date().getTime();
    const channelName = String(ts).slice(0, 7)
    try {
      if (agoraSendClientRef.current) {
        await agoraSendClientRef.current.join(config.agoraAppID, channelName, null);
        videoTrackRef.current = await AgoraRTC.createCameraVideoTrack({
          encoderConfig: "1080p_1",
        })
        
        if (videoTrackRef.current) {
          profileStatus.current = 'success'
          videoTrackRef.current.play('testVideo')
        } else {
          profileStatus.current = 'error'
        }

        let message = profileStatus.current === 'success' ? 'Camera works well.' : 'Camera not works properly.'
        setTestSuites(oldTestSuites => {
          oldTestSuites.find(testSuite => testSuite.id === 3).notError = profileStatus.current === 'success'
          oldTestSuites.find(testSuite => testSuite.id === 3).message = message
          return oldTestSuites
        })
        if (profileStatus.current !== 'success') {
          setOpens(oldOpens => {
            const newOpens = [...oldOpens]
            newOpens[3] = !newOpens[3]
            return newOpens
          })
        }
      }
    } catch (e) {
      if (e.code === 'PERMISSION_DENIED') {
        setTestSuites(oldTestSuites => {
          oldTestSuites.find(testSuite => testSuite.id === 3).notError = false
          oldTestSuites.find(testSuite => testSuite.id === 3).message = `<p>Permission denied.<br />
${isSafari ? 'Go to Preferences > Websites > Camera > When visiting other websites: select \'Allow\' from the drop-down.' :
isChrome ? 'Go to Preferences > Privacy & Security > Site Settings > then select Camera and allow the website to have access to it.' : ''}</p>`
          return oldTestSuites
        })
        setOpens(oldOpens => {
          const newOpens = [...oldOpens]
          newOpens[3] = !newOpens[3]
          return newOpens
        })
        setTimeout(() => {
          stopLocalCamera()
        }, 1000)
      }
    }
  }

  const stopLocalCamera = () => {
    if (videoTrackRef.current) {
      videoTrackRef.current.stop()
      videoTrackRef.current.close()
      videoTrackRef.current = null
    }
    if (agoraSendClientRef.current) {
      agoraSendClientRef.current.leave()
    }
    setFinishTest(true)
  }

  return {
    activeStep,
    finishTest,
    testSuites,
    startTest,
    volume,
    checkSpeaker,
    stopLocalCamera,
    profileStatus,
    stopAll
  };
}
