import { Button, CircularProgress } from '@material-ui/core'
import React, {  } from 'react'

const ContinueButton = (props) => {
  const { disabled, loading, onClick, text, specialClass } = props

  return (
    <div className={`continue_button ${specialClass ?? ''}`}>
      <div>
        <Button disabled={disabled} onClick={onClick} color="primary" variant="contained">
          {loading ?<CircularProgress size={20} /> : text}
        </Button>
      </div>
    </div>
  )
}

export default ContinueButton;
