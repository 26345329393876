import React, { useEffect, useState } from 'react'
import './styles.css'
import firebase from 'firebase/app'
import { CircularProgress } from '@material-ui/core';
import ContinueButton from '../ConsultantProfile/ConsultantBooking/components/ContinueButton';
import { AUTH_ROUTES_CONSULTANT, COMMON_ROUTES } from '../../utils/routes';
import useCookieTheme from '../App/useCookieTheme';
import ConfirmAlert from '../components/ConfirmAlert';
import { useGlobalStyles } from '../App/globalStyles';
import BookingCard from '../Home/components/bookingCard/BookingCard';
import { useDispatch, useSelector } from 'react-redux';
import { userState } from '../../api/userSlice';
import { fetchTheme } from '../../api/themeSlice';
import { useHistory } from 'react-router-dom';

const GuestCall = (props) => {
  const { bookingID } = props.match.params
  const userID = useSelector(userState).user?.userID
  const { logo, bookingLogo, appointmentName, changePage } = useCookieTheme()
  const dispatch = useDispatch()
  const history = useHistory()

  const [bookingData, setBookingData] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(null)
  const [isStarted, setIsStarted] = useState(false);
  const [consultantID, setConsultantID] = useState(false);
  const [alertInfo, setAlertInfo] = useState({ open: false })

  useEffect(() => {
    const asyncSetTheme = async () => {
      await dispatch(fetchTheme({
        newThemeID: bookingData.themeID,
        history
      })).unwrap()
    }

    if (bookingData) {
      asyncSetTheme()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingData])

  useEffect(() => {
    if (userID) {
      changePage({
        pathname: AUTH_ROUTES_CONSULTANT.HOME
      })
    } else {
      firebase.app().firestore().doc(`/bookings/${bookingID}`).onSnapshot(res => {
        setIsStarted(false)

        if (res.data()?.status === 'cancelled' || res.data()?.status === 'declined') {
          setError(`This ${appointmentName ?? 'appointment'} was ${res.data()?.status === 'cancelled' ? 'cancelled' : 'declined'}!`)
          setBookingData(null)
          setLoaded(true)
          setConsultantID(res.data().consultantID)
          return
        }

        const startTime = res.data()?.startDate.toDate()
        const endTime = res.data()?.startDate?.toDate()
        if (endTime) {
          endTime.setMinutes(endTime.getMinutes() + res.data()?.length)
        }
        if (!res.exists) {
          setError(`You cannot access this ${appointmentName ?? 'appointment'}!`)
        } else if (endTime.getTime() < new Date().getTime()) {
          setConsultantID(res.data().consultantID)
          setError(`Your ${appointmentName ?? 'appointment'} time has expired!`)
        } else {
          setBookingData(res.data())
        }

        if (startTime) {
          const startInterval = setInterval(() => {
            if (startTime.getTime() < new Date().getTime()) {
              setIsStarted(true)
              clearInterval(startInterval)
            }
          }, 1000)
        }

        if (endTime) {
          const endInterval = setInterval(() => {
            if (endTime.getTime() < new Date().getTime()) {
              setBookingData(null)
              setConsultantID(res.data().consultantID)
              setError(`Your ${appointmentName ?? 'appointment'} time has expired!`)
              clearInterval(endInterval)
            }
          }, 1000)
        }

        setLoaded(true)
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingID, userID])

  useGlobalStyles()

  return (
    <div className="guest_call_container">
      {bookingData && loaded ?
          <div className='booking_container'>
            <div className='logo_container'>
              <img
                className='logo'
                alt=""
                src={bookingLogo ?? logo}
              />
            </div>
            <p>Here is you {appointmentName ?? 'appointment'} information:</p>
            <BookingCard
              variant="home"
              isClient={true}
              data={bookingData}
              setAllBookingsLoaded={setLoaded}
              isCurrentBooking={isStarted}
              setAlertInfo={setAlertInfo}
            />
          </div>
      :
        <>
          {error && loaded ? 
            <div className='error_container'>
              <div className='logo_container'>
                <img
                  className='logo'
                  alt=""
                  src={bookingLogo ?? logo}
                />
              </div>
              <p className='error'>{error}</p>
              {consultantID &&
                <ContinueButton
                  onClick={() => {
                    changePage({
                      pathname: COMMON_ROUTES.CONSULTANT_BOOKING.replace(':consultant', consultantID)
                    })
                  }}
                  text={`BOOK ANOTHER ${appointmentName ? appointmentName.toUpperCase() : 'APPOINTMENT'}`}
                  specialClass='book_another_reading_button'
                />
              }
            </div>
          : 
              <div className='loading_container'>
                <CircularProgress size={50} />
              </div>
          }
        </>
      }

      {alertInfo.open && <ConfirmAlert data={alertInfo} />}
    </div>
  )
}

export default GuestCall;