import React, { useEffect } from "react";
import useCookieTheme from "./useCookieTheme";
import { useRef } from "react";
import { Button } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear'
import firebase from 'firebase/app'
import { isAndroid } from "react-device-detect";
import { useSelector } from "react-redux";
import { themeState } from "../../api/themeSlice";

const Banner = (props) => {
  const { setBannerOn } = props
  const themeID = useSelector(themeState).theme?.id
  const { name, universalLink } = useCookieTheme()

  const shouldOpenApp = useRef(true)

  useEffect(() => {
    window.addEventListener('focus', () => {
      shouldOpenApp.current = true
    })
    window.addEventListener('blur', () => {
      shouldOpenApp.current = false
    })
  }, [])

  const handleSwitchToApp = async () => {
    if (isAndroid) {
      if (window.location.pathname.includes('/consultant/')) {
        const universalLinkConsultant = await firebase.functions().httpsCallable("getUniversalLink")({
          themeID: themeID,
          consultantID: window.location.pathname.split('/')[2]
        })
        window.open(universalLinkConsultant.data)
      } else {
        window.open(universalLink)
      }
    } else {
      window.open(window.location.pathname.includes('/consultant/') ? `${universalLink}?consultantID=${window.location.pathname.split('/')[2]}` : universalLink)
    }
  }

  return (
    <div className='banner'>
      <p>Get more out of {name} with our app</p>
      <div className='actions_banner'>
        <Button
          size='small'
          color="primary"
          onClick={handleSwitchToApp}
          variant="contained"
        >
          Switch to app
        </Button>
        <ClearIcon onClick={() => {
          setBannerOn(false)
        }} />
      </div>
    </div>
  );
}

export default Banner;
