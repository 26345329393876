import React, { useState } from 'react'
import { useGlobalStyles } from '../App/globalStyles'
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Tab } from '@material-ui/core';
const TestWidget = () => {
  const [value, setValue] = useState('2');

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  useGlobalStyles()

  return (
    <TabContext value={value}>
      <TabList
        onChange={handleChange}
        textColor='primary'
        indicatorColor='primary'
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Consultant" value="1" />
        <Tab label="Team" value="2" />
      </TabList>
      <TabPanel style={{ width: '100%', padding: 0 }} value="1">
        <div>
          <iframe
            src="https://loki-web.web.app/Cristi.Pele..Yo/AVYPK?integration=AVYPK"
            title='Loki integration'
            id="integration_loki_consultant"
            allow="camera;microphone"
            style={{ visibility: "hidden", width: "100%", minHeight: 600, border: 'none' }}
            onLoad={() => {
              // the iframe won't show until the load is complete, so here you can put a loading or something to finish
              const integrationIframe = document.getElementById('integration_loki_consultant')
              if (integrationIframe) {
                integrationIframe.style.visibility = 'visible';
                
                window.addEventListener('message', function (event) {
                  if (event.origin === 'https://loki-web.web.app') {
                    if (event.data.height && event.data.height > window.innerHeight) {
                      const height = event.data.height;
                      integrationIframe.style.height = height + 'px';
                    } else {
                      integrationIframe.style.height = window.innerHeight + 'px';
                    }
                    if (event.data.width !== undefined) {
                      const width = event.data.width;
                      integrationIframe.style.width = width + '%';
                      integrationIframe.style.margin = '0 calc((100% - ' + width + '%) / 2)';
                    }
                  }
                });

                window.addEventListener('load', function () {
                  integrationIframe.src = document.getElementById("integration_loki_consultant").src + '&rand=' + Math.round(Math.random() * 10000000);
                })

                const wind = integrationIframe.contentWindow
                if (wind) {
                  setInterval(() => {
                    wind.postMessage({
                      scrollHeight: window.scrollY,
                      maxHeight: window.innerHeight
                    }, "*");
                  }, 500)
                }
              }
            }}
          />
        </div>
      </TabPanel>
      <TabPanel style={{ width: '100%', padding: 0 }} value="2">
        <div>
          <iframe
            src="https://loki-web.web.app/team/Ancu Edited Radu's Team/hNpH?integration=hNpH"
            title='Loki integration'
            id="integration_loki_team"
            allow="camera;microphone"
            style={{ visibility: "hidden", width: "100%", minHeight: 600, border: 'none' }}
            onLoad={() => {
              // the iframe won't show until the load is complete, so here you can put a loading or something to finish
              const integrationIframe = document.getElementById('integration_loki_team')
              if (integrationIframe) {
                integrationIframe.style.visibility = 'visible';
                
                window.addEventListener('message', function (event) {
                  if (event.origin === 'https://loki-web.web.app') {
                    if (event.data.height && event.data.height > window.innerHeight) {
                      const height = event.data.height;
                      integrationIframe.style.height = height + 'px';
                    } else {
                      integrationIframe.style.height = window.innerHeight + 'px';
                    }
                    if (event.data.width !== undefined) {
                      const width = event.data.width;
                      integrationIframe.style.width = width + '%';
                      integrationIframe.style.margin = '0 calc((100% - ' + width + '%) / 2)';
                    }
                  }
                });

                window.addEventListener('load', function () {
                  integrationIframe.src = document.getElementById("integration_loki_team").src + '&rand=' + Math.round(Math.random() * 10000000);
                })

                const wind = integrationIframe.contentWindow
                if (wind) {
                  setInterval(() => {
                    wind.postMessage({
                      scrollHeight: window.scrollY,
                      maxHeight: window.innerHeight
                    }, "*");
                  }, 500)
                }
              }
            }}
          />
        </div>
      </TabPanel>
    </TabContext>
  )
}

export default TestWidget;