export const notSameDataMessages = (autoNotifyTeamMaster, name) => {
  return autoNotifyTeamMaster ? 
  `Your account is registered but you can't access the content of this app until you become a ${name} team member. We've notified ${name} team master in order to send you an invitation. In the meantime please check your inbox to verify your email address.` :
  `Your account is registered but you can't access the content of this app until you become a ${name} team member. Please contact ${name} team master in order to receive the team invitation. In the meantime please check your inbox to verify your email address.`
}

export const getDocs = (docsLocation) => {
  return docsLocation ?? "/docs/"
}

export const getURLs = (iosAppStore, androidPackage) => {
  return {
    appleURL: iosAppStore ? `https://apps.apple.com/us/app/loki-payperminute-calls/id${iosAppStore}` : `https://apps.apple.com/us/app/loki-payperminute-calls/id1501207011`,
    googleURL: androidPackage ? `https://play.google.com/store/apps/details?id=${androidPackage}` : `https://play.google.com/store/apps/details?id=com.adonis.loki`
  }
}
