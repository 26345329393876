import React, {  } from 'react';
import TableCell from '@material-ui/core/TableCell';
import { TableRow } from '@material-ui/core';
import useCookieTheme from '../../../App/useCookieTheme';
import TeamTable from './TeamTable';
import TeamDiscountCouponsRow from './TeamDiscountCouponsRow';

const TeamDiscountCouponsTable = (props) => {
  const { dataSource, handleEdit, handleDelete } = props
  const { consultantTitle } = useCookieTheme()

  const tableCells = 
      <TableRow>
        <TableCell align="left">Name</TableCell>
        <TableCell align="left">Percent</TableCell>
        <TableCell align="left">Expiry date</TableCell>
        <TableCell align="left">{consultantTitle}s</TableCell>
        <TableCell align="left">Cut option</TableCell>
        <TableCell align="left" className='header_actions_discount_coupons'>Actions</TableCell>
      </TableRow>

  const tableRows = 
      <>
        {dataSource.map((row) => (
          <TeamDiscountCouponsRow key={row.id} data={row} handleEdit={handleEdit} handleDelete={handleDelete} />
        ))}
      </>

  return (
    <TeamTable tableCells={tableCells} tableRows={tableRows} classTable='discount_coupons_table' />
  )
};

export default TeamDiscountCouponsTable;
