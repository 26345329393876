import React, {  } from 'react';
import DiscountCoupons from '../../../MyProfile/components/DiscountCoupons';
import { useTeamContext } from '../teamContext';
import { userState } from '../../../../api/userSlice';
import { useSelector } from 'react-redux';

const DiscountCouponsTab = (props) => {
  const masterID = useSelector(userState).user?.userID
  const { consultants, setSeverity } = useTeamContext()

  return (
    <DiscountCoupons
      masterID={masterID}
      setSeverity={setSeverity}
      memberList={consultants.map(consultant => {
        return {
          id: consultant.consultantID,
          name: consultant.name
        }
      })}
    />
  )
};

export default DiscountCouponsTab;
