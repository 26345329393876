import React from 'react';
import './styles.css';
import ConsultantsList from '../../../ConsultantsList';

const TeamConsultantsList = (props) => {
  const teamID = props.match.params["teamID"]

  return (
    <ConsultantsList teamID={teamID} />
  )
};

export default TeamConsultantsList;
