import React, { useEffect, useState } from 'react';
import 'firebase/firestore'
import moment from 'moment';
import InfoPopover from '../../../components/InfoPopover';

const TimezoneInfoDialog = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [timezoneOffset, setTimezoneOffset] = useState(null);
  const open = Boolean(anchorEl)

  useEffect(() => {
    const offset = moment().utcOffset() / 60
    setTimezoneOffset(offset)
  }, [timezoneOffset])

  const handleInfo = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className='timezone_info'>
      <InfoPopover
        open={open}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleInfo={handleInfo}
      >
        <div className='timezone_info_content'>
          This schedule is displayed in your timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone}
          (UTC{timezoneOffset < 0 ? timezoneOffset : `+${timezoneOffset}`}).
        </div>
      </InfoPopover>
    </div>
  )
};

export default TimezoneInfoDialog;
