import React, {  } from 'react';
import { CircularProgress } from '@material-ui/core';
import TeamDiscountTable from '../Table/TeamDiscountTable';

const BookedHoursDiscountTab = (props) => {
  const { reportDiscountData } = props

  return (
    <>
      {reportDiscountData ?
        <>
          <div className='team_availability_title heading'>Booked with 10% Discount (starting with 09/01/2023)</div>
          <div className='availability_table'>
            <TeamDiscountTable dataSource={reportDiscountData?.filter(data => data.id !== 'percent_of_hours_booked')} />
          </div>
        </>
      :
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
          <CircularProgress size={50} />
        </div>
      }
    </>
  )
};

export default BookedHoursDiscountTab;
