export const config = {
  type: 'astrologer',
  webSite: 'https://astrologerconnect.com',
  apiKey: "AIzaSyC39luPNLVosJH4NTHNnUzzF1IaOx1IsOo",
  authDomain: "astrologer-ea0ff.firebaseapp.com",
  cloudFunctionsURL: "https://us-central1-astrologer-ea0ff.cloudfunctions.net/",
  projectId: "astrologer-ea0ff",
  storageBucket: "astrologer-ea0ff.appspot.com",
  messagingSenderId: "171824249818",
  appId: "1:171824249818:web:961af1e83538644ae81ee3",
  vapidKey: "BJLeQbrbXVBcHKUIxZtHccZqWcCakfqU3IpZVfCT-EcsEYwb3MiwklZPVSQdmYmHEda4wsUVBJyVoUr69XiaGKs",
  stripeKey: "pk_live_51KymPbDWTIANMhINTywNsTeD3tpPCAYlRGvq1WRXo2Ryz5fJ8Om25dBERbXZd7sIE2s0tYfVBpSRrmHZKNMXHuJS00V1nnfOki",
  agoraAppID: "b15e3a556d1f4346bdb0137fbab76524",
  title: "Astrologer Connect"
}

export const defaultTheme = {
  id: 'astrologerTheme',
  mainColor: '#FFB72B',
  backgroundColor: '#f3f9fb',
  appName: 'Astrologer Connect',
  appConsultantProfileTitle: 'Astrology Hub\'s Astrologer Connect',
  appConsultantProfileDescription: 'The Premier Source for Quality Online Astrology Readings',
  consultantTitle: 'astrologer',
  employeeTitle: 'astrologer',
  giftButtonName: 'Gift A Reading',
  menu: {
    dashboard: "Get Started &<br />Upcoming Readings",
    history: "Past Readings",
    profile: "My Profile",
    consultantsList: "Astrologers"
  },
  logo: 'https://firebasestorage.googleapis.com/v0/b/astrologer-ea0ff.appspot.com/o/logos%2Fastrologer.png?alt=media&token=029f2394-f033-4947-980a-8b1251f54ca3',
  availabilityHeading: 'Availability for Booked Readings',
  makeAppointmentButtonName: 'Book A Reading',
  appointmentName: 'reading',
  bookingEnableName: 'booking',
  bookingLogo: 'https://firebasestorage.googleapis.com/v0/b/astrologer-ea0ff.appspot.com/o/logos%2Fastrologer_connect_logo.png?alt=media&token=fdc4a673-abf2-4ce2-b52f-273ba3f9fda1',
  domainStart: "https://astrologerconnectlive.page.link"
}

export const teamMasters = "ana@astrologyhub.com"
