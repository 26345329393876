export default function useBookingHook() {
  const getBooking = () => {
    const booking = localStorage.getItem('booking')
    return booking ? JSON.parse(booking) : null
  }

  const setBooking = (data) => {
    localStorage.setItem('booking', JSON.stringify(data))
  }

  const removeBooking = () => {
    localStorage.removeItem('booking')
  }

  return {
    getBooking,
    setBooking,
    removeBooking
  };
}
