import React, { useEffect, useState } from 'react'
import "./profile.css";
import { Button, IconButton, Snackbar, CircularProgress, useMediaQuery } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import firebase from 'firebase/app'
import { ImagePicker } from 'react-file-picker'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import SwitchItem from '../components/SwitchIOS';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useGlobalStyles } from '../App/globalStyles';
import { config } from '../../config/config';
import { AUTH_ROUTES_CONSULTANT, COMMON_ROUTES } from '../../utils/routes';
import useCookieTheme from '../App/useCookieTheme';
import InfoPopover from '../components/InfoPopover';
import { HashLink } from 'react-router-hash-link';
import ProfileTabs from './ProfileTabs';
import { useProfileContext } from './profileContext';
import { useDispatch, useSelector } from 'react-redux';
import { userState, getProfilePic } from '../../api/userSlice';

const ProfilePage = (props) => {
    const consultantID = props.match.params.id
    const closePopup = props.location.closePopup
    const hashFromURL = (new URL(document.location)).searchParams.get("section");
    const setPerMin = JSON.parse((new URL(document.location)).searchParams.get("setPerMin") ?? null)
    const setBookings = JSON.parse((new URL(document.location)).searchParams.get("setBookings") ?? null)
    const dispatch = useDispatch()
    const userID = useSelector(userState).user?.userID
    const profilePic = useSelector(userState).profilePic
    const {
        user,
        loading,
        setUserID,
        isPublicProfile,
        isGratuityPeriod,
        setErrorMessage,
        setSeverity,
        open,
        anchorEl,
        anchorSet,
        errorMessage,
        severity,
        handleInfo
    } = useProfileContext()
    const showBackButton = userID !== consultantID
    setUserID(consultantID)
    const { changePage } = useCookieTheme()

    const [consultantProfilePic, setConsultantProfilePic] = useState(consultantID === userID ? profilePic : null)
    const [isUploadingProfilePic, setIsUploadingProfilePic] = useState(false)
    const [notSetPerMin, setNotSetPerMin] = useState(setPerMin)
    const [notSetBookings, setNotSetBookings] = useState(setBookings)
    
    const widthLessThan650px = useMediaQuery('(max-width:649px)');

    useEffect(() => {
      if (closePopup) {
          closePopup()
      }
    }, [closePopup])

    useEffect(() => {
        if (hashFromURL) {
          const hashDocument = document.getElementById('with_hash_from_url')
          if (hashDocument) {
              hashDocument.click()
          }
        }
    }, [hashFromURL, loading])

    useEffect(() => {
        if(notSetPerMin) {
            setErrorMessage(`You need to have at least one valid price${config.type === 'yaloop' ? ' or free calls available' : ''}!`)
            setSeverity('warning')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notSetPerMin])

    useEffect(() => {
        if(notSetBookings) {
            setErrorMessage("You need to set booking prices first!")
            setSeverity('warning')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notSetBookings])

    useEffect(() => {
        if (userID === consultantID) {
            return
        }

        // Get the profile pic, that one's easy
        firebase.app().storage().ref(`images/${consultantID}.jpg`).getDownloadURL().then(res => {
            setConsultantProfilePic(res)
        }).catch(_ => {
            console.log('no profile pic')
            setConsultantProfilePic(null)
        })
    }, [consultantID, userID])

    const togglePublicChecked = (checked) => {
        firebase.firestore().doc(`/users/${user.userID}`).update({ isPublic: checked })
    }

    const toggleGratuityPeriodChecked = (checked) => {
        firebase.firestore().doc(`/users/${user.userID}`).update({ gratuityPeriod: checked ? 1 : 0 })
    }

    const changeProfilePic = (base64) => {
        setIsUploadingProfilePic(true)
        console.log("Got so far!")
        if (!user) {
            return
        }
        firebase.app().functions().httpsCallable("updateUserProfile")({
            updateData: base64,
            targetUserID: user.userID,
            isProfilePic: true
        }).then(() => {
            console.log("Success updating!")
            firebase.app().storage().ref(`images/${user.userID}.jpg`).getDownloadURL().then(async res => {
                if (consultantID === userID) {
                    await dispatch(getProfilePic()).unwrap()
                }
                setConsultantProfilePic(res)
                setIsUploadingProfilePic(false)
            }).catch((error) => {
                setErrorMessage(error.message)
                setSeverity('error')
                setIsUploadingProfilePic(false)
            })
        }).catch((error) => {
            setErrorMessage(error.message)
            setSeverity('error')
            setIsUploadingProfilePic(false)
        })
    }

    useGlobalStyles()

    const themeMainColor = useTheme().palette.primary.main
  
    const useStyles = makeStyles({
        price_p: {
            color: `${themeMainColor} !important`,
        },
        change_pass_button: {
            color: themeMainColor
        },
        backText: {
            color: themeMainColor
        }
    })
    const styleClasses = useStyles();

    if (loading) {
        return (
            <div className='loading_container_single'>
                <CircularProgress size={50} />
            </div>
        );
    }

    return (
        <div className="profile">
            {showBackButton && widthLessThan650px &&
                <div className={`back_text ${styleClasses.backText}`} onClick={() => {
                    changePage({
                        pathname: AUTH_ROUTES_CONSULTANT.TEAM,
                    })
                }}>
                    Back to Team
                </div>
            }
            <div className='header'>
                <div className='left_header'>
                    {showBackButton && !widthLessThan650px &&
                        <IconButton className="back_button" onClick={() => {
                                changePage({
                                    pathname: AUTH_ROUTES_CONSULTANT.TEAM,
                                })
                            }} aria-label="delete">
                            <KeyboardArrowLeftIcon fontSize="medium" />
                        </IconButton>
                    }
                    {isUploadingProfilePic ? 
                        <div className='upload_pic_loading'>
                            <CircularProgress size={50} />
                        </div>
                    :
                        <ImagePicker
                            extensions={['jpg', 'jpeg', 'png']}
                            dims={{ minWidth: 10, maxWidth: 1000, minHeight: 10, maxHeight: 1000 }}
                            onChange={base64 => {
                                setErrorMessage(null)
                                console.log("At least onCHange called?")
                                changeProfilePic(base64)
                            }}
                            onError={() => {
                                setErrorMessage("Error uploading your picture. It must be max 1000x1000!")
                                setSeverity('error')
                            }}
                        >
                            <Button>
                                <img className='profile_pic' alt="" src={consultantProfilePic ?? require("../../images/user.png")} />
                            </Button>
                        </ImagePicker>
                    }
                    <div className='names'>
                        <div className="profile_name heading">
                            {user["name"]} {user["surname"]}
                        </div>
                        <div className="profile_expertise">
                            {user["consultancyType"]}
                        </div>
                    </div>
                </div>
                <div className='right_header'>
                    <div>
                        <span className='right_header_label'>Public Profile</span>
                        <SwitchItem checked={isPublicProfile} set={togglePublicChecked} />
                        <InfoPopover
                            open={open.isPublicProfile}
                            anchorEl={anchorEl.isPublicProfile}
                            setAnchorEl={(value) => anchorSet(value, 'isPublicProfile')}
                            handleInfo={(e) => handleInfo(e, (value) => anchorSet(value, 'isPublicProfile'))}
                            buttonClass='availability_info_button'
                        >
                            <div className='timezone_info_content'>
                                When on, your profile will be listed among the consultants in both the apps and web. When it's off, clients can only access your services through your profile link.
                            </div>
                        </InfoPopover>
                    </div>
                    <div>
                        <span className='right_header_label'>{config.type !== 'astrologer' && '1 '}Minute{config.type === 'astrologer' && 's'} Free?</span>
                        <SwitchItem checked={isGratuityPeriod} set={toggleGratuityPeriodChecked} />
                        <InfoPopover
                            open={open.isGratuityPeriod}
                            anchorEl={anchorEl.isGratuityPeriod}
                            setAnchorEl={(value) => anchorSet(value, 'isGratuityPeriod')}
                            handleInfo={(e) => handleInfo(e, (value) => anchorSet(value, 'isGratuityPeriod'))}
                            buttonClass='availability_info_button'
                        >
                            <div className='timezone_info_content'>
                                When on, you offer the first minute for free in each instant call.
                            </div>
                        </InfoPopover>
                    </div>
                </div>
            </div>
            <div className='preview_profile'>
                <Button 
                    className='view_profile_button'
                    onClick={() => {
                        changePage({
                            pathname: COMMON_ROUTES.CONSULTANT.replace(':consultant', user.userID),
                            search: 'fromProfile=true'
                        })
                    }}
                >
                    Preview Profile (how clients see your profile page)
                </Button>
            </div>

            <ProfileTabs setNotSetPerMin={setNotSetPerMin} setNotSetBookings={setNotSetBookings} />
            
            {errorMessage &&
                <Snackbar
                    open={Boolean(errorMessage)}
                    autoHideDuration={10000}
                    onClose={() => {
                        if (!hashFromURL) {
                            setNotSetPerMin(false)
                            setNotSetBookings(false)
                            setErrorMessage(null)
                        }
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={() => {
                        if (!hashFromURL) {
                            setNotSetPerMin(false)
                            setNotSetBookings(false)
                            setErrorMessage(null)
                        }
                    }} severity={severity}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            }

            <HashLink
              id='with_hash_from_url'
              to={{
                pathname: AUTH_ROUTES_CONSULTANT.PROFILE.replace(':id', user.userID),
                hash: `#${hashFromURL}`,
                setPerMin: notSetPerMin,
                setBookings: notSetBookings
              }}
              style={{ display: 'none' }}
            />
        </div>
    )
}

export default ProfilePage;
