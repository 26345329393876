import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app'
import BookingCard from '../Home/components/bookingCard/BookingCard';
import { CircularProgress } from '@material-ui/core';
import { calculateFakePrice, calculateRealPrice, isFullyPaid, updateSessionLog, uuidv4 } from '../../utils/utils';
import UnpaidSessionDialog from '../Home/components/bookingCard/UnpaidSessionDialog';
import { config } from '../../config/config';
import { AUTH_ROUTES_CLIENT, COMMON_ROUTES } from '../../utils/routes';
import moment from 'moment';
import ConfirmAlert from '../components/ConfirmAlert';
import { beforeTestMessage } from '../../utils/constants';
import useCookieTheme from '../App/useCookieTheme';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorDetails, userState } from '../../api/userSlice';
import { setActiveSession } from '../../api/sessionSlice';

const History = () => {
  const dbUser = useSelector(userState).user
  const userID = useSelector(userState).user?.userID
  const isConsultant = useSelector(userState).user?.consultant
  const { menuHistory, changePage } = useCookieTheme()
  const dispatch = useDispatch()

  const [historyCalls, setHistoryCalls] = useState([]);
  const [allLoaded, setAllLoaded] = useState(false);
  const [loadingCall, setLoadingCall] = useState(false);
  const [unpaidSession, setUnpaidSession] = useState(false);
  const [alertInfo, setAlertInfo] = useState({ open: false })
  let currentSession = null

  useEffect(() => {
    const getHistoryAsync = async () => {
      const currDate = new Date()
      const toRetBookings = await firebase
        .firestore()
        .collection("/bookings")
        .where("status", "in", ["accepted", "declined"])
        .where(isConsultant ? "consultantID" : "clientID", "==", userID)
        .where("startDate", "<", currDate)
        .orderBy("startDate", "desc")
        .get()
      const allDocsBookings = toRetBookings.docs;
      const allDatasBookings = allDocsBookings.map((each) => {
        return {
          ...each.data(),
          id: each.id
        }
      });
      console.log(`Loaded ${allDatasBookings.length} bookings.`);

      let historyBookings = []
      if (allDatasBookings.length > 0) {
        historyBookings = allDatasBookings.filter((each) => moment(each.startDate.toDate()).add(each.length, 'minutes').toDate().getTime() < currDate.getTime())
      }
      
      const toRetSessions = await firebase
        .firestore()
        .collection("/sessions")
        .where("status", "in", ["finished", "rejected"])
        .where(isConsultant ? "consultantID" : "clientID", "==", userID)
        .get()
      const allDocs = toRetSessions.docs;
      const allDatas = allDocs.map((each) => {
        return {
          ...each.data(),
          id: each.id
        }
      });
      console.log(`Loaded ${allDatas.length} sessions.`);

      let historySessions = []
      if (allDatas.length > 0) {
        const callsHistorySnap = allDatas.filter((callHistory) => !callHistory.bookingID)
        const callsWithConsultantData = await Promise.all(
          callsHistorySnap.map(async (call) => {
            const consultantData = (await firebase.firestore().doc(`/users/${call.consultantID}`).get()).data()
            if (!consultantData) {
              return null
            }
            if (call.isChatCall) {
              call.chatAvailable = consultantData.online && consultantData.chatPrice > 0
              call.priceSession = consultantData.chatPrice
            } else if (call.videoEnabled) {
              call.videoAvailable = consultantData.online && consultantData.videoPrice > 0
              call.priceSession = consultantData.videoPrice
            } else {
              call.audioAvailable = consultantData.online && consultantData.audioPrice > 0
              call.priceSession = consultantData.audioPrice
            }
            return {
              ...call,
              startDate: call.dateCreated
            }
          })
        )
        historySessions = callsWithConsultantData.filter(each => each !== null);
      }

      if (config.type !== 'astrologer' && isConsultant && historyBookings.length) {
        const bookingSessions = allDatas.filter(callHistory => callHistory.status === 'finished').map((callHistory) => callHistory.bookingID)
        const newBookingHistory = historyBookings.filter(bookingHistory =>  bookingSessions.includes(bookingHistory.bookingID) || bookingHistory.status === 'declined')
        historyBookings = newBookingHistory
      }
      
      setHistoryCalls(historySessions.concat(historyBookings))
      setAllLoaded(true)
    }

    getHistoryAsync()
  }, [isConsultant, userID])

  const tappedAudioCall = (session) => {
    currentSession = session
    checkIfTestNeeded(false)
  }

  const tappedVideoCall = (session) => {
    currentSession = session
    checkIfTestNeeded(true)
  }

  const tappedChatCall = (session) => {
    currentSession = session
    checkIfTestNeeded(false, true)
  }

  const checkIfTestNeeded = (videoEnabled, isChatCall) => {
    if (isChatCall) {
      startCall(videoEnabled, isChatCall)
    } else {
      setAlertInfo({
        open: true, 
        title: "",
        description: beforeTestMessage(videoEnabled),
        okTitle: 'Test',
        cancelTitle: 'Skip',
        onConfirm: () => {
          setAlertInfo({ open: false })
          changePage({
            pathname: COMMON_ROUTES.TEST,
            search: `videoEnabled=${videoEnabled}`,
            startCall: () => { startCall(videoEnabled, isChatCall) }
          })
        },
        onCancel: () => {
          setAlertInfo({ open: false })
          startCall(videoEnabled, isChatCall)
        },
        onClose: () => {
          setAlertInfo({ open: false })
        }
      })
    }
  }

  const startCall = (videoEnabled, isChatCall) => {
    if (!dbUser?.stripeDefaultPMHint) {
      changePage({
        pathname: AUTH_ROUTES_CLIENT.PAYMENT,
        search: 'fromCallHistory=true'
      })
      return
    }

    setLoadingCall(true)

    startCallAsync(videoEnabled, isChatCall)
  }

  const startCallAsync = async (videoEnabled, isChatCall) => {
    const lastSession = await firebase.app().firestore().collection('sessions').where('clientID', '==', userID).where("status", "==", "finished").orderBy('dateCreated', 'desc').limit(1).get()

    if (lastSession.docs.length > 0) { // We got a session
      const lastSesh = lastSession.docs[0]
      const lastSeshData = lastSesh.data()

      if (!isFullyPaid(lastSeshData)) {
        setLoadingCall(false)
        setUnpaidSession(lastSesh)
        return
      }
    }

    const { locale, corporate, consultantID } = currentSession
    const { name, surname } = dbUser
    const fullName = `${name} ${surname}`

    const createNewSessionParams = {
      consultantID: consultantID,
      callUUID: uuidv4(),
      clientFullName: fullName,
      clientName: name,
      videoEnabled: videoEnabled,
      isChatCall: isChatCall,
      locale: locale,
      corporate: corporate,
      firstChargeFromWallet: false,
      clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    if (userID) {
      createNewSessionParams.clientID = userID
    }

    try {
      const result = await firebase.app().functions("europe-west1").httpsCallable('createNewSession')(createNewSessionParams)
      const newSessionID = result.data.sessionID
      const newSession = await firebase.firestore().doc(`/sessions/${newSessionID}`).get()

      updateSessionLog(newSessionID, `[SESSION - ${newSessionID}] ${new Date()} - Starting ${isChatCall ? 'text chat' : videoEnabled ? 'video' : 'audio'} call by client ${userID}`)

      dispatch(setActiveSession(JSON.stringify({
        ...newSession.data(),
        id: newSession.id
      })))
      setLoadingCall(false)
      changePage({
        pathname: COMMON_ROUTES.ACTIVE_CALL,
        search: `isConsultant=false&from=${AUTH_ROUTES_CLIENT.HISTORY}`
      })
    } catch(_) {
      dispatch(setErrorDetails({
        message: "Consultant unavailable at the moment. Please try again!"
      }))
      setLoadingCall(false)
    }
  }

  const pendingAmount = unpaidSession ? (calculateFakePrice(unpaidSession.data()) - calculateRealPrice(unpaidSession.data())) : 0

  const build = (item, index) => {
    return (
      <BookingCard
        key={index}
        variant="home"
        isClient={!isConsultant}
        data={item}
        historyCall={Boolean(item?.callUUID)}
        historyBooking={!item?.callUUID}
        loadingCall={loadingCall}
        tappedAudioCall={tappedAudioCall}
        tappedVideoCall={tappedVideoCall}
        tappedChatCall={tappedChatCall}
      ></BookingCard>
    )
  }

  return (
    <div className="home_bookings">
      {allLoaded ? (
        <>
          <div className="label_bookings heading">
            {historyCalls.length === 0 && `No `}{menuHistory ?? 'History'}
          </div>
          {historyCalls.length > 0 &&
            <div className="bookings_content">
              {historyCalls.sort((a, b) => {
                return b.startDate.toDate() - a.startDate.toDate()
              }).map(build)}
            </div>
          }
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', marginTop: '200px' }}>
          <CircularProgress size={50} />
        </div>
      )}
      
      {unpaidSession &&
        <UnpaidSessionDialog
          unpaidSession={unpaidSession}
          setUnpaidSession={setUnpaidSession}
          pendingAmount={pendingAmount}
          locale={currentSession.locale}
        />
      }

      {alertInfo.open && <ConfirmAlert data={alertInfo} />}
    </div>
  )
};

export default History;
