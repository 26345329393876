/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import "./booking_card.css";
import 'firebase/firestore'
import Typography from '@material-ui/core/Typography'
import { formattedIntegerLocaleSeparator, localeToCurrency, updateSessionLog } from '../../../../utils/utils'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import Countdown from 'react-countdown';
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import { CloudDownload } from '@material-ui/icons'
import firebase from 'firebase/app'
import { ReactComponent as AudioIcon } from '../../../../images/audio.svg';
import { ReactComponent as VideoIcon } from '../../../../images/video.svg';
import { Button, Divider, useMediaQuery } from '@material-ui/core';
import { useBookingCardStyles } from '../../../App/globalStyles';
import BookingCardActions from './BookingCardActions';
import { config } from '../../../../config/config';
import IntakeInformation from '../../../ActiveCall/components/IntakeInformation';
import IntakeInformationDialog from './IntakeInformationDialog';
import ChatIcon from '@material-ui/icons/Chat'
import { AUTH_ROUTES_CLIENT, COMMON_ROUTES, NO_AUTH_ROUTES } from '../../../../utils/routes';
import ConfirmAlert from '../../../components/ConfirmAlert';
import { downloadRecording } from '../../../../utils/recordings';
import BookingChatIcon from '../../../ActiveCall/components/ChatIcon';
import { beforeTestMessage } from '../../../../utils/constants';
import useCookieTheme from '../../../App/useCookieTheme';
import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import { setActiveSession, setCurrentBookingID } from '../../../../api/sessionSlice';
import { setErrorDetails, userState } from '../../../../api/userSlice';

export const dataCalls = {
  video: {
    name: 'Video Call',
    icon: <VideoIcon />
  },
  audio: {
    name: 'Audio Call',
    icon: <AudioIcon />
  },
  chat: {
    name: 'Text Chat',
    icon: <ChatIcon className='chat_icon' />
  },
}

const BookingCard = (props) => {
  const historyBooking = props.historyBooking
  const historyCall = props.historyCall
  const isClient = props.isClient
  const variant = props.variant
  const isHome = variant === "home"
  const isNotifications = variant === "notifications"
  const fromSideBar = props.fromSideBar
  const setAllBookingsLoaded = props.setAllBookingsLoaded
  const setAlertInfo = props.setAlertInfo
  const loadingCall = props.loadingCall
  const tappedAudioCall = props.tappedAudioCall
  const tappedVideoCall = props.tappedVideoCall
  const tappedChatCall = props.tappedChatCall
  const isCurrentBooking = props.isCurrentBooking
  const setReloadBookings = props.setReloadBookings

  const dispatch = useDispatch()
  const dbUserID = useSelector(userState).user?.userID
  const currentBookingIDSet = useRef(false)
  const currentBookingIDSetNull = useRef(false)

  const each = {
    ...props.data,
    status: historyCall && props.data.status === 'finished' ? 'accepted' : props.data.status,
    successfullyCharged: isClient ? props.data.successfullyCharged : (props.data.teamMemberPercentage  ? props.data.successfullyCharged * props.data.teamMemberPercentage : props.data.successfullyCharged) * (!isClient ? (props.data.customConsultantPercentage ?? 1) : 1),
    estimatedPrice: !isClient ? (props.data.customConsultantPercentage ?? 1) * props.data.estimatedPrice : props.data.estimatedPrice,
    callType: props.data.callType ? props.data.callType : props.data.isChatCall ? 'chat' : props.data.videoEnabled ? 'video' : 'audio'
  }

  if (isClient) {
    if (each.salesTax) {
      each.estimatedPrice = each.estimatedPrice + each.salesTax
    }
    if (each.discountPaidByConsultant) {
      each.estimatedPrice = each.estimatedPrice - each.discountPaidByConsultant
    }
    if (each.discountPaidByTeam) {
      each.estimatedPrice = each.estimatedPrice - each.discountPaidByTeam
    }
  } else {
    if (each.salesTax) {
      const minutes = props.data.successfullyCharged / (props.data.price + props.data.salesTax)
      each.successfullyCharged = props.data.price * minutes * (props.data.teamMemberPercentage ?? 1) * (props.data.customConsultantPercentage ?? 1)
    }
    if (each.teamMemberPercentage) {
      each.estimatedPrice = each.estimatedPrice * each.teamMemberPercentage
    }
    if (each.discountPaidByConsultant) {
      each.estimatedPrice = each.estimatedPrice - each.discountPaidByConsultant
    }
  }

  const notes = each.notes && each.notes !== "" ? each.notes : "No meeting notes"
  const { appointmentName, changePage } = useCookieTheme()

  const [loading, setLoading] = useState(false);
  const [openIntakeInfos, setOpenIntakeInfos] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false);
  const [alertInfoState, setAlertInfoState] = useState({ open: false })
  
  let length = each.length
  if (historyCall && each.firstPingDate && each.lastPingDate) {
    length = moment(each.lastPingDate.toDate()).diff(moment(each.firstPingDate.toDate())) / 1000 / 60
  }

  const startDate = historyCall ? each.firstPingDate ? each.firstPingDate.toDate() : each.dateCreated.toDate() : each.startDate.toDate()
  const startMoment = moment(startDate)
  const endMoment = moment(startMoment).set('seconds', 0).add(length, 'minutes')
  const prettyStartDate = startMoment.format('MMMM Do YYYY')
  const prettyStartTime = startMoment.format('hh:mm')
  const ampmStartDate = startMoment.format('A')
  const endDate = endMoment.format('hh:mm')
  const ampmEndDate = endMoment.format('A')
  const displayRecording = (each.recordingEnabled || config.type === 'astrologer') && each.status === 'accepted' && startMoment.toDate().getTime() > moment().add(-30, 'days').toDate().getTime() && each.callType !== 'chat'

  const widthMoreThan725px = useMediaQuery('(min-width:724px)');
  const widthLessThan800px = useMediaQuery('(max-width:799px)');
  const widthLessThan650px = useMediaQuery('(max-width:649px)');
  const widthLessThan450px = useMediaQuery('(max-width:449px)');
  const orientationPortrait = useMediaQuery('(orientation: portrait)');

  useEffect(() => {
    if (isCurrentBooking && each.status === 'pending') {
    }
  }, [each.status, isCurrentBooking])

  useEffect(() => {
    const interval = setInterval(() => {
      const currentMoment = moment(new Date())
      const newIsCurrentBooking = currentMoment.isAfter(startMoment) && currentMoment.isBefore(endMoment)
      if (!historyCall && !historyBooking && isHome && isCurrentBooking !== newIsCurrentBooking) {
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [endMoment, historyBooking, historyCall, isCurrentBooking, isHome, startMoment]);
  
  const startBookingCall = async () => {
    const isChatCall = each.callType === "chat";
    const videoEnabled = each.callType === "video";
  
    console.log(`${each.clientID ?? each.clientEmail} calling ${each.consultantID}`);
  
    function uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          // eslint-disable-next-line no-mixed-operators
          const r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16).toUpperCase();
        }
      );
    }
    const callUUID = uuidv4();
  
    const createNewSessionParams = {
      consultantID: each.consultantID,
      callUUID: callUUID,
      clientFullName: each.clientFullName,
      clientName: each.clientName,
      videoEnabled: videoEnabled,
      isChatCall: isChatCall,
      locale: each.locale,
      corporate: each.corporate,
      firstChargeFromWallet: false,
      bookingID: each.bookingID,
      giftID: each.giftID,
      clientEmail: each.clientEmail,
      isGuestSession: Boolean(each.clientID),
      isFree: each.isFree,
      clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    if (dbUserID) {
      createNewSessionParams.clientID = dbUserID
    }
  
    console.log("Create new session:");
    console.log(createNewSessionParams);
  
    try {
      const result = await firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("createNewSession")(createNewSessionParams);
  
        const newSessionID = result.data.sessionID;
        const newSession = await firebase
          .firestore()
          .doc(`/sessions/${newSessionID}`)
          .get();
  
        updateSessionLog(newSessionID, `[SESSION - ${newSessionID}] ${new Date()} - Starting ${isChatCall ? 'text chat' : videoEnabled ? 'video' : 'audio'} call by ${each.clientID ? `client ${each.clientID}` : `guest ${each.clientEmail}`}`)
  
        dispatch(setActiveSession(JSON.stringify({
          ...newSession.data(),
          id: newSession.id
        })))

        const from = each.clientID ? AUTH_ROUTES_CLIENT.HOME : NO_AUTH_ROUTES.GUEST_CALL.replace(':bookingID', each.bookingID)
        changePage({
          pathname: COMMON_ROUTES.ACTIVE_CALL,
          search: `isConsultant=false&from=${from}`
        })
    } catch(e) {
      dispatch(setErrorDetails({
        message: "Consultant unavailable at the moment. Please try again!"
      }))
      console.log(e)
  
      return Promise.resolve();
    }
  };

  const callTapped = async () => {
    setLoading(true)
    try {
      if (each.callType === 'chat') {
        await startBookingCall()
      } else {
        setAlertInfoState({
          open: true, 
          title: "",
          description: beforeTestMessage(each.callType === 'video'),
          okTitle: 'Test',
          cancelTitle: 'Skip',
          onConfirm: () => {
            setAlertInfoState({ open: false })
            changePage({
              pathname: COMMON_ROUTES.TEST,
              search: `videoEnabled=${each.callType === 'video'}`,
              startCall: async () => {
                await startBookingCall()
              }
            })
          },
          onCancel: async () => {
            setAlertInfoState({ open: false })
            await startBookingCall()
          },
          onClose: () => {
            setAlertInfoState({ open: false })
            setLoading(false)
          }
        })
      }
    } catch {
      setLoading(false)
    }
  }

  const countRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      if (!currentBookingIDSet.current) {
        dispatch(setCurrentBookingID(each?.bookingID))
        currentBookingIDSet.current = true
      }
      return <span />;
    } else {
      // Render a countdown
      if (days) {
        return <span>{days}d {hours}h {minutes}m {seconds}s</span>;
      } else {
        return <span>{hours}h {minutes}m {seconds}s</span>;
      }
    }
  };

  const countRendererEnd = ({ completed }) => {
    if (completed) {
      if (!currentBookingIDSetNull.current) {
        dispatch(setCurrentBookingID(null))
        if (setReloadBookings) {
          setReloadBookings(true)
        }
        currentBookingIDSetNull.current = true
      }
      return <span />;
    } else {
      return null
    }
  };

  const acceptBookingTapped = async () => {
    const { bookingID } = each
    setLoading(true)
    
    firebase.functions().httpsCallable("acceptBooking")({ bookingID: bookingID }).then((_ => {
      console.log("Booking accepted")
    })).catch((e) => {
      if (e.details?.error === "payment_failed") {
        setAlertInfo({
          open: true, title: "",
          description: e.details.message,
          onConfirm: () => {},
          onCancel: hideAlert,
          okTitle: "",
          cancelTitle: "OK"
        })
      } else {
        console.log("ERROR", e.message)
        dispatch(setErrorDetails({
          message: e.message
        }))
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  const hideAlert = () => {
    setAlertInfo(oldAlertInfo => ({
      ...oldAlertInfo,
      open: false
    }))
  }

  const rejectBookingTapped = async () => {
    setLoading(true)
    const { bookingID } = each
    const bookingRef = firebase.app().firestore().collection('bookings').doc(bookingID)
    const toUpdate = {
        status: "declined"
    }

    const bookingData = (await bookingRef.get()).data()
    if (bookingData.giftID) {
      await firebase.app().firestore().collection('gifts').doc(bookingData.giftID).update({
        isUsed: false
      })
      toUpdate.giftID = null
    }
    if (bookingData.rescheduleBookingID && bookingData.paymentIntentIds?.length > 0) {
      await firebase.functions().httpsCallable("refundBooking")({ bookingID: bookingData.bookingID })
    }

    await bookingRef.update(toUpdate)
    setLoading(false)
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    fabProgress: {
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }));
  const classes = useStyles()
  useBookingCardStyles()

  const StartCall = () => {
    return (
      <div className={classes.wrapper}>
        <IconButton className="quadrado booking_icon_button booking_orange_button" color='primary' disabled={loading} onClick={callTapped}>
          {loading ? <CircularProgress size={24} className={classes.buttonProgress} /> : 'Start Call'}
        </IconButton>
      </div>
    )
  }

  const DownloadButton = () => {
    return (
      <Button
        className='make_appointment_button download_button'
        onClick={() => downloadRecording(each, startMoment, dispatch, setIsDownloading)}
        color="primary"
      >
        {isDownloading ? <CircularProgress size={10} /> : 'Download Recording'}
      </Button>
    )
  }

  return (
    <div className={`booking_card_container ${isHome ? 'booking_card_container_border' : ''} ${historyBooking || historyCall ? each.status === 'accepted' ? 'booking_card_accepted' : 'booking_card_declined' : ''}`}>
      {!isCurrentBooking && isHome && !historyCall && !historyBooking && isClient && each.status === 'pending' &&
        <div className='booking_card_footer booking_card_header start_call_text'>
          <span>
            You'll return here at the time of your {appointmentName ?? 'appointment'} to start your call.
          </span>
          <Divider className='top_divider' />
        </div>
      }

      {isHome && orientationPortrait &&
        <div className='booking_card_footer booking_card_header start_call_text'>
          <div className='booking_card_header_name'>
            <div className="label_type_2 heading label_bookings">
              {isClient ? each.consultantFullName ?? each.consultantHandle : each.clientFullName ?? each.clientHandle}
            </div>

            {(historyBooking || isHome) && !each.callUUID && each.clientID &&
                <BookingChatIcon
                  sessionData={each}
                  isConsultant={!isClient}
                  sessionID={each.id}
                  otherHandle={isClient ? each.consultantFullName ?? each.consultantHandle : each.clientFullName ?? each.clientHandle}
                />
            }
          </div>
          <Divider className='top_divider' />
        </div>
      }
      
      <div className={`booking_card`}>
        {(!orientationPortrait || isNotifications) &&
          <div className={`booking_card_col ${!isHome || widthLessThan800px ? 'booking_card_col_big' : ''}`}>
            <div className="label_type_2 heading">
              {isClient ? each.consultantFullName ?? each.consultantHandle : each.clientFullName ?? each.clientHandle}
            </div>

            {!(orientationPortrait && widthLessThan650px) && !historyCall && isClient && (
              <div className="label_type_1">
                {each.expertise}
              </div>
            )}

            {orientationPortrait && widthLessThan650px && fromSideBar &&
              <div className="label_type_1">
              {dataCalls[each.callType].name}
                <div>
                  {each.isFree ? 'FREE' : formattedIntegerLocaleSeparator(each.estimatedPrice, each["locale"])}
                  {!each.isFree && localeToCurrency(each["locale"])}
                  {`/${each.length}min`}
                </div>
              </div>
            }
          </div>
        }

        {!orientationPortrait &&
          <div className="booking_card_icon">
            {(historyBooking || isHome) && !each.callUUID && each.clientID &&
                <BookingChatIcon
                  sessionData={each}
                  isConsultant={!isClient}
                  sessionID={each.id}
                  otherHandle={isClient ? each.consultantFullName ?? each.consultantHandle : each.clientFullName ?? each.clientHandle}
                />
            }
          </div>
        }

        {!fromSideBar &&
          <div className={`booking_card_col row ${!isHome || widthLessThan800px ? 'booking_card_col_big' : ''} ${orientationPortrait && widthLessThan650px ? 'booking_card_column' : ''}`}>
            {!widthLessThan650px && 
              <div className="selection_icon">
                {dataCalls[each.callType].icon}
              </div>
            }
            <div>
              <div className={`label_type_1 ${widthLessThan650px ? 'label_type_1_center' : ''}`}>
                {dataCalls[each.callType].name}
              </div>
              <div className={`label_type_1 ${widthLessThan650px ? 'label_type_1_center' : ''}`}>
                {(!each.giftID || !isClient) &&
                  <>
                    {each.isFree ? 'FREE' : formattedIntegerLocaleSeparator(historyCall ? each.successfullyCharged : each.estimatedPrice, each["locale"])}
                    {!each.isFree && localeToCurrency(each["locale"])}
                  </>
                }
                {!historyCall && `${(!each.giftID || !isClient) ? '/' : ''}${each.length}min${each.giftID && isClient ? ' (gift)' : ''}`}
              </div>
            </div>
          </div>
        }

        <div className={`booking_card_col ${!isHome || widthLessThan800px ? 'booking_card_col_big' : ''} booking_card_date ${isHome && !historyBooking && !historyCall && !isCurrentBooking ? 'booking_card_date_with_points' : ''}`}>
          <div className="label_type_1 label_type_1_center label_date">
            {prettyStartDate}<br />{prettyStartTime} {ampmStartDate} - {endDate} {ampmEndDate}<br />
            ({Intl.DateTimeFormat().resolvedOptions().timeZone})
          </div>
          {isHome && !historyCall && !historyBooking &&
            <>
              {each.status === 'accepted' ?
                <>
                  <div className="booking_card_count">
                    <Countdown renderer={countRenderer} date={startDate} />
                  </div>
                  <div style={{ visibility: 'hidden' }}>
                    <Countdown renderer={countRendererEnd} date={endMoment.toDate()} />
                  </div>
                </>
              :
                <>
                  {!isClient &&
                    <div className='booking_pending_status heading'>
                      Pending Approval
                    </div>
                  }
                </>
              }
            </>
          }
        </div>

        {!isCurrentBooking && isHome && !historyCall && !historyBooking &&
          <>
            {isClient ? (
              <div className="booking_card_col booking_center">
                {each.status === 'accepted' ? (
                    <div className='booking_accepted_status'>
                      Approved
                    </div>
                  ) : (
                    <div className='booking_pending_status heading'>
                      Pending Approval
                    </div>
                  )
                }
              </div>
            ) : (
              <>
                {each.status === 'accepted' ? (
                    <>
                      {config.type !== 'astrologer' &&
                        <div className={`booking_card_col booking_center ${widthLessThan800px ? 'booking_notes_small' : ''}`}>
                          <div className="label_type_1">
                            {notes}
                          </div>
                        </div>
                      }
                    </>
                  ) : (
                    <div className={`booking_card_col row ${widthLessThan800px ? 'booking_notes_small' : ''}`}>
                      {loading || each.status === 'processing' ? <CircularProgress size={24} /> : (
                        <>
                          <IconButton disabled={loading} className="booking_icon_button" color="primary" aria-label="add to shopping cart" onClick={acceptBookingTapped}>
                            <CheckIcon />
                          </IconButton>
                          <IconButton disabled={loading} className="booking_icon_button booking_declined_button" color="primary" aria-label="add to shopping cart" onClick={rejectBookingTapped}>
                            <ClearIcon />
                          </IconButton>
                        </>
                      )}
                    </div>
                  )
                }
              </>
            )}
          </>
        }

        {isClient && historyBooking &&
          <div className={`booking_card_col ${widthLessThan800px ? 'booking_notes_small' : ''}`}>
            <Button
              className='make_appointment_button'
              onClick={() => {
                changePage({
                  pathname: COMMON_ROUTES.CONSULTANT_BOOKING.replace(':consultant', each.consultantID)
                })
              }}
              color="primary"
            >
              Book Follow Up
            </Button>
          </div>
        }

        {isClient && historyCall &&
          <div className={`booking_card_col ${widthLessThan800px ? 'booking_notes_small' : ''}`}>
            <div className='session_price_call'>
              <p>Call again</p>
              {each.isChatCall ? 
                <IconButton className='call_button' disabled={!each.chatAvailable || loadingCall} onClick={() => tappedChatCall(each)}>
                  {each.chatAvailable && !loadingCall ? <ChatIcon className='chat_icon chat_icon_pointer' /> : 
                  <div className='chat_icon_disable_container'>
                    <ChatIcon className='chat_icon_disable' />
                  </div>}
                  {loadingCall && <CircularProgress size={24} className={classes.buttonProgress} />}
                </IconButton>
              :
                <>
                  {each.videoEnabled ? 
                    <IconButton className='call_button' disabled={!each.videoAvailable || loadingCall} onClick={() => tappedVideoCall(each)}>
                      {each.videoAvailable && !loadingCall ? <VideoIcon /> : <Avatar style={{ width: 50, height: 50 }} src={process.env.PUBLIC_URL + `/video-unavailable.png`}></Avatar>}
                      {loadingCall && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </IconButton> :
                    <IconButton className='call_button' disabled={!each.audioAvailable || loadingCall} onClick={() => tappedAudioCall(each)}>
                      {each.audioAvailable && !loadingCall ?
                        <AudioIcon /> : <Avatar style={{ width: 50, height: 50 }} src={process.env.PUBLIC_URL + `/audio-unavailable.png`}></Avatar>}
                        {loadingCall && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </IconButton>
                  }
                </>
              }
              {((each.isChatCall && each.chatAvailable) || (each.videoEnabled && each.videoAvailable) || (!each.isChatCall && !each.videoEnabled && each.audioAvailable)) &&
                <div>
                  {formattedIntegerLocaleSeparator(each.priceSession, each.locale)}
                  {localeToCurrency(each.locale)}/min
                </div>
              }
            </div>
          </div>
        }

        {(historyBooking || historyCall) && !widthLessThan450px &&
          <div className='booking_card_icon'>
            <div className='recording_container'>
              {displayRecording && 
                <>
                  {isDownloading ? <CircularProgress size={20} /> : 
                    <>
                      <p>Recording</p>
                      <CloudDownload onClick={() => downloadRecording(each, startMoment, dispatch, setIsDownloading)} />
                    </>
                  }
                </>
              }
            </div>
          </div>
        }

        {isCurrentBooking && isClient && !orientationPortrait &&
          <div className="booking_card_col booking_center">
            <StartCall />
          </div>
        }

        {!isHome && 
          <div className={`booking_card_col booking_notif_buttons ${!isHome ? 'booking_card_col_small' : ''}`}>
            {loading || each.status === 'processing' ? <CircularProgress size={24} /> : (
              <>
                <IconButton disabled={loading} className="booking_icon_button" color="primary" aria-label="add to shopping cart" onClick={acceptBookingTapped}>
                  <CheckIcon />
                </IconButton>
                <IconButton disabled={loading} className="booking_icon_button booking_declined_button" color="primary" aria-label="add to shopping cart" onClick={rejectBookingTapped}>
                  <ClearIcon />
                </IconButton>
              </>
            )}
          </div>
        }

        {isCurrentBooking && isHome && !isClient && !orientationPortrait &&
          <div className="booking_card_col booking_waiting_call">
            <Typography variant="p" style={{ color: 'red', display: 'table' }}>
              Waiting for {each.clientFullName} to initiate call.
            </Typography>
          </div>
        }

        {isHome && !historyBooking && !historyCall && !isCurrentBooking && !isClient && widthMoreThan725px && each.clientDetails &&
          <IntakeInformation bookingID={each.bookingID} />
        }

        {isHome && !historyBooking && !historyCall && !isCurrentBooking &&
          <BookingCardActions
            isClient={isClient}
            bookingID={each.bookingID}
            consultantID={each.consultantID}
            setAllBookingsLoaded={setAllBookingsLoaded}
            widthMoreThan725px={widthMoreThan725px}
            setOpenIntakeInfos={setOpenIntakeInfos}
            clientDetails={each.clientDetails}
          />
        }
      </div >

      {isCurrentBooking && isHome && !isClient && orientationPortrait &&
        <div className='booking_card_footer booking_waiting_call'>
          <Typography variant="p" style={{ color: 'red', display: 'table' }}>
            Waiting for {each.clientFullName} to initiate call.
          </Typography>
        </div>
      }

      {!isCurrentBooking && isHome && !historyCall && !historyBooking && isClient && each.status === 'accepted' &&
        <div className='booking_card_footer start_call_text'>
          <Divider className='bottom_divider' />
          <div>
            The "Start Call" button will appear at your scheduled {appointmentName ?? 'appointment'} time. Thank you for your patience. In the mean time you can test your mic and camera here:
            <Button
              className='test_call_button'
              onClick={() => {
                changePage({
                  pathname: COMMON_ROUTES.TEST,
                  search: `videoEnabled=${each.callType === "video"}&fromBookings=true`,
                  startCall: () => { 
                    changePage({ pathname: '/' })
                  },
                })
              }}
              color="primary"
              variant="outlined"
            >
              TEST CALL
            </Button>
          </div>
        </div>
      }

      {isCurrentBooking && isClient && orientationPortrait &&
        <div className='booking_card_footer column_footer'>
          <StartCall />
        </div>
      }

      {(historyCall || historyBooking) && widthLessThan450px &&
        <div className='booking_card_footer no_margin_bottom'>
          {displayRecording && <DownloadButton />}
        </div>
      }

      {openIntakeInfos &&
        <IntakeInformationDialog bookingID={each.bookingID} open={openIntakeInfos} setOpen={setOpenIntakeInfos} />
      }

      {alertInfoState.open && <ConfirmAlert data={alertInfoState} />}
    </div>
  )
};

export default BookingCard;
